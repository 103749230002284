import { Card } from "./card/Card";
import React, { useState } from "react";
import { IStyleExt } from "./IExt";
import { useMessage } from "./util";
import { MessageBarType, TextField, Stack, PrimaryButton, DefaultButton } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { UserClient, ApiClient } from "../api/client";

export function ConfirmBox(props: IStyleExt) {
    const hist = useHistory();
    const [message, setMessage] = useMessage(["Enter your confirmation Code", MessageBarType.info]);
    const [confirmCode, setconfirmCode] = useState<string>();
    return <Card
        cardTitle="Confirm Your Account"
        message={
            {
                text: message?.[0],
                type: message?.[1]
            }}
        styleExt={props.styleExt}
    >
        <TextField value={confirmCode} onChange={(ev, newValue) => setconfirmCode(newValue)} />
        <Stack horizontal tokens={{ childrenGap: "0.5rem" }} horizontalAlign="stretch" disableShrink>
            <DefaultButton style={{ flexBasis: "5rem", flexGrow: 1 }}
                onClick={() => {
                    (async () => {
                        try {
                            await UserClient.resendCode();
                            setMessage("Code has been sent!", MessageBarType.success);
                        }
                        catch (ex) {
                            setMessage(ex.message, MessageBarType.error);
                        }
                    })()
                }}
            >Send Again</DefaultButton>
            <PrimaryButton disabled={!Boolean(confirmCode)} style={{ flexBasis: "5rem", flexGrow: 1 }}
                onClick={() => {
                    (async () => {
                        try {
                            await UserClient.confirmCode(confirmCode as string);
                            ApiClient.token = "";
                            window.location.href = "/";
                        }
                        catch (ex) {
                            setMessage(ex.message, MessageBarType.error);
                        }
                    })()
                }}
            >Submit</PrimaryButton>
        </Stack>
        <DefaultButton onClick={() => {
            hist.push("/signout")

        }}>Sign Out</DefaultButton>
    </Card>
}