import React, { useEffect, useState, FormEvent, ReactElement, useCallback, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Stack, Text, IBreadcrumbItem, Pivot, PivotItem, DetailsList, SelectionMode, Link, Dialog, DialogType, MessageBarType, Spinner, DialogFooter, PrimaryButton, DefaultButton, TextField, Label, Checkbox, StackItem, IconButton, mergeStyles, Popup, Modal } from "@fluentui/react";
import { IRole, SessionClient, IDocument, ApiClient, ISessionBasic, MessageBoardClient, IMessageBoard, IMessagePost, IUser, UserClient, IActor } from "../../api/client";
import { SetupPage } from "./SetupPage";

import { useMessage, useSearchParams, useCrumbs } from "../util";
import { fileUploadStyle, imortExportList } from "../../styles";
import { bgColor } from "../../colors";


export function IconContextButton(props: { deleteAction: () => void }) {
    const { deleteAction } = props;
    const itemClicked = useCallback(() => {
        deleteAction();
    }, [deleteAction]);

    return <><IconButton iconProps={{ iconName: "Cancel" }} onClick={itemClicked} /></>
}

export function AdminDirectory(props: { session: ISessionBasic }) {
    const [sessionUsers, setSessionUsers] = useState<IUser[]>([]);
    //    const [selectedId, setSelectedId] = useState<number>();
    const [message, setMessage] = useMessage(["Select a user to view their workspace."]);
    const hist = useHistory();
    useEffect(() => {
        (async () => {
            try {
                let allUsers = await SessionClient.getActiveUsers(props.session.id as number);
                setSessionUsers(allUsers);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [props.session.id, setMessage])
    const userList = sessionUsers.map(su => ({ "Workspace Name": `${su.firstName} ${su.lastName}` }))
    return <SetupPage title="My Workspace - Admin" message={message}>
        {/* <Dropdown placeholder="Pick a user" options={sessionUsers.map(u => ({ key: -(u.id as number), text: `${u.firstName} ${u.lastName}` }))}
            onChange={(ev, dd) => setSelectedId(dd?.key as number)}></Dropdown> */}
        <DetailsList
            items={userList}
            onRenderItemColumn={(itm, idx) => {
                return <Link onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    hist.push(`/simulation?roleid=${-Number(sessionUsers[Number(idx)].id)}&sessionId=${props.session.id as number}`);

                }}>{itm["Workspace Name"]}</Link>

            }}
            selectionMode={SelectionMode.none}

        />
        {/* 
        <PrimaryButton disabled={!selectedId} style={{ alignSelf: "end", marginLeft: "auto" }}
            onClick={() => {
                if (selectedId)
                    hist.push(`/simulation?roleid=${selectedId}&sessionId=${props.session.id as number}`);
            }}
        >View as Selected User</PrimaryButton> */}
    </SetupPage>
}

const showWhenFocused = mergeStyles({
    boxSizing: "border-box",
    opacity: 0,
    height: "0",
    overflow: "hidden",

    "&:focus, &:focus-within": {
        height: "unset",
        opacity: 1

    }


});

function nameToPrefix(name: string) {
    const nameParts = name.split("_");
    return nameParts.length === 4 ?
        `${nameParts[0]} ${nameParts[1]}` : "Other"
}
export function DirectoryViewer(props: { roles: IRole[], role: IRole, session: ISessionBasic, actors: IActor[] }) {
    const [hideDialog, setDHid] = useState(true);
    const toggleHideDialog = () => setDHid((v) => !v);

    const [inputFile, setInputFile] = useState<File | null>();
    const [crumbs, setCrumbs] = useState<IBreadcrumbItem[]>();
    const { team } = useParams<{ "team": string }>();
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useMessage();
    const [messageBoard, setMessageboard] = useState<IMessageBoard[]>([]);
    const [activeTab, setActiveTab] = useState<string>();
    const [post, setPost] = useState<{ title?: string, message?: string }>();
    const [inlineDoc, setInlineDoc] = useState<ReactElement>();
    const [docUrl, setDocUrl] = useState<string>();
    const [inlineDocTitle, setInlinedocTitle] = useState<string>();
    const [runValue, setRunValue] = useState(0);
    const [messageRunValue, setMessageRunValue] = useState(0);
    const [notifyAdmin, setNotifyAdmin] = useState<boolean>(false);
    const [divPosition, setDivPosition] = useState<{ x: number, y: number }>();
    const [times, setTimes] = useState<{ time: number, selected: boolean }[]>([]);
    const [prefixes, setPrefixes] = useState<{ name: string, selected: boolean }[]>([]);

    const dateFilterRef = useRef<HTMLDivElement>(null);
    const prefixFilterRef = useRef<HTMLDivElement>(null);
    function clearInfo() {
        setPost(undefined);
        setMessage(undefined);
    }

    let hist = useHistory();

    useEffect(() => {
        const isTopLevel = !props.role.IsTeam;
        let newCrumbs: IBreadcrumbItem[] = [
            {
                key: "session",
                text: props.session.name as string
            },
            {
                key: "team",
                text: props.role.team as string,
                onClick: !isTopLevel ? (ev) => {
                    hist.push(`/simulation?roleid=${props.roles.find(r => r.IsTeam && r.team === props.role.team)?.id}&sessionid=${props.session.id}`);
                    ev?.preventDefault();
                } : undefined,
                isCurrentItem: !props.role.IsTeam
            }
        ];

        if (isTopLevel) {
            newCrumbs.push({
                key: "role",
                text: `${props.role?.role} (${props.role.ob})`,
                isCurrentItem: true
            });
        }
        setCrumbs(newCrumbs);

        (async function () {
            try {
                let docs = await SessionClient.getDocuments(props.role.id, Number(props.session.id), props.role.affiliation);

                if (ApiClient.isAdmin && props.role.id < 0) {
                    let user = await UserClient.find(-props.role.id);
                    newCrumbs[1].isCurrentItem = false;
                    newCrumbs.push({
                        key: "username",
                        text: `${user.firstName} ${user.lastName}`,
                        isCurrentItem: true
                    })
                    setCrumbs([...newCrumbs]);
                }

                docs.forEach((d) => {
                    d._scheduledDate = new Date(new Date(d.scheduledDate).getTime() + 5 * 60 * 60 * 1000);
                    if (d.type === "collection") {
                        d.name = "C" + d.id + props.role.team + ' ' + props.role.role + '' + d.name.substr(d.name.indexOf(':'));
                    }

                });
                setTimes(Array.from((new Set(docs.map(d => d._scheduledDate.valueOf())))).sort((a, b) => b - a).map(t => ({ time: t, selected: true })));
                setPrefixes(Array.from(new Set(docs.map((d) => nameToPrefix(d.name)))).sort().map((name) => ({ name, selected: true })));
                setDocuments(docs);
            }
            catch (ex) {
                alert(ex.message);
            }
        })()

    }, [setTimes, props.roles, props.role, team, hist, props.session, runValue]);


    useEffect(() => {
        if (activeTab === "Message Board") {
            (async function () {
                let messageBoard = await MessageBoardClient.getMessageBoard(props.session.id as number, props.role.id, props.role.affiliation);
                setMessageboard(messageBoard);
            })();
        }
    }, [props.role.affiliation, props.role.id, props.session.id, activeTab, messageRunValue]);


    return <SetupPage title="Workspace" message={message} crumbs={crumbs} commands={activeTab === "Message Board" ?
        (post ? [{
            key: "save", text: "Save", iconProps: { iconName: "Save" }, onClick: () => {
                (async function () {
                    try {
                        await MessageBoardClient.post(post.title as string, post.message as string, props.session.id as number, props.role.id, notifyAdmin, props.role.affiliation);
                        setMessageRunValue(messageRunValue + 1);
                        clearInfo();
                    }
                    catch (ex) {
                        setMessage(ex.message, MessageBarType.error);
                    }
                })()
            }
        }, { key: "cancel", text: "Cancel", iconProps: { iconName: "Cancel" }, onClick: () => { clearInfo() } }] :
            [{
                key: "newpost", text: "New Post", iconProps: { iconName: "CirclePlus" }, onClick: () => {
                    setPost({});
                }
            }]) : [{
                key: "Upload", text: "Upload", iconProps: { iconName: "Upload" }, onClick: () => {
                    toggleHideDialog()
                }
            }]}>
        <Pivot onLinkClick={(pi) => {
            setActiveTab(pi?.props.headerText);
        }}>
            <PivotItem headerText="Workspace" itemIcon="DocumentSet" >
                <DetailsList

                    className={imortExportList}
                    onShouldVirtualize={() => false}
                    selectionMode={SelectionMode.none}
                    items={documents.filter(d => times.find((t) => t.time === d._scheduledDate.getTime() && t.selected) && prefixes.find((p) => p.name === nameToPrefix(d.name) && p.selected))}
                    columns={[
                        {
                            key: "docname", minWidth: 96, name: "Name", isRowHeader: true, isResizable: true,
                            onRender: (item: IDocument) => {
                                return <Link onClick={(ev) => {
                                    if (item.type === "report") {
                                        (async function () {

                                            //var downloadLink = document.createElement("a");
                                            //downloadLink.href = (await SessionClient.openDocument(props.session.id, props.role.id, item.id)).link;
                                            setDocUrl((await SessionClient.openDocument(props.session.id, props.role.id, item.id)).link);

                                            //                   document.body.appendChild(downloadLink);
                                            //                 downloadLink.click();
                                            //               document.body.removeChild(downloadLink);

                                        })()
                                        ev.preventDefault();
                                    }
                                    else if (item.type === "collection") {
                                        (async function () {
                                            let collection = (await SessionClient.getCollectionDoc(item.id, props.session.id as number));
                                            setInlinedocTitle(item.name);
                                            setInlineDoc(<Stack>
                                                <Label>Request</Label>
                                                <Text>{collection.request}</Text>
                                                <Label>Response</Label>
                                                <Text>{collection.response}</Text>
                                            </Stack>);
                                        })()


                                    }
                                    else {
                                        alert(`Unknown type: ${item.type}`);
                                    }
                                }}>{item.name}</Link>
                            }
                        },
                        { key: "docType", fieldName: "type", minWidth: 96, name: "Type", isResizable: true },
                        {
                            key: "scheduledDate",
                            iconName: "filter",
                            iconClassName: mergeStyles({
                                float: "right",
                                paddingLeft: "1rem",
                                opacity: times.find(t => !t.selected) ? 1 : 0.3
                            }),
                            minWidth: 96, name: "Date", isResizable: true, onRender: (item: IDocument) => {
                                return `${item._scheduledDate.toLocaleDateString()} ${item._scheduledDate.toLocaleTimeString()} EST`;
                            }, onColumnClick: (ev) => {

                                const elem: HTMLElement = ev.currentTarget;
                                const rect = elem.getBoundingClientRect();

                                dateFilterRef.current?.focus();

                                setDivPosition({
                                    x: rect.left,
                                    y: rect.top
                                })

                            }
                        },
                        {
                            key: "prefix",
                            iconName: "filter",
                            iconClassName: mergeStyles({
                                float: "right",
                                paddingLeft: "1rem",
                                opacity: prefixes.find(t => !t.selected) ? 1 : 0.3
                            }),
                            minWidth: 96, name: "Prefix", isResizable: true, onRender: (item: IDocument) => {
                                return nameToPrefix(item.name);
                            }, onColumnClick: (ev) => {

                                const elem: HTMLElement = ev.currentTarget;
                                const rect = elem.getBoundingClientRect();

                                prefixFilterRef.current?.focus();

                                setDivPosition({
                                    x: rect.left,
                                    y: rect.top
                                })

                            }
                        },

                        {
                            key: "action", minWidth: 28, name: " ",
                            onRender: (item, index, column) => {
                                return <IconContextButton deleteAction={() => {
                                    (async () => {
                                        try {
                                            if (prompt("Are you sure you want to delete this item?")) {
                                                await SessionClient.deleteDocument(item.id, props.role.id, Number(props.session.id), props.role.affiliation);
                                                setRunValue(runValue + 1);
                                            }
                                        } catch (ex) {
                                            alert(ex);
                                        }

                                    })()
                                }} />
                            }
                        }
                        // { key: "docname", minWidth: 96, name: "Document Name" }
                    ]

                    }
                />
            </PivotItem>
            <PivotItem headerText="Message Board" itemIcon="Message">
                <MessageBoard
                    affiliation={props.role.affiliation}
                    sessionId={props.session.id as number}
                    roleId={props.role.id as number}
                    title={post?.title}
                    message={post?.message}
                    showForm={Boolean(post)}
                    setNotifyAdmin={setNotifyAdmin}
                    notifyAdmin={notifyAdmin}
                    messageBoard={messageBoard}
                    fieldChange={(ev, newvalue) => {
                        let updatedPost = { ...post };
                        (updatedPost as any)[(ev.target as HTMLInputElement).name] = newvalue;
                        setPost(updatedPost);
                    }} />
            </PivotItem>
        </Pivot>
        {<div
            tabIndex={0}
            ref={dateFilterRef}
            className={mergeStyles(showWhenFocused,
                {
                    position: "absolute",
                    top: `calc(${divPosition?.y}px - 1.75rem)`,
                    left: `calc(${divPosition?.x}px)`,
                    //width: "10rem",
                    boxSizing: "border-box",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    padding: "0.25rem"
                })} >
            {/* <Text
                        variant="medium"
                    >Pick Dates:</Text> */}
            <div className={mergeStyles({
                border: "1px solid #ccc",
                padding: "0.25rem",
                boxSizing: "border-box",
                marginBottom: "0.5rem"
            })}>
                {
                    times.map((tm) => {
                        var dt = new Date();
                        dt.setTime(tm.time);
                        return <div>
                            {/* <Checkbox className={mergeStyles({ verticalAlign: "middle", padding: "0.25rem 0.25rem", lineHeight: "2" })} label={`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`} 
                                checked={tm.selected}
                                onChange={
                                    ()=>alert("change")
                                }
                                onClick={
                                    () => {
                                        alert("CLICKED");
                                        setTimes(times.map((tm2) =>{
                                                if (tm2.time === tm2.time)
                                            tm2.selected = !tm2.selected
                                            return tm2;
                                        }))
                                    }
                                }

                            /> */}

                            <label><input
                                checked={tm.selected}
                                type="checkbox" className={mergeStyles({ verticalAlign: "middle", padding: "0.25rem 0.25rem", lineHeight: "2" })}
                                onChange={() => {
                                    setTimes(times.map((tm2) => {
                                        if (tm2.time === tm.time)
                                            tm2.selected = !tm2.selected
                                        return tm2;
                                    }))
                                }} />{`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`}</label>
                        </div>
                    })


                    // .forEach(tm => {
                    //     const lineItem = document.createElement("div");
                    //     lineItem.style.lineHeight = "2";
                    //     lineItem.style.padding = "0 0.25rem";
                    //     lineItem.innerHTML = `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
                    //     const cb = document.createElement("input");
                    //     cb.type = "checkbox";
                    //     cb.tabIndex = -1;
                    //     cb.style.verticalAlign = "middle";
                    //     lineItem.prepend(cb);
                    //     choiceList.appendChild(lineItem);
                    // })


                }</div>
            <PrimaryButton
                className={mergeStyles({ width: "calc(100%)" })}
                onClick={() => {
                    let selected = false;
                    if (times.find(p => !p.selected)) {
                        selected = true;;
                    }

                    setTimes(times.map(t => {
                        t.selected = selected;
                        return t
                    }))
                }}
            >{times.find(t => !t.selected) ? "Select All" : "Clear All"}</PrimaryButton>
            {/* <DefaultButton
                className={mergeStyles({ width: "calc(50% - 0.25rem)" })}
                onClick={(ev) => {
                    (ev.currentTarget as HTMLElement).blur()
                    dateFilterRef.current?.blur()
                    //setTimeout(()=>dateFilterRef.current?.blur(),2000)
                }
                }
            >Cancel</DefaultButton> */}
        </div>
        }

        {<div
            tabIndex={0}
            ref={prefixFilterRef}
            className={mergeStyles(showWhenFocused,
                {
                    position: "absolute",
                    top: `calc(${divPosition?.y}px - 1.75rem)`,
                    left: `calc(${divPosition?.x}px)`,
                    //width: "10rem",
                    boxSizing: "border-box",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    padding: "0.25rem"
                })} >
            {/* <Text
                        variant="medium"
                    >Pick Dates:</Text> */}
            <div className={mergeStyles({
                border: "1px solid #ccc",
                padding: "0.25rem",
                boxSizing: "border-box",
                marginBottom: "0.5rem"
            })}>
                {
                    prefixes.map((p) => {
                        return <div>
                            <label><input
                                checked={p.selected}
                                type="checkbox" className={mergeStyles({ verticalAlign: "middle", padding: "0.25rem 0.25rem", lineHeight: "2" })}
                                onChange={() => {
                                    setPrefixes(prefixes.map((p2) => {
                                        if (p2.name === p.name)
                                            p2.selected = !p2.selected
                                        return p2;
                                    }))
                                }} />{p.name}</label>
                        </div>
                    })
                }</div>
            <PrimaryButton
                className={mergeStyles({ width: "calc(100%)" })}
                onClick={() => {
                    let selected = false;
                    if (prefixes.find(p => !p.selected)) {
                        selected = true;
                    }

                    setPrefixes(prefixes.map(p => {
                        p.selected = selected;
                        return p
                    }))
                }}>{prefixes.find(p => !p.selected) ? "Select All" : "Clear All"}</PrimaryButton>
        </div>
        }
        <Dialog hidden={hideDialog} dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Upload File',
            subText: '',
        }} >
            <form action={`${ApiClient.tempHost}/uploads`} method="post" encType="multipart/form-data" onSubmit={(ev) => {
                (async function () {
                    if (inputFile) {
                        setIsLoading(true);
                        let fd = new FormData();
                        fd.append("scenarioId", String(props.session.scenarioId));
                        fd.append("roleId", String(props.role.id));
                        fd.append("affiliation", String(props.role.affiliation));
                        fd.append("sessionId", String(props.session.id));
                        fd.append("inputFileCode", 'role');
                        fd.append("inputFile", inputFile);
                        try {
                            let result = await fetch(ev.currentTarget.action, {
                                method: "post",
                                body: fd,
                                credentials: "include"
                            });
                            let text = await result.text();
                            toggleHideDialog();
                            setMessage(text, MessageBarType.success)

                            setRunValue(runValue + 1);
                        }
                        catch (ex) {
                            toggleHideDialog();
                            setMessage(ex.message, MessageBarType.error)
                        }
                        setIsLoading(false);
                    }
                })()
                ev.preventDefault();
            }}>
                {isLoading ? <Spinner label="Uploading Document(s)" /> :
                    <>
                        <Stack>
                            <input type="file" name="inputFile" className={fileUploadStyle} onChange={(ev) => setInputFile(ev.target.files?.[0])} />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={!inputFile} type="submit" children="Import" />
                            <DefaultButton onClick={toggleHideDialog} children="Cancel" />
                        </DialogFooter>
                    </>}
            </form>
        </Dialog>
        <Dialog hidden={!inlineDoc} dialogContentProps={{
            type: DialogType.largeHeader,
            title: inlineDocTitle,
            showCloseButton: true,
            subText: '',
        }}>
            {inlineDoc}
            <DialogFooter>
                <PrimaryButton onClick={() => {
                    setInlineDoc(undefined);
                }}>Dismiss</PrimaryButton>
            </DialogFooter>
        </Dialog>
        {<Modal isOpen={Boolean(docUrl)} onDismiss={() => setDocUrl(undefined)}>
            <iframe src={docUrl} className={mergeStyles({
                height: "90vh",
                width: "80vw"

            })} />
        </Modal>}
    </SetupPage>

}

function MessageBoard(props: { affiliation?: string, notifyAdmin: boolean, setNotifyAdmin: (notify: boolean) => void, sessionId: number, roleId: number, messageBoard: IMessageBoard[], showForm: boolean, title?: string, message?: string, fieldChange: (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void }) {
    let history = useHistory();
    return props.showForm ? <Stack tokens={{ childrenGap: "1rem" }}>
        <TextField name="title" value={props.title} label="Title" placeholder="New Post title" maxLength={150} onChange={props.fieldChange} />
        <TextField name="message" value={props.message} label="Message" placeholder="New Post details" multiline maxLength={500} onChange={props.fieldChange} />
        <Checkbox label="Notify Admin?"
            checked={props.notifyAdmin}
            onChange={(ev, checked) => {
                props.setNotifyAdmin(checked || false);
            }} />
    </Stack> :
        <DetailsList
            selectionMode={SelectionMode.none}
            items={props.messageBoard}
            columns={
                [
                    {
                        key: "title", fieldName: "title", name: "Post", minWidth: 96, isRowHeader: true,
                        onRender: (item: IMessageBoard) => {
                            return <Link onClick={() => { history.push("/simulation/messageboard/posts?messageboardid=" + item.id + "&sessionid=" + props.sessionId + "&roleid=" + props.roleId + (props.affiliation ? `&affiliation=${props.affiliation}` : "")) }}>{item.title}</Link>
                        }
                    },
                    { key: "replies", fieldName: "replies", name: "Replies", minWidth: 96 },
                    { key: "lastAt", fieldName: "lastAt", name: "Last", minWidth: 96 },
                    { key: "createdAt", fieldName: "createdAt", name: "Created", minWidth: 96 }
                ]}
        />
}

export function MessageViewer(props: { session: ISessionBasic, role: IRole }) {

    const messageBoardId = Number(useSearchParams().get("messageboardid"));
    const [posts, setPosts] = useState<IMessagePost[]>([]);
    const [reply, setReply] = useState<string>();
    const [message, setMessage] = useMessage();
    const [crumbs, setCrumbs] = useCrumbs();
    const [runValue, setRunValue] = useState(0);
    const [notifyAdmin, setNotifyAdmin] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                let posts = await MessageBoardClient.getPosts(props.session.id as number, props.role.id, messageBoardId, props.role.affiliation);
                let title = posts.find(p => !p.id)?.message;
                setPosts(posts.filter(p => p.id));
                setCrumbs([
                    { key: "session", text: props.session.name as string },
                    { key: "role", text: props.role.role },
                    { key: "messageboard", text: title as string }
                ]);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })();
    }, [props.session, props.role, messageBoardId, runValue, setCrumbs, setMessage])

    return <SetupPage message={message} title="Message Board" crumbs={crumbs}><Stack tokens={{ childrenGap: "1rem" }}>
        {
            posts?.map((p, i) => {
                return <>
                    <Text variant="mediumPlus" styles={{
                        root: {
                            border: "1px solid #CCC",
                            backgroundColor: i === 0 ?
                                //"#f2fafe"
                                "#abdff9"
                                : (p.userId === 1 ?
                                    //"#FFFFE0" 
                                    "#ffff94"
                                    : bgColor),
                            padding: "1rem"
                        }
                    }}>{p.message}</Text>
                    <Stack.Item align="end"><Text>By: {p.userName} @ {p.createdAt}</Text></Stack.Item>
                </>
            })
        }
        <Text variant="xLarge">Reply</Text>
        <TextField value={reply} multiline maxLength={500} onChange={(ev, newval) => { setReply(newval) }} placeholder="Add a reply...."  ></TextField>
        <StackItem align="end"><Checkbox label="Notify Admin?" checked={notifyAdmin} onChange={(ev, checked) => { setNotifyAdmin(checked || false) }} /></StackItem>
        <Stack.Item align="end"><PrimaryButton disabled={!reply} onClick={() => {
            (async () => {
                try {
                    await MessageBoardClient.reply(reply as string, messageBoardId, props.session.id as number, props.role.id, notifyAdmin, props.role.affiliation);
                    setRunValue(runValue + 1);
                    setReply("");
                }
                catch (ex) {
                    setMessage(ex.message, MessageBarType.error);
                }
            })()
        }}>Post Reply</PrimaryButton></Stack.Item>
    </Stack>
    </SetupPage>
}