import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'office-ui-fabric-react/dist/css/fabric.min.css'
import { AuthPage } from './AuthPage';
import { Dashboard } from './components/Dashboard';
import { SignOutBox } from './components/SignOutBox';
import { CollectionResponse } from './components/dash/CollectionResponse';
import { ProfilePage } from './components/dash/ProfilePage';
import { Card } from './components/card/Card';
import { Stack } from '@fluentui/react';
import { appScreen } from './styles';
import { PaymentPage } from './components/PaymentPage';


function TestHandler() {

  let isTest = window.location.host === "localhost:3000";
  if (isTest)
    window.location.href = window.location.href.replace(":3000", ":1337");

  return <div>404 - Page Not Found</div>
}

function App(props: { isLoggedIn: boolean }) {

  let [isLoggedIn, setLoggedIn] = useState(props.isLoggedIn);

  return <Router>
    {!isLoggedIn ?

      <AuthPage onLoggedIn={() => setLoggedIn(true)} />

      :
      <Switch>
        <Route path={"/404"} exact>
          <div>Page was not found</div>
        </Route>
        <Route path="/login">
          <AuthPage onLoggedIn={() => { window.location.href = "/"; }} />
        </Route>
        <Route path="/resetpassword">
          <AuthPage onLoggedIn={() => { window.location.href = "/"; }} />
        </Route>
        <Route path="/signout">
          <SignOutBox onLoggedOut={() => setLoggedIn(false)} />
        </Route>
        <Route path="/collectionresponse">
          <CollectionResponse />
        </Route>
        <Route path="/collviewer">
          <TestHandler />
        </Route>
        <Route path="/sessionviewer">
          <TestHandler />
        </Route>
        <Route path="/policyviewer">
          <TestHandler />
        </Route>
        <Route path="/policyquadviewer">
          <TestHandler />
        </Route>
        <Route path="/profile">
          <Stack className={appScreen} dir="ltr" horizontalAlign="center" >
            <Card cardTitle="Waymark Intel Portal Profile" style={{ marginTop: "1rem", maxWidth:"80%" }} >
              <ProfilePage />
            </Card>
          </Stack>
        </Route>
        <Route path='/paymentpage'>
          <PaymentPage />
        </Route>
        <Route>
          <Dashboard />
        </Route>
      </Switch>
    }
  </Router>

}

export default App;
