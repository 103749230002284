import React from "react";
import { MessageBarType, Stack, TextField, ActionButton, Separator, PrimaryButton } from "@fluentui/react";
import { ApiClient, SessionClient } from "../api/client";
import { FormCard } from "./card/FormCard"
import { IStyleExt } from "./IExt";
import { useHistory } from "react-router-dom";


export class LoginBox extends React.Component<IStyleExt & { onLoggedIn: () => void }, { messageType?: MessageBarType, message?: string, loading?: boolean, usernameField?: string, passwordField?: string }>{
    constructor(props: IStyleExt & { onLoggedIn: () => void }) {
        super(props);
        this.state = {};
        this.setMessage.bind(this);
        this.clearMessage.bind(this);
    }

    setMessage(message: string, type = MessageBarType.error) {
        this.setState({
            messageType: type,
            message: message
        });
    }
    clearMessage() {
        this.setState({
            messageType: undefined,
            message: undefined
        });
    }

    handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const target = event.target as HTMLInputElement;
        const name = target.name;
        this.setState({
            [name]: target.value
        });
    }
    render() {

        function MakeAccount() {
            let history = useHistory();
            return <ActionButton iconProps={{ iconName: "AddFriend" }} children="Create Account"
                onClick={() => {
                    history.push("/login/help?mode=newaccount")
                }}
            />
        }
        function Tester() {
            let history = useHistory();
            return <ActionButton iconProps={{ iconName: "Info" }} children="Need Help"
                onClick={() => history.push("/login/help")} />
        }

        return (
            <FormCard formTitle="Web Portal Login" onSubmit={(ev) => { this.login(); ev.stopPropagation(); ev.preventDefault(); }}
                message={this.state.message} messageType={this.state.messageType}
                styleExt={this.props.styleExt}>
                <TextField name="usernameField" value={this.state.usernameField} label="Username" placeholder="Enter Your Username" onChange={this.handleInputChange} />
                <TextField name="passwordField" value={this.state.passwordField} label="Password" placeholder="Enter Your Password" type="password" onChange={this.handleInputChange} />
                <Stack.Item align="end"><PrimaryButton type="submit" disabled={this.state.loading} primary>Login</PrimaryButton></Stack.Item>
                <Stack horizontal horizontalAlign="space-evenly">
                    <MakeAccount/>
                    <Separator alignContent="start" vertical></Separator>
                    <Tester></Tester>
                </Stack>
            </FormCard>
        )
    }
    async login() {
        this.setState({ loading: true })
        if (this.state.usernameField && this.state.passwordField) {
            // const hasPaid = await SessionClient.checkPaymentStatus(ApiClient.signedInUser.id!, )
            try {
                await ApiClient.login(this.state.usernameField, this.state.passwordField);
                this.setMessage(`Welcome ${ApiClient.signedInUser.firstName} ${ApiClient.signedInUser.lastName}. You're logged in!`, MessageBarType.success);
                window.location.replace("/");
                
            }
            catch (ex) {
                this.setMessage(ex.message);
                this.setState({ passwordField: "" })
            }
        }
        else
            this.setMessage("Username & Password are required for login.");

        this.setState({ loading: undefined });
        //this.setMessage("Login was clicked!")
    }
}
