import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Nav, INavStyles, INavLinkGroup, INavLink, Dropdown, IDropdownOption, Separator, Dialog, DefaultButton, DialogFooter, PrimaryButton, TextField } from "@fluentui/react";
import { red } from "./colors";
import { useHistory } from "react-router-dom";
import { IRole, ISessionBasic, ApiClient, IActor, SessionClient, UserClient } from "./api/client";


type SidebarProps = { isAdmin?: boolean, roles?: IRole[], actors?: IActor[], session?: ISessionBasic, sessions: ISessionBasic[], sessionChanged: (session: ISessionBasic) => void }

export function Sidebar(props: SidebarProps) {
    const sessions = props.sessions;
    // console.log(ApiClient.signedInUser.id)
    const activeSession = props.session;
    //const [loadingSession, setLoadingSession] = useState(false);

    let hist = useHistory();
    const sbLinkClicked = useCallback((ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        if (item?.url)
            hist.push(item?.url);

        ev?.preventDefault();
    }, [hist]);
    const [showDialog, setShowDialog] = useState(false)
    const [accessCode, setAccessCode] = useState<string>()
    function makeStyles(): Partial<INavStyles> {
        return {
            root: {
                boxSizing: 'border-box',

                "& > * > .ms-Nav-chevronButton": {
                    pointerEvents: "none",
                    borderBottom: "1px solid #ccc",
                    marginTop: "0",
                    height: "3.25rem",
                    backgroundColor: red,
                    color: "white",
                },
                "& > * > .ms-Nav-chevronButton i": {
                    display: "none"
                },

            },
            groupContent: {
                marginBottom: "0.5rem",

                "& > * > * > * > *> * > .ms-Nav-linkText": {
                    fontWeight: "500"

                }
            },
            chevronButton: {
                background: "none",

                '&::after': {
                    border: "none"
                }

            },
            chevronIcon: {
                transform: 'rotate(-90deg)',

                '.is-expanded > * > &': {
                    transform: 'rotate(0)'
                },


            },
            compositeLink: {

                '&[name="DROPDOWN"]>button': {
                    padding: "0.5rem"
                },
                '&[name="SEPERATOR"]>*>*>*': {
                    width: '100%'
                }

            },
            link: {
                background: "none",
                color: "black",

                '& .ms-Dropdown-container': {
                    width: "100%",
                    textAlign: "left"
                },
                "&::after": {
                    border: "none"
                }

            }
        }
    }

    let groups = useMemo(function (): INavLinkGroup[] {
        let links: INavLink[] = []
        if (sessions) {
            links.push({
                name: "DROPDOWN",
                url: "",
                key: "DROPDOWN"
            })
        }

        if (activeSession && props.roles && props.actors) {
            let sessionQuery = "?sessionid=" + activeSession.id;
            links.push({
                name: "Dashboard",
                url: "/",
                onClick: sbLinkClicked
            });
            let warGameLinks = {
                name: "Map",
                isExpanded: true,
                url: "",
                links: [{
                    name: "Map View",
                    url: "/sessionviewer?id=" + activeSession.id,
                    onClick: null as any//sbLinkClicked 
                }, {
                    name: props.isAdmin ? "Policy Responses" : "Policy Actions",
                    url: (props.isAdmin ? "/policyquadviewer" : "/policyviewer") + sessionQuery + "&fs=1",
                    onClick: null as any//sbLinkClicked 
                }
                ]
            } as INavLink;

            if (props.isAdmin)
                warGameLinks.links?.push({ name: "Collection Responses", url: "/collectionresponse" + sessionQuery, onClick: sbLinkClicked })
            else
                warGameLinks.links?.push({
                    name: "Collection Viewer", url: "/collviewer" + sessionQuery, onClick: null as any // sbLinkClicked 
                })

            //if (props.session?.HasWorkSpace) {
            let simulationLinks = {
                name: "Workspace",
                isExpanded: true,
                url: "",
                links: [
                    // {
                    //     name: "My Workspace",
                    //     url: "/simulation/workspace" + sessionQuery,
                    //     onClick: sbLinkClicked
                    // }
                ]
            } as INavLink

            let teams = props.roles.filter(r => r.IsTeam);
            teams.forEach((teamRole) => {
                const teamLink: INavLink = {
                    name: teamRole.team,
                    url: `/simulation?roleid=${teamRole.id < 0 && ApiClient.isAdmin ? -Number(ApiClient.signedInUser.id) : teamRole.id}&sessionid=${activeSession?.id}`,
                    onClick: sbLinkClicked,
                    links: props.roles?.filter((r) => r.team === teamRole.team && !r.IsTeam).sort((a, b) => a.role.localeCompare(b.role))
                        .map(r => ({
                            name: `${r.role}${(r.ob && r.ob !== 'All' ? " " + r.ob : '')}`,
                            url: `/simulation?roleid=${r.id}&sessionid=${activeSession?.id}`,
                            onClick: sbLinkClicked
                        }))
                };
                simulationLinks.links?.push(teamLink);
            });
            if (activeSession.turn)
                new Set(props.actors.filter(a => a.isMember || props.isAdmin).map(a => a.affiliation)).forEach((aff) => {
                    const teamLink: INavLink = {
                        name: "Wargame: " + aff,
                        url: `/simulation?affiliation=${aff.toLowerCase()}&roleid=${0}&sessionid=${activeSession?.id}`,
                        onClick: sbLinkClicked
                    };
                    simulationLinks.links?.push(teamLink);
                });

            links.push(simulationLinks);
            //}
            links.push(warGameLinks);
        }
        if (props.isAdmin) {
            if (activeSession) {
                links.push({
                    name: "Admin",
                    url: "",
                    links: [
                        {
                            name: "Emailer",
                            url: "/emailer?sessionid=" + activeSession.id,
                            onClick: sbLinkClicked
                        },
                        {
                            name: "Winner",
                            url: "/admin/sessions/results?sessionid=" + activeSession.id,
                            onClick: sbLinkClicked
                        },
                        {
                            name: "Users",
                            url: `/admin/sessions/manage?sessionid=${activeSession.id}`,
                            onClick: sbLinkClicked
                        }
                    ]
                });

            }
            links.push(
                {
                    name: "SEPERATOR",
                    url: "",


                },
                {
                    name: "Setup",
                    url: "",
                    links: [
                        {
                            name: "Clients",
                            url: "/admin/clients",
                            onClick: sbLinkClicked
                        },
                        {
                            name: "Users",
                            url: "/admin/users",
                            onClick: sbLinkClicked
                        },
                        {
                            name: "Scenarios",
                            url: "/admin/scenarios",
                            onClick: sbLinkClicked
                        },
                        {
                            name: "Sessions",
                            url: "/admin/sessions",
                            onClick: sbLinkClicked
                        }, {
                            name: "Time Warp",
                            url: "/admin/schedulerdate",
                            onClick: sbLinkClicked
                        }
                    ]
                });
        }

        links.push({
            name: "Help",
            url: "/help",
            onClick: (ev) => {
                alert('Please contact SICMON directly if you need help.  Thanks.');
                ev?.preventDefault();
                ev?.stopPropagation();
            }
        });
        links.push({
            name: "Add Access Code",
            url: "/login/help?mode=newaccount",
            onClick: sbLinkClicked
        });

        return [{
            name: "Navigation Menu",
            // onHeaderClick: (ev, iscollapsing)=>{
            //     if(iscollapsing){
            //         alert("collapsing");
            //     ev?.preventDefault()
            //     ev?.stopPropagation();
            //     }
            // },
            links: links,
        }];
    }, [props.isAdmin, props.roles, props.actors, activeSession, sbLinkClicked, sessions])

    //This useEffect checks to see if the session clicked on in the dropdown is paid for or not. If not, you are redirected to paypal page
    useEffect(() => {
        (async () => {
            // const checkSessionPayment = await SessionClient.checkPaymentStatus(ApiClient.signedInUser.id as number, activeSession?.id as number)
            if (!activeSession?.paymentTransactionId && activeSession?.cost) {
                console.log(activeSession)
                hist.push(`/paymentpage?sessionId=${activeSession?.id}`)
            }
        })()
    }, [activeSession])

    return (<><Nav styles={makeStyles} groups={groups}
        onRenderLink={(prop, renderer) => {
            if (prop?.name === "DROPDOWN")
                return <Dropdown selectedKey={activeSession?.id} options={sessions?.map(s => ({ session: s, text: s.name, key: s.id } as IDropdownOption)) || []}
                    onChanged={(dd: IDropdownOption, value) => {
                        console.log(activeSession)
                        props.sessionChanged((dd as any).session);
                    }} />
            else if (prop?.name === "SEPERATOR")
                return <Separator style={{ width: "100%" }} styles={{
                    root: {

                        "&::before": {
                            backgroundColor: "#ccc"
                        }

                    }
                }} />
            else return (renderer as any)(prop);
        }} />

        {/* <Dialog hidden={!showDialog} onDismiss dialogContentProps={{ title: "Add Access Code" }} >
            <TextField name="Access Code" onChange={(ev, nv) => {
                setAccessCode(nv);
            }} />
            <DialogFooter >
                <PrimaryButton onClick={() => {
                    if (accessCode)
                        UserClient.checkAccess(accessCode).then(() => {
                            hist.push("/login/signup?accesscode=3B773986")
                        }).catch((ex) => {
                            alert(ex.message)
                        });
                }} text="Send" />
                <DefaultButton onClick={() => setShowDialog(false)} text="Cancel" />
            </DialogFooter>
        </Dialog> */}
    </>)
}