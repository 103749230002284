import React, { useState, useEffect } from "react";
import { Icon, Stack, Text, mergeStyles, StackItem, TextField, Dropdown, DatePicker } from "@fluentui/react";
import { appScreen, card } from "../../styles";
import { LoadOrError, TimePicker } from "../util";
import { SessionClient, ISession, ICollection, IRole } from "../../api/client";
import { useLocation } from "react-router-dom";

let tagProp: React.CSSProperties = { width: "6rem", padding: "0rem", textAlign: "center", border: "1px solid #ccc", borderRadius: "2.5rem" };

function CollectionResponseItem(props: { roles: IRole[], collection: ICollection, session?: ISession }) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isDirty, setIsDirty] = useState<boolean | undefined>();
    const [saveTimeout, setSaveTimeout] = useState(0);
    const [response, setResponse] = useState(props.collection.response);
    const [team, setTeam] = useState(props.roles.find(r => r.id === props.collection.roleId)?.team);
    const [roleId, setRoleId] = useState(props.collection.roleId);
    const [date, setDate] = useState(props.collection._scheduleDate);



    const dateParts = props.collection._scheduleDate ? props.collection._scheduleDate.toLocaleTimeString('en-US').split(':') : []
    const [time, setTime] = useState<string | undefined>(dateParts.length ?
        (`${dateParts[0]}:${dateParts[1]}${dateParts[2].substring(dateParts[2].length - 2).toLowerCase()}`)
        : undefined);
    useEffect(() => {
        setResponse(props.collection.response);
    }, [props.collection.response])

    function resopnseSaveHandler(ev: React.FormEvent, newValue: string | undefined) {
        if (saveTimeout)
            clearInterval(saveTimeout);

        setSaveTimeout(setTimeout(async () => {
            await SessionClient.collectionResponse(props.session?.id || 0, newValue || "", props.collection.id, roleId, date, time)
            setIsDirty(false);
        }, 2500) as any as number)
        setResponse(newValue || "");
        setIsDirty(true);
    }

    let c = props.collection;

    return (
        <Stack tokens={{ childrenGap: "0.5rem" }}>
            <Stack horizontal tokens={{ childrenGap: "0.25rem" }} verticalAlign="center"
                style={{
                    cursor: "pointer", borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc",
                    backgroundColor: c.response ? "lightcoral" : undefined
                }}
                onClick={() => { setIsCollapsed(!isCollapsed); }}>
                <Icon iconName={isCollapsed ? "ExploreContentSingle" : "CollapseContentSingle"} style={{ display: "inline-block" }} />
                <StackItem grow><Text style={{ padding: "0.5rem 0" }} block variant="mediumPlus"  >
                    {c.title}
                </Text></StackItem>
                {c.reportNumbers && <StackItem grow><Text style={{ padding: "0.5rem 0" }} block variant="mediumPlus"  >
                    {c.reportNumbers}
                </Text></StackItem>}
                <Text style={tagProp} >{c.source}</Text>
                <Text style={tagProp}>{c.priority}</Text>
                {c.teamName && <Text style={tagProp} variant="xSmall">{c.teamName}</Text>}
            </Stack>
            {!isCollapsed &&
                <>  <Text>{c.request}</Text>
                    {props.session?.turn === 0 && < Stack horizontalAlign="end" horizontal tokens={{ childrenGap: "0.5rem" }} >
                        <Dropdown selectedKey={team} onChange={(ev, dd) => setTeam(dd?.key as string)} options={Array.from(new Set(props.roles.map(r => r.team))).map(t => ({ key: t, text: t }))} placeholder='Pick a team' />
                        <Dropdown selectedKey={roleId} onChange={(ev, dd) => setRoleId(dd?.key as number)} disabled={!team} options={props.roles.filter(r => r.team === team).map(r => ({ key: r.id, text: r.role }))} placeholder='Pick a role' />
                        <DatePicker value={date} onSelectDate={(dt) => setDate(dt as Date)} />
                        <TimePicker selectedKey={time} name="time" onChange={(name, dd) => setTime(dd?.key as string)} placeholder="Pick a time" />
                    </Stack>}
                    <TextField maxLength={800} value={response || undefined} multiline onChange={resopnseSaveHandler}
                        disabled={(props.collection.turn !== props.session?.turn) || (props.session.turn === 0 && (!team || !roleId || !date || !time))}
                    />
                    {response && <Stack horizontal styles={{ root: { marginTop: "0 !important", color: isDirty ? "" : "lightgray", fontSize: "0.75rem" } }}>{isDirty !== undefined && (isDirty ? "Pending" : "Saved")}<Stack.Item styles={{ root: { marginLeft: "auto", fontSize: "0.75rem" } }} >{`${response.length}`}/800</Stack.Item></Stack>}
                </>}
        </Stack>)
}

function TurnGroup(props: { turn: number, collections: ICollection[], session?: ISession, roles: IRole[] }) {
    const [isCollapsed, setIsCollapsed] = useState(props.turn !== props.session?.turn);

    return (
        <Stack>
            <Stack horizontal onClick={() => { setIsCollapsed(!isCollapsed); }} style={{ cursor: "pointer" }} ><Text variant="large"><Icon iconName={"ChevronRight"} style={{
                fontSize: "0.75rem",
                paddingRight: "0.5rem",
                transform: `rotate(${isCollapsed ? 0 : 90}deg) translateX(${isCollapsed ? 0 : 0.25}rem) `
            }} />Round {props.turn}</Text></Stack>
            <Stack tokens={{ childrenGap: "0.5rem", padding: "1rem" }}>
                {
                    !isCollapsed && props.collections.map((c) => {
                        return <CollectionResponseItem roles={props.roles} collection={c} session={props.session} ></CollectionResponseItem>
                    })
                }
            </Stack>
        </Stack>)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
type AffilaitionInfo = { name: string, color: string };
const clearDropdown = mergeStyles({
    border: "0",
    backgroundColor: "transparent",
    color: "white",
});
const optionStyle = mergeStyles({
    color: "white !important",
    border: "none",
    backgroundColor: "transparent"
})

export function CollectionResponse() {
    let sessionid = Number(useQuery().get("sessionid"));

    const [loadError, setLoadError] = useState("");
    const [session, setSession] = useState<ISession>();
    const [roles, setRoles] = useState<IRole[]>();
    const [collections, setCollections] = useState<ICollection[]>()

    const [affiliations, setAffilaitions] = useState<AffilaitionInfo[]>()
    const [leftAffiliation, setLeftAffiliation] = useState<string>();
    const [rightAffiliation, setRightAffiliation] = useState<string>();

    useEffect(() => {
        (async () => {
            try {
                
                let session = await SessionClient.findById(sessionid);
                const actors = await SessionClient.getActors(sessionid);

                const affs = [...Array.from(new Set(actors.map((pa) => pa.affiliation)))].map((aff) => ({
                    name: aff || "",
                    color: actors?.find((a) => a.affiliation === aff && a.isPrimaryColor)?.color || actors?.find(a => a.affiliation === aff)?.color || ""
                }));

                setAffilaitions(affs);
                setLeftAffiliation(affs[0].name);
                setRightAffiliation(affs[1].name);
                if (session?.id) {
                    setSession(session);
                    setRoles(await SessionClient.getRoles(sessionid));
                    const cols = await SessionClient.getCollections(sessionid, true);
                    cols.forEach((c) => {
                        if (c.scheduledDate)
                            c._scheduleDate = new Date(new Date(c.scheduledDate).getTime() + 5 * 60 * 60 * 1000);
                    })
                    setCollections(cols);
                }
                else
                    throw new Error("Invalid Session Id.");
            } catch (ex) {
                setLoadError(ex.message);
            }
        })()

    }, [sessionid])

    //Should move to load
    let leftRecord: Record<number, ICollection[]> = {};
    let rightRecord: Record<number, ICollection[]> = {};
    collections?.forEach((c) => {
        //let activeRcd: Record<number, ICollection[]>;
        if (c.affiliation.toLowerCase() === leftAffiliation?.toLowerCase()) {
            const curItem = leftRecord[c.turn] || (leftRecord[c.turn] = [])
            curItem.push(c);
        }
        if (c.affiliation.toLowerCase() === rightAffiliation?.toLowerCase()) {
            const curItem = rightRecord[c.turn] || (rightRecord[c.turn] = [])
            curItem.push(c);
        }
    });

    return (<Stack className={appScreen} horizontalAlign="stretch" verticalAlign="stretch" padding="2rem">
        <Stack className={card} grow tokens={{ childrenGap: "1rem" }}>
            <Text variant="xxLargePlus">Collection Responses</Text>
            <LoadOrError error={loadError}>
                <Stack horizontal tokens={{ childrenGap: "0.5rem" }} styles={{ root: { borderBottom: "1px solid #ccc", borderTop: "1px solid #ccc", padding: "1rem 0.25rem", backgroundColor: "#f8f8ff" } }}>
                    <Text className={mergeStyles({ fontWeight: "bold" })}>Session Name:</Text><Text>{session?.name}</Text>
                    <Text className={mergeStyles({ fontWeight: "bold" })}>Client Code:</Text><Text>{session?.clientName}</Text>
                    <Text className={mergeStyles({ fontWeight: "bold" })}>Session Turn:</Text><Text>{session?.turn}/{session?.totalTurns}</Text>
                </Stack>
            </LoadOrError>
            {affiliations && <Stack horizontal grow horizontalAlign="stretch" tokens={{ childrenGap: "1rem" }} >
                <Stack grow tokens={{ childrenGap: "1rem" }} style={{ width: "50%" }}>
                    <div className={mergeStyles({ textAlign: "center", backgroundColor: affiliations.find(a => a.name === leftAffiliation)?.color, padding: "0.5rem", color: "white" })}>
                        <Dropdown
                            styles={{
                                dropdown: clearDropdown,
                                title: optionStyle,
                            }}
                            selectedKey={leftAffiliation}
                            options={affiliations.map((a) => ({ key: a.name, text: a.name }))}
                            onChange={(ev, dd) => setLeftAffiliation(String(dd?.key))}
                        />
                    </div>
                    <Stack styles={{ root: { maxHeight: "calc(100vh - 18rem)", overflow: "auto" } }} >
                        {Object.keys(leftRecord).sort().map((c) => <TurnGroup roles={roles?.filter(r => r.affiliation?.toLowerCase() === leftAffiliation?.toLowerCase()) || []} turn={Number(c)} collections={leftRecord[Number(c)]} session={session} />)}
                    </Stack>
                </Stack>
                <Stack grow tokens={{ childrenGap: "1rem" }} style={{ width: "50%" }}>
                    <div className={mergeStyles({ textAlign: "center", backgroundColor: affiliations.find(a => a.name === rightAffiliation)?.color, padding: "0.5rem", color: "white" })}>
                        <Dropdown
                            styles={{
                                dropdown: clearDropdown,
                                title: optionStyle,
                            }}
                            selectedKey={rightAffiliation}
                            options={affiliations.map((a) => ({ key: a.name, text: a.name }))}
                            onChange={(ev, dd) => setRightAffiliation(String(dd?.key))}
                        />
                    </div>
                    <Stack styles={{ root: { maxHeight: "calc(100vh - 18rem)", overflow: "auto" } }}>
                        {Object.keys(rightRecord).sort().map((c) => <TurnGroup roles={roles?.filter(r => r.affiliation?.toLowerCase() === rightAffiliation?.toLowerCase()) || []} turn={Number(c)} collections={rightRecord[Number(c)]} session={session} />)}
                    </Stack>
                </Stack>
            </Stack>}
        </Stack>
    </Stack>)
}

