import { ISessionBasic, SessionClient } from "../api/client";
import { SetupPage } from "./dash/SetupPage";
import React, { useState, useEffect } from "react";
import { Image, Dropdown,  ImageFit, MessageBarType } from "@fluentui/react";
import { useMessageState } from "./util";

export function ResultSetupPage(props: { session: ISessionBasic }) {
    const [session, setSession] = useState(props.session);
    const [winner, setWinner] = useState<string>(session.winner || "");
    const [affiliations, setAffiliations] = useState<string[]>([])
    //const [anthem, setAnthem] = useState<HTMLAudioElement>()
    const [message, setMessageState] = useMessageState();
    useEffect(() => {
        (async () => {
            let actors = await SessionClient.getActors(Number(session.id));
            setAffiliations(Array.from(new Set(actors.map(a => a.affiliation))));
        })()
    }, [session]);

    //useEffect(() => {
      //  if (anthem)
        //    anthem.pause();

        //if (winner) {
          //  let newAnthem = new Audio(`https://waymarkintel.blob.core.windows.net/assets-${session.scenarioId}/anthem_${winner?.toLowerCase()}.mp3`);
            //newAnthem.play();
            //setAnthem(newAnthem);
        //}
    //}, [winner])

    return <SetupPage title="Session Winner"
        message={message}
        commands={winner ? [
            {
                text: "Save", key: "save", iconProps: { iconName: "Save" },
                onClick: () => {
                    (async () => {
                        try {
                            await SessionClient.setWinner(Number(session.id), winner)
                            setSession({ ...session, winner });
                            setMessageState(["Winner Saved!", MessageBarType.success])
                        }
                        catch (ex) {
                            setMessageState([ex.message, MessageBarType.error])
                        }
                    })()
                }
            },
            {
                text: session.released ? "Recall" : "Release", key: "release", iconProps: { iconName: "Upload" },
                disabled: !session.winner || session.winner !== winner,
                onClick: () => {
                    (async () => {
                        try {
                            if (session.released) {
                                await SessionClient.recallWinner(Number(session.id));
                                setSession({ ...session, released: undefined });
                                setMessageState(["Winner Recalled!", MessageBarType.success]);
                            }
                            else {
                                await SessionClient.releaseWinner(Number(session.id));
                                setSession({ ...session, released: new Date() });
                                setMessageState(["Winner Released!", MessageBarType.success]);
                            }
                        }
                        catch (ex) {
                            setMessageState([ex.message, MessageBarType.error])
                        }
                    })()
                }
            }
        ] : undefined}
        crumbs={[
            { key: "session", text: props.session.name || "", isCurrentItem: true },
            { key: "DROPDOWN", text: "" }
        ]}
        crumbRender={(p, dr) => {
            if (p?.key === "DROPDOWN")
                return <Dropdown onChange={(e, dd) => { setWinner(String(dd?.key)); }} selectedKey={winner} options={affiliations.map((a) => ({ key: a, text: a }))} placeholder="Select a winner"
                    isDisabled={Boolean(session.released)}

                />
            else if (dr)
                return dr(p)
            else return <div />;
        }} >


        <Image
            styles={
                {
                    root: {
                        width: "100%",
                        height: "25rem"
                    }
                }

            }
            imageFit={ImageFit.centerContain}
            placeholder="Loading..."
            src={winner ? `https://waymarkintel.blob.core.windows.net/assets-${props.session.scenarioId}/flag_${winner?.toLowerCase()}.jpg` : undefined} />

    </SetupPage>
}