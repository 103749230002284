
import { useSearchParams, useMessage, TimePicker, runAsync } from "../util";
import { useState, useEffect } from "react";
import { IRole, ISession, SessionClient, ScheduleClient } from "../../api/client";
import { SetupPage } from "./SetupPage";
import React from "react";
import { MessageBarType, Stack, Dropdown, TextField, PrimaryButton, DatePicker, Separator } from "@fluentui/react";

export function EmailerPage() {
    const sessionId = Number(useSearchParams().get("sessionid"));
    const [session, setSession] = useState<ISession>();
    const [message, setMessage] = useMessage(["Schedule an email to go out to a team. Emails scheduled in the past will be delivered on the next scheduled run.", MessageBarType.info])
    const [allRoles, setAllRoles] = useState<IRole[]>([]);
    const [teams, setTeams] = useState<string[]>([]);
    const [roleIds, setRoleIds] = useState<number[]>([]);
    const [subject, setSubject] = useState<string>("");
    const [body, setBody] = useState<string>("");
    const [date, setDate] = useState<Date>();
    const [time, setTime] = useState<string>()

    useEffect(() => {
        (async () => {
            try {
                let s = await SessionClient.findById(sessionId);
                let rls = await SessionClient.getRoles(sessionId);
                setSession(s);
                setAllRoles(rls.filter(r => r.id > 0).sort((a, b) => {
                    return a.team.localeCompare(b.team) || a.role.localeCompare(b.role)
                }));
            } catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [sessionId, setMessage]);

    let allTeams = Array.from(new Set(allRoles.filter(r => r.IsTeam).map(r => r.team)))
    return <SetupPage title={"Session Emailer"} crumbs={[{ key: "session", text: session?.name || "", isCurrentItem: true }]} message={message} >
        <Stack>
            <Stack disableShrink horizontal tokens={{ childrenGap: "1rem" }} horizontalAlign="stretch" styles={{
                root: {

                    "& > *": {
                        flexGrow: 1,
                        flexShrink: 0,
                        flexBasis: "3rem"
                    }

                }
            }} >
                <DatePicker value={date} label="Date" onSelectDate={(d) => { if (d) setDate(d) }} placeholder="Set a date" />
                <TimePicker name="time" label="Time" placeholder="Pick a time" onChange={(n, dd) => { setTime(dd?.key as string) }} />
            </Stack>
            <Stack disableShrink horizontal tokens={{ childrenGap: "1rem" }} horizontalAlign="stretch" styles={{
                root: {

                    "& > *": {
                        flexGrow: 1,
                        flexBasis: "3rem"
                    }

                }
            }} >
                <Dropdown label="Team(s)" options={allTeams.map(t => ({ key: t, text: t }))} multiSelect placeholder="Pick Team(s)"
                    onChange={(ev, dd) => {
                        if (dd?.selected) {
                            setTeams([...teams, dd.key as string])
                        }
                        else {
                            let ti = teams.findIndex(t => t === dd?.key);
                            if (ti > -1)
                                setTeams(teams.splice(ti, 1))
                        }
                    }}
                />
                <Dropdown label="Roles(s)" options={allRoles.filter(r => !r.IsTeam && teams.includes(r.team)).map(r => ({ key: r.id, text: `${r.team} \\ ${r.role} \\ ${r.ob}` }))} multiSelect placeholder="Pick role(s)"
                    onChange={(ev, dd) => {
                        if (dd?.selected) {
                            setRoleIds([...roleIds, dd.key as number])
                        }
                        else {
                            let i = roleIds.findIndex(t => t === dd?.key);
                            if (i > -1)
                                setRoleIds(roleIds.splice(i, 1))
                        }
                    }}
                />
            </Stack>
            <TextField label="Subject" value={subject} placeholder="Add a subject" onChange={(ev, newVal) => setSubject(newVal || "")} />
            <TextField label="Body" value={body} placeholder="Add a body" onChange={(ev, newVal) => setBody(newVal || "")} multiline multiple />
            <Separator />
            <PrimaryButton style={{ alignSelf: "end", marginLeft: "auto" }} disabled={!subject || !body || !date || !time || !roleIds.length}
                onClick={() =>
                    runAsync(ScheduleClient.add(subject, -1, sessionId, date as Date, time as string, { type: "email", body, roleIds }), setMessage, "Email has been scheduled.")
                }
            >Schedule Email</PrimaryButton>
        </Stack>
    </SetupPage >

}