import { createContext } from "react";

function getCookie(name: string): string {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2)
        return parts.pop()?.split(";").shift() || "";
    else
        return "";
}

export enum HttpMethod {
    POST = "post",
    GET = "get"
}

export let AuthContext = createContext({ isLoggedIn: false })

class _ApiClient {

    token: string = "";
    signedInUser: {
        id?: number,
        confirmed?: boolean
        firstName?: string
        lastName?: string
        role?: number
        // paid?: boolean
    } = {};

    tempHost = window.location.host === "localhost:3000" ? "http://localhost:1337" : "";




    get isLoggedIn() {
        return Boolean(this.token);
    }

    get isAdmin() {
        return ((this.signedInUser.role || 0) & 1) === 1
    }
    async logout() {
        await fetch("/api/me/signout", {
            credentials: this.tempHost ? "include" : undefined
        });
        this.token = "";
    }

    async loginFromCookie(forceToken?: string) {
        this.token = forceToken || decodeURIComponent(getCookie("oj-csrf-token"));
        this.signedInUser = (await this.post('/api/me/iam'));
        if (this.signedInUser.confirmed)
            sessionStorage.setItem("__user", JSON.stringify(this.signedInUser));
    }
    async login(user: string, pass: string) {
        if (user && pass) {
            user = user.trim();
            let signInResponse = await fetch(this.tempHost + '/api/me/signin', {
                method: 'POST', body: JSON.stringify({ user: user, pass: pass }),
                credentials: this.tempHost ? "include" : undefined,
                headers: { 'Content-Type': 'application/json' }
            });
            if (signInResponse.ok) {
                await this.loginFromCookie();
            }
            else {
                try {
                    const msg = await signInResponse.json();
                    throw msg;
                }
                catch (err) {
                    throw new Error("Invalid Username/Password.  Please try again.");
                }
            }

        }
        else {
            throw new Error("Invalid Username/Password.  Please try again.");
        }
    }
    async post<T = any>(url: string, data?: any) {
        return (await (await this.run(url, data, HttpMethod.POST)).json()) as T;
    }
    async run(url: string, data: any, method: HttpMethod) {
        let resp = await fetch(this.tempHost + url, {
            method: method || "post",
            body: JSON.stringify(data),
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'oj-csrf-token': this.token
            }
        });
        if (!resp.ok) {
            let erroOut;
            try {
                erroOut = await resp.json();
                erroOut.status = resp.status;
            }
            catch (err) {
                erroOut = { status: resp.status, message: resp.statusText };
            }
            throw erroOut;
        }

        return resp;
    }
}

export const ApiClient = new _ApiClient();

interface IRef {
    id: number
    code: string
    name: string
}

export class PortalClient {


    static async findById(clientId: number) {
        return await ApiClient.post<IClient>("/api/client/find", { id: clientId });
    }

    static async users() {
        return await ApiClient.post<IUser[]>("/api/user/list");
    }

    static async clients() {
        return await ApiClient.post<IClient[]>("/api/client/list");
    }
    static async deleteClient(id: number) {
        return await ApiClient.post("/api/client/delete", { id });
    }
    static async saveClient(client: IClient) {
        return await ApiClient.post<IClient>("/api/client/add", client);
    }
}

export class RefClient {
    static async clients() {
        return await ApiClient.post<IRef[]>("/api/ref/client");
    }
    static async scenarios() {
        return await ApiClient.post<IRef[]>("/api/ref/scenario");
    }
}


export interface ICollection {
    id: number,
    sessionId: number,
    affiliation: string,
    source: string,
    priority: "High" | "Medium" | "Low",
    title: string,
    reportNumbers: string,
    request: string,
    response: string,
    long: number,
    lat: number
    turn: number,
    scheduledDate?: string,
    roleId?: number,
    _scheduleDate?: Date
    teamName?: String
}

enum Priority {
    "High" = 3,
    "Medium" = 2,
    "Low" = 1
}
export interface IActor {
    id: number;
    name: string;
    code: string;
    affiliation: string;
    color: string,
    isMember: boolean,
    isPrimaryColor: boolean
}

export interface IRole {
    id: number
    participants: number
    team: string
    role: string
    ob: string
    IsTeam: boolean
    affiliation?: string
}

export interface IDocument {
    id: number
    roleId: number
    sessionId: string
    scheduledDate: string,
    _scheduledDate: Date,
    scenarioId: string
    name: string
    createdAt: Date,
    type: string
    extra?: {
        type: string
    }
}
export interface IScenarioSchedule {
    id: number,
    scenarioId: number
    offset: Number
    role: string,
    simDate: string,
    source: string,
    ob: string,
    team: string,
    dayPart: string,
    participants: number,
    documentName: string
}
export interface ISessionSchedule {
    id: number,
    documentName: string,
    team: string,
    role: string,
    scheduledDate: Date,
    status: number
}

export interface IUserNDA {
    id: number,
    firstName: string,
    lastName: string,
    agreedOn: string,
    agreedOnDate?: Date,
    ipAddress: string
    signature: string
}

export interface IRoleUpdate {
    id: number, affiliation: string, roles: number[]
}

export class ScheduleClient {
    static async reschedule(date: Date, time: string, ids: number[]) {
        return await ApiClient.post("/api/schedule/reschedule", { ids: ids, date: date, time: time });
    }
    static async release(ids: number[]) {
        return await ApiClient.post("/api/schedule/release", { ids: ids });
    }
    static async disable(ids: number[]) {
        return await ApiClient.post("/api/schedule/disable", { ids: ids });
    }
    static async add(name: string, roleId: number, sessionId: number, date: Date, time: string, extra?: any) {
        return await ApiClient.post("/api/schedule/add", {
            name,
            roleId,
            sessionId,
            date,
            time,
            extra
        })
    }
    static async tick(date: Date, time: string) {
        return await ApiClient.post("/api/schedule/tick", {
            date,
            time,
        })
    }
}
export interface IDashItem {
    title: string
    stat: string
}

export class SessionClient {


    static async addPayment(transactionId: string, sessionId: number, userId: number) {
        return await ApiClient.post<{ id: number, transactionId: string }[]>("/api/session/sessionPayment", {
            transactionId,
            sessionId,
            status: "Active",
            source: "Paypal",
            userId
        });
    }
    static async removePayment(paymentId: number) {
        return await ApiClient.post<{}>("/api/session/sessionPayment", {
            id: paymentId,
            status: "Inactive"
        });
    }
    // static async checkPaymentStatus(userId: number, sessionId: number, bypassIfAdmin?: boolean) {
    //     let result = false;
    //     // if client has payed for respective sessionID, return true, else return false
    //     // console.log(`${userId} + ${sessionId}`)
    //     if (ApiClient.isAdmin && bypassIfAdmin) {
    //         result = true;
    //     }
    //     else if (userPaymentData[userId] && userPaymentData[userId].length > 0) {
    //         if (userPaymentData[userId].includes(sessionId)) {
    //             result = true;
    //         }
    //     }
    //     return result;
    // }

    static async getDashboard(sessionId: number) {
        return await ApiClient.post<IDashItem[]>("/api/session/dashboard", { sessionId });
    }
    static async setWinner(sessionId: number, affiliation: string) {
        return await ApiClient.post("/api/session/winner", { mode: 'save', sessionId, affiliation });
    }
    static async releaseWinner(sessionId: number) {
        return await ApiClient.post("/api/session/winner", { mode: 'release', sessionId });
    }
    static async recallWinner(sessionId: number) {
        return await ApiClient.post("/api/session/winner", { mode: 'recall', sessionId });
    }
    static async openDocument(sessionId: number | undefined, roleId: number, docId: number) {
        return await ApiClient.post<{ link: string }>("/api/session/opendoc", { sessionId: sessionId, roleId: roleId, docId: docId });
    }
    static async deleteDocument(documentId: number, roleId: number, sessionId: number, affiliation?: string) {
        await ApiClient.post<any>("/api/session/deleteDocument", { documentId, sessionId, roleId, affiliation });

    }
    static async getDocuments(roleId: number, sessionId: number, affiliation?: string) {
        const docs = await ApiClient.post<IDocument[]>("/api/session/documents", { sessionId, roleId, affiliation });
        docs.forEach((d) => {
            d.extra = JSON.parse(d.extra as any)
            d.type = d.extra?.type || "report"
        });
        return docs;
    }

    static async getSchedule(sessionId: number) {
        return await ApiClient.post<ISessionSchedule[]>("/api/session/schedule", { sessionId: sessionId });
    }
    static async getNDA(sessionId: number) {
        return await ApiClient.post<IUserNDA[]>("/api/session/nda", { sessionId: sessionId });
    }
    static async setUsersAndRoles(sessionId: number, updates: IRoleUpdate[], participants: number) {
        await ApiClient.post("/api/simulation/updateroles", { id: sessionId, updates: updates, participants });
    }

    static async getActiveUsers(sessionId: number) {
        return (await ApiClient.post<ISessionUser[]>("/api/user/sessionroles", { sessionId: sessionId })).filter(u => u.roles) as IUser[]
    }
    static async getUsersAndRoles(sessionId: number, noWorkspace: boolean): Promise<[(ISessionUser & {
        all: number,
        humint: number,
        imint_am: number,
        imint_gn: number,
        sigint: number
    })[], IRole[], string[]]> {

        let users = await ApiClient.post<(ISessionUser & {
            all: number,
            humint: number,
            imint_am: number,
            imint_gn: number,
            sigint: number
        })[]>("/api/user/sessionroles", { sessionId: sessionId });
        let roles = await this.getRoles(sessionId);
        let actors = await this.getActors(sessionId);

        users.forEach((u) => {
            if (u._scores) {
                const scores = JSON.parse(u._scores)
                u.all = scores.all;
                u.humint = scores.humint;
                u.imint_am = scores.imint_am;
                u.imint_gn = scores.imint_gn;
                u.sigint = scores.sigint;
            }

            u.roleList = [];

            if (u.roles) {
                (JSON.parse(u.roles) as number[]).forEach((id) => {
                    let role = roles.find(r => r.id === id);
                    if (role && (!noWorkspace || role.id > 0))
                        u.roleList.push(role);
                })
            }

            if (u.roleList.length) {
                u.teamRole = roles.find(r => r.IsTeam && r.team === u.roleList[0].team);
                u.teamText = u.teamRole?.team;
            }


        });

        return [users, noWorkspace ? roles.filter(r => r.id > 0) : roles, Array.from(new Set(actors.map(a => a.affiliation as string)))];
    }

    static async getActors(sessionId: number) {
        return (await ApiClient.post<IActor[]>("/api/session/actors", { sessionId }));
    }
    static async getRoles(sessionId: number) {
        return (await ApiClient.post<IRole[]>("/api/simulation/roles", { id: sessionId }));
    }

    //Should just be on the session when you call a Find on the session.
    static async getCollections(sessionId: number, all?: boolean) {
        return (await ApiClient.post<ICollection[]>("/api/session/collection", {
            action: "get",
            sessionId: sessionId,
            all: all
        })).sort((a, b) => (Priority[b.priority] - Priority[a.priority]) ||
            a.source.localeCompare(b.source) ||
            a.title.localeCompare(b.title));
    }
    static async getCollectionDoc(documentId: number, sessionId: number) {
        return (await ApiClient.post<ICollection>("/api/session/collection", {
            action: "doc",
            id: documentId,
            sessionId
        }));
    }

    static async collectionResponse(sessionId: number, response: string, id: number, roleId?: number, date?: Date, time?: string) {
        return await ApiClient.post("/api/session/collection", {
            action: "update",
            sessionId: sessionId,
            response: response,
            roleId: roleId,
            id: id,
            date: date,
            time: time
        });
    }

    static async delete(sessionId: number) {
        await ApiClient.post("/api/session/delete", { id: sessionId });
    }
    static async save(saveInfo: {
        participants: number, name: string, clientId: number,
        scenarioId: number, hpt: number, turns: number, seedTurn: number,
        startDate: Date, morningTime: string, eveningTime: string, cost: string
    }) {
        console.log(JSON.stringify(saveInfo))
        let output = (await ApiClient.post<ISession>("/api/session/add", saveInfo));
        return output;
    }

    static async refreshAccessToken(sessionId: number) {
        return await ApiClient.post<IClient[]>("/api/session/refreshcode", {
            sessionId
        });
    }
    static async get() {
        return await ApiClient.post<ISession[]>("/api/session/list");
    }
    static async getBasic() {
        return await ApiClient.post<ISessionBasic[]>("/api/session/basic");
    }

    static async findById(sessionId?: number) {
        return (await ApiClient.post<ISession[]>("/api/session/find", { id: sessionId }))[0];
    }
}

export interface ISessionUser extends IUser {
    roles?: string,
    roleList: IRole[]
    teamRole?: IRole
    affiliation?: string;
    teamText?: string,
    isDirty: boolean,
    Score: number
    paymentSource?: string,
    paymentTransactionId?: string,
    paymentId?: number
    _scores: string,
}

export interface IUser {
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    createdAt?: Date,
    clientCode?: string
}
export interface IUserProfile {
    firstName: string,
    lastName: string,
    email: string,
    clientCode: string,
    clientName: string,
    sessionName: string,
    sessionId: number,
    Score: number,
    response: any
}
export interface IClient {
    id?: number,
    name?: string,
    code?: string,
    poc?: string,
    pocTitle?: string,
    email?: string,
    phone?: string,
    city?: string,
    state?: string
}

export interface ISessionBasic {
    id?: number
    name?: string
    clientId?: number
    scenarioId?: number,
    scenarioName?: string,
    clientName?: string,
    createdAt?: Date,
    turn?: number,
    totalTurns?: number,
    HasWorkSpace: boolean,
    winner?: string,
    released?: Date,
    paymentSource?: string,
    paymentTransactionId?: string,
    paymentId?: number,
    cost?: number,

}

export interface ISession extends Omit<ISessionBasic, "paymentSource" | "paymentTransactionId" | "paymentId"> {
    clientName?: string,
    scenarioCode?: string
    pendingCount?: number,
    participantCount?: number,
    userCount?: number,
    affiliation: string,
    hpt?: number
    accessCode?: string
}

export interface IScenarioExt extends IScenario {
    actors?: {
        code: string,
        name: string,
        affiliation: string
    }[],
    bases?: {
        name: string,
        long: number,
        lat: number,
        type: string[],
        actoreCode: string
    }[],
    units?: {
        name: string,
        lat: number,
        long: number,
        unitTypeCode: string
        baseName: string,
        transportName: string
    }[],
    roles?: {
        participants: number,
        team: string,
        role: string,
        ob: string
    }[],
    actions?: {
        "unittype": string,
        "action": string,
        "actor": string
        "extra": string
        "ogSpeed": number
        "ogUnit": string
        "ogMaxRange": number
        "maxUnit": string
        "rangeMethod": string
        "speed": number
        "maxRange": number
    }[]
}
export interface IScenario {
    id?: number,
    code: string,
    name: string,
    description: string
}

export class ScenarioClient {
    scenarioId?: number;

    // static create(code: string, name: string, description: string): IScenario {
    //     throw "Error";
    // }
    static async get() {
        return await ApiClient.post<IScenario[]>("/api/scenario/get");
    }
    static async upsert(name: string, code: string, description: string, id?: number) {
        return await ApiClient.post<{ id: number }>("/api/scenario/upsert", {
            id: id || undefined,
            name: name,
            code: code,
            description: description
        });
    }
    static async documents(id: number) {
        return await ApiClient.post<IDocument[]>("/api/scenario/documents", { id: id })
    }
    static async schedule(id: number) {
        return await ApiClient.post<IScenarioSchedule[]>("/api/scenario/schedule", { id: id })
    }
    static async roles(id: number) {
        return await ApiClient.post<IRole[]>("/api/scenario/roles", { id: id })
    }
    static async import(id: number, entity: string, data: any[]) {
        return await ApiClient.post<{ id: number }>("/api/scenario/import", {
            id: id,
            data: data,
            entity: entity
        });
    }
    static async findById(scenarioId?: number) {
        if (!scenarioId)
            return { code: "", name: "", description: "" } as IScenarioExt
        else {
            let output = await ApiClient.post<IScenarioExt>("/api/scenario/find", { id: scenarioId });
            output.actors = JSON.parse(output.actors as any);
            output.bases = JSON.parse(output.bases as any);
            output.units = JSON.parse(output.units as any);
            output.roles = JSON.parse(output.roles as any);
            output.actions = JSON.parse(output.actions as any);
            return output;
        }
    }
    constructor(scenarioId?: number) {
        this.scenarioId = scenarioId;

    }
}

export interface IMessageBoard {
    id: number,
    title: string,
    replies: number,
    lastAt: Date
    createdAt: Date
}

export interface IMessagePost {
    id: number;
    messageBoardId: number;
    message: string,
    userId: number,
    userName: string,
    createdAt: Date

}

export class MessageBoardClient {
    static async post(title: string, message: string, sessionId: number, roleId: number, notifyAdmin: boolean, affiliation?: string) {
        await ApiClient.post("/api/messageboard/post", {
            title,
            message,
            sessionId,
            roleId,
            notifyAdmin,
            affiliation
        })
    }
    static async reply(message: string, messageBoardId: number, sessionId: number, roleId: number, notifyAdmin: boolean, affiliation?: string) {
        await ApiClient.post("/api/messageboard/reply", {
            sessionId,
            roleId,
            messageBoardId,
            message,
            notifyAdmin,
            affiliation
        })
    }

    static async getPosts(sessionId: number, roleId: number, messageBoardId: number, affiliation?: string) {
        return await ApiClient.post<IMessagePost[]>("/api/messageboard/get", {
            sessionId: sessionId,
            roleId: roleId,
            messageBoardId: messageBoardId,
            affiliation
        })
    }

    static async getMessageBoard(sessionId: number, roleId: number, affiliation?: string) {
        return await ApiClient.post<IMessageBoard[]>("/api/messageboard/get", {
            sessionId: sessionId,
            roleId: roleId,
            affiliation
        });
    }


}

export class UserClient {

    static async find(userId: number) {
        return await ApiClient.post<IUser>("/api/me/find", { userId });
    }

    static async resetPassword(pass: string, tk: string) {
        return await ApiClient.post<{ message: string }>("/api/me/resetpassword", { tk, pass });
    }
    static async forgotPassword(email: string) {
        return await ApiClient.post<{ message: string }>("/api/me/recover", { email, mode: "pass" });
    }
    static async forgotUser(email: string) {
        return await ApiClient.post<{ message: string }>("/api/me/recover", { email, mode: "user" });
    }
    static async checkAccess(accesscode: string) {
        return await ApiClient.post<IClient>("/api/me/accesscheck", { accesscode });
    }
    static async resendCode() {
        return await ApiClient.post("/api/me/confirmcode", { mode: "create" });
    }
    static async confirmCode(code: string) {
        return await ApiClient.post("/api/me/confirmcode", { mode: "test", code })
    }
    static async createAccount(email: string, pass: string, first: string, last: string, accesscode: string, score: number, signature: string, scores: any, profile: any) {
        return await ApiClient.post("/api/me/createaccount", { mode: "create", email, pass, first, last, accesscode, score, profile, scores, signature })
    }
    static async blockUser(userId: number) {
        return await ApiClient.post("/api/me/block", { userId });
    }
    static async profile(userId: number) {
        const profiles = await ApiClient.post<IUserProfile[]>("/api/me/profile", { userId });
        profiles.forEach((p) => {
            if (p.response) {
                p.response = JSON.parse(p.response as any)
            }
        })
        return profiles;
    }

    static async getTestAccounts(sessionId: number) {
        return await ApiClient.post<(IUser & { isEnabled: boolean })[]>("/api/me/testaccounts", { mode: "get", sessionId });
    }
    static async setTestAccounts(sessionId: number, userIds: number[]) {
        await ApiClient.post("/api/me/testaccounts", { mode: "set", sessionId, userIds });
    }

}