import React, { useState } from "react";
import {  Stack, TextField,PrimaryButton } from "@fluentui/react";
import {  UserClient } from "../api/client";
import { FormCard } from "./card/FormCard"


import { useHistory } from "react-router-dom";
import { useSearchParams } from "./util";
import { centerPiece } from "../styles";


export function ResetPassBox() {
    //const [ms, setMs] = useMessageState();
    const [newPass, setNewPass] = useState("");
    const tk = useSearchParams().get("tk");
    const hist = useHistory();
    return (
        <FormCard formTitle="Web Portal Login" onSubmit={(ev) => { ev.stopPropagation(); ev.preventDefault(); }}
            styleExt={centerPiece}>
            <TextField name="passwordField" value={newPass} label="Password (8 characters minimum)" placeholder="Enter your new password" type="password" onChange={(ev, tf) => { setNewPass(tf || "") }} />
            <Stack.Item align="end"><PrimaryButton type="submit"
                onClick={() => {
                    if (newPass.length < 8)
                        alert("Password must be at least 8 characters");
                    else if (!tk)
                        alert("Missing Auth Token");
                    else
                        (async () => {
                            try{
                                await UserClient.resetPassword(newPass, tk);
                                hist.push("/");
                            }
                            catch(ex){
                                alert(ex);
                            }

                        })()

                }}

                primary>Reset</PrimaryButton></Stack.Item>
        </FormCard>
    )
}



