import { useState, useEffect } from "react";
import { IDropdownOption, Dropdown } from "@fluentui/react";
import React from "react";

export function DropdownAsync(props: { required?: boolean, label: string, name: string, placeholder?: string, getItems: () => Promise<{ key: string|number, text: string }[]>, seed?: string|number, onChange: (name: string, dd: IDropdownOption | undefined) => void }) {
    const [options, setOptions] = useState<{ key: string|number, text: string }[]>([])
    //const [selectedKey, setSelectedKey] = useState<string|null>();
    useEffect(()=>{
        (async ()=>{
            setOptions(await props.getItems());
        })()
    },[setOptions,props, props.getItems])
    
    const [selectedItem, setSelectedItem] = useState<IDropdownOption>();

    const onChange = (ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined) => {
        props.onChange(props.name, item);
        setSelectedItem(item);
    }
    return (
        <Dropdown selectedKey={selectedItem?.key || props.seed} label={props.label} options={options} disabled={!options.length} onChange={onChange} placeHolder={props.placeholder} required={props.required} />
    );
}