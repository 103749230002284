import { SetupPage } from "./SetupPage";
import React, { useState } from "react";
import { DatePicker, Stack, MessageBarType, PrimaryButton } from "@fluentui/react";
import { TimePicker, useMessage, runAsync } from "../util";
import { ScheduleClient } from "../../api/client";

export function SimulateDate() {
    const [date, setDate] = useState<Date>();
    const [time, setTime] = useState<string>();
    const [message, setMessage] = useMessage(["Submitting this form will simulate the passage of time across the entire application. This will affect every session. Please make sure you understand what you are doing when using this page.", MessageBarType.severeWarning])
    return <SetupPage title="Simulate Date" message={message}>
        <Stack horizontal tokens={{ childrenGap: "1rem" }} horizontalAlign="stretch" styles={{
            root: {

                "& > *": {
                    flexGrow: 1,
                    flexShrink: 0,
                    flexBasis: "3rem"
                }

            }
        }}>
            <DatePicker value={date} label="Schedule Date" placeholder="Pick a date" onSelectDate={(d) => setDate(d as Date)} />
            <TimePicker name="time" label="Schedule Time " placeholder="Pick a time" onChange={(ev, dd) => setTime(dd?.key as string)} />
        </Stack>
        <PrimaryButton disabled={!time || !date}
            onClick={() =>
                runAsync(
                    ScheduleClient.tick(date as Date, time as string), setMessage, "The date has been processed.")

            }>Simulate Date (This will affect all sessions)</PrimaryButton>
    </SetupPage>

}