import React, { useState } from "react";
import { Text, Stack, Icon, mergeStyles, TextField, Button, MessageBarType, MessageBar, PrimaryButton } from "@fluentui/react";
import { FormCard } from "./card/FormCard";
import { IStyleExt } from "./IExt";
import { bgColor, borderColor, red, yellow, green } from "../colors";
import { useLocation, useHistory } from "react-router-dom";
import { UserClient } from "../api/client";
import { useMessage } from "./util";

const iconClass = mergeStyles({
    fontSize: "2.5rem",
    height: "4rem",
    lineHeight: "4rem",
    width: "3rem",
    color: borderColor,
});


export function HelpBox(props: IStyleExt) {
    let hist = useHistory();
    let getMode = new URLSearchParams(useLocation().search).get("mode") as "forgotuser" | "forgotpass" | "newaccount";
    const [fieldInput, setFieldInput] = useState<string>();
    const [message, setMessage] = useMessage();
    let title = ""
    let ph = "";
    switch (getMode) {
        case "forgotpass":
            title = "Provide Username";
            ph = "Account Username";
            break;
        case "forgotuser":
            title = "Provide Email Address";
            ph = "Registered Email";
            break;
        case "newaccount":
            title = "Provide Access Code"
            ph = "Access Code"
            break;
    }

    function IconButton(props: { mode: "forgotuser" | "forgotpass" | "newaccount", label: string, iconName: string, isActive?: boolean, activeColor?: string }) {
        let history = useHistory();
        let isActive = props.mode === getMode;
        return (
            <Stack horizontalAlign="center" styles={
                {
                    root: {
                        flexBasis: "33%",
                        border: `1px solid ${borderColor}`,
                        textAlign: "center",
                        marginTop: "0.25rem",
                        cursor: "pointer",
                        padding: "0.25rem",
                        backgroundColor: isActive ? bgColor : undefined,

                        "&:hover": {
                            backgroundColor: bgColor,
                            color: "black"
                        },
                        "&:hover *": {
                            color: !isActive ? "black" : ""
                        },
                        "&:active> i": {
                            color: props.activeColor
                        }

                    }
                }
            } onClick={() => {
                setMessage(undefined);
                setFieldInput("");

                history.push(`/login/help?mode=${props.mode}`);
            }} grow>
                <Icon iconName={props.iconName} className={iconClass}
                    styles={
                        {
                            root: {
                                color: isActive ? props.activeColor : undefined
                            }
                        }
                    }
                />
                <Text
                    styles={
                        {
                            root: {
                                paddingBottom: "0.25rem",
                                color: isActive ? "black" : red
                            }
                        }
                    }
                >{props.label}</Text>
            </Stack>)
    }





    return (
        <FormCard formTitle="How Can We Help?" styleExt={props.styleExt}>
            <Stack horizontal tokens={
                {
                    childrenGap: "0.5rem"
                }} horizontalAlign="center">
                <IconButton mode="forgotuser" label="Forgot Username" iconName="Contact" activeColor={red} />
                <IconButton mode="forgotpass" label="Forgot Password" iconName="AsteriskSolid" activeColor={yellow} />
                <IconButton mode="newaccount" label="Create Account" iconName="CirclePlus" activeColor={green} />

            </Stack>
            {message && <MessageBar messageBarType={message[1]}>{message[0]}</MessageBar>}
            {getMode && <TextField value={fieldInput} label={title} placeholder={ph} onChange={(ev, value) => {
                setFieldInput(value);
            }} />}
            <Stack horizontalAlign="stretch" horizontal tokens={{ childrenGap: "0.5rem" }} styles={{
                root: {

                    "&>*": {
                        flexBasis: "100%"
                    }

                }
            }}>

                <Button onClick={() => hist.push("/login")}>Back To Login</Button>
                {getMode && <PrimaryButton
                    onClick={() => {
                        (async () => {
                            try {
                                if (fieldInput) {
                                    let outputMessage = "";
                                    switch (getMode) {
                                        case "forgotpass":
                                            outputMessage = (await UserClient.forgotPassword(fieldInput)).message;
                                            setMessage(outputMessage, MessageBarType.info);
                                            break;
                                        case "forgotuser":
                                            outputMessage = (await UserClient.forgotUser(fieldInput)).message;
                                            setMessage(outputMessage, MessageBarType.info);
                                            break;
                                        case "newaccount":
                                            outputMessage = await (await UserClient.checkAccess(fieldInput)).name as string;
                                            hist.push("/login/signup?accesscode=" + fieldInput);
                                            break;
                                    }

                                }
                            } catch (ex) {
                                setMessage(ex.message, MessageBarType.error);
                            }
                        })()
                    }}
                >Submit</PrimaryButton>}
            </Stack>
        </FormCard>)
}
