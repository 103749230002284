import { mergeStyles, ShimmerElementType, IShimmerElement, Stack, Shimmer } from "@fluentui/react";
import React from "react";

const wrapperClass = mergeStyles({
  padding: 2,

  '& > .ms-Shimmer-container': {
    margin: '10px 0',
  },

});
//   const shimmerWithElementFirstRow:IShimmerElement[] = [
//     { type: ShimmerElementType.line, height: 2, width: '49%', verticalAlign: 'bottom' },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, height: 2, width: '49%' , verticalAlign: 'bottom' },
//   ];
const shimmerWithElementSecondRow: IShimmerElement[] = [
  { type: ShimmerElementType.line, height: 16, width: '49%', verticalAlign: "top" },
  { type: ShimmerElementType.gap, width: '2%' },
  { type: ShimmerElementType.line, height: 16, width: '49%', verticalAlign: "top" },
];
//   const shimmerWithElementThirdRow = [
//     { type: ShimmerElementType.circle, height: 24 },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, height: 16, width: '20%' },
//     { type: ShimmerElementType.gap, width: '5%' },
//     { type: ShimmerElementType.line, height: 16, width: '20%' },
//     { type: ShimmerElementType.gap, width: '10%' },
//     { type: ShimmerElementType.line, height: 16, width: '15%' },
//     { type: ShimmerElementType.gap, width: '10%' },
//     { type: ShimmerElementType.line, height: 16 },
//   ];
//   const shimmerVerticalElement: IShimmerElement[] = [
//     { type: ShimmerElementType.circle },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.circle, height: 15, verticalAlign: 'top' },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, verticalAlign: 'bottom', width: '20%' },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, height: 5, verticalAlign: 'top', width: '20%' },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, height: 16, width: '15%' },
//     { type: ShimmerElementType.gap, width: '2%' },
//     { type: ShimmerElementType.line, height: 10, verticalAlign: 'bottom' },
//   ];
export const ShimmerForm: React.FunctionComponent = () => {
  return (
    <Stack className={wrapperClass}>
      <Shimmer shimmerElements={shimmerWithElementSecondRow} />
      <Shimmer shimmerElements={shimmerWithElementSecondRow} />
      <Shimmer shimmerElements={shimmerWithElementSecondRow} />
    </Stack>
  );
};

