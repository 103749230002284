import React, { useState, useEffect, ReactEventHandler, useMemo, useCallback, ReactElement } from "react";
import { IScenario, ScenarioClient, IScenarioExt, IDocument, IScenarioSchedule, ApiClient } from "../../api/client";
import { Selection, Stack, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, IBreadcrumbItem, TextField, MessageBarType, Pivot, PivotItem, DetailsList, Link, DefaultButton, PrimaryButton, Dialog, DialogFooter, DialogType, Spinner, IColumn } from "@fluentui/react";
import { ShimmerForm } from "../shimmer/ShimmerForm";
import { useSearchParams, useMessageState, useCrumbs, csvURL, Switch404, csvMessage, setSortable, editFormCommands, useMessage } from "../util";
import { useHistory, Route } from "react-router-dom";
import { SetupPage } from "./SetupPage";
import { halfField, fullField, fileUploadStyle, imortExportList } from "../../styles";

import csv from 'csvtojson'
const setupPageTitle = "Setup: Scenarios";
const rootCrumb: (hist: any) => IBreadcrumbItem = (hist) => ({ key: "1", text: "Scenarios", onClick: () => { hist.push("/admin/scenarios") } });



export function ScenarioList() {
    const history = useHistory();
    const [isItemSelected, setIsItemSelected] = useState(false);
    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setIsItemSelected(Boolean(selection.count))
        }
    }), [setIsItemSelected])
    const [message, setMessage] = useMessageState();
    const [scenarios, setScenarios] = useState<IScenario[]>()
    const [hideDialog, setHideDialog] = useState(true); // useBoolean(true);
    const toggleHideDialog = () => {
        setHideDialog((v) => !v);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [inputFile, setInputFile] = useState<File | null>();
    const [fileList, setFileList] = useState<ReactElement[]>();
    useEffect(() => {
        (async function () {
            try {
                setScenarios(await ScenarioClient.get())
            }
            catch (ex) {
                setMessage([ex.message, MessageBarType.error]);
            }
        })()
    }, [setMessage, setScenarios])

    return <SetupPage title={setupPageTitle}
        message={message}
        commands={[
            {
                key: 'newItem',
                text: 'New',
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    history.push("/admin/scenarios/edit")
                }
            },
            {
                key: 'editItem',
                text: 'Edit',
                disabled: !isItemSelected,
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    let scen = (selection.getSelection()[0] as IScenario);
                    history.push("/admin/scenarios/edit?scenarioid=" + scen.id)
                }
            },
            {
                key: 'deleteItem',
                text: 'Delete',
                disabled: !isItemSelected,
                iconProps: { iconName: 'Delete' },
                onClick: () => {

                }
            },
            {
                key: 'setupItem',
                text: 'Configuration',
                disabled: !isItemSelected,
                iconProps: { iconName: 'Repair' },
                onClick: () => {
                    let scen = (selection.getSelection()[0] as IScenario);
                    history.push("/admin/scenarios/setup?scenarioid=" + scen.id)
                }
            },
            {
                key: 'reports',
                text: 'Reports',
                disabled: !isItemSelected,
                iconProps: { iconName: 'DocumentSet' },
                onClick: () => {
                    let scen = (selection.getSelection()[0] as IScenario);
                    history.push("/admin/scenarios/reports?scenarioid=" + scen.id)
                }
            },
            {
                key: 'assets',
                text: 'Assets',
                disabled: !isItemSelected,
                iconProps: { iconName: 'ZipFolder' },
                onClick: () => {
                    (async () => {
                        const parser = new DOMParser();
                        let scen = (selection.getSelection()[0] as IScenario);
                        let resp = await fetch(`https://waymarkintel.blob.core.windows.net/assets-${scen.id}?restype=container&comp=list`);
                        if (resp.ok) {
                            let xmlText = await resp.text();
                            let xmlDoc = parser.parseFromString(xmlText, "text/xml");
                            let fileElems: ReactElement[] = [];
                            xmlDoc.querySelectorAll("Url").forEach((url: Element) => {
                                let parts = url.innerHTML.split("/");
                                fileElems.push(<a href={url.innerHTML} style={{ display: "block" }}>{String(parts.pop())}</a>);
                            })
                            setFileList(fileElems);
                        }

                        toggleHideDialog();
                    })()
                }
            },
            {
                key: 'schedule',
                text: 'Schedule',
                disabled: !isItemSelected,
                iconProps: { iconName: 'Clock' },
                onClick: () => {
                    let scen = (selection.getSelection()[0] as IScenario);
                    history.push("/admin/scenarios/schedule?scenarioid=" + scen.id)
                }
            }


        ]}
    >
        <ShimmeredDetailsList
            shimmerLines={4}
            enableShimmer={!scenarios}
            items={scenarios || []}
            columns={[
                {
                    name: "Name",
                    fieldName: "name",
                    key: "Name",
                    minWidth: 128,
                    maxWidth: 128,
                    isRowHeader: true,
                    isResizable: true
                }, {
                    name: "Code",
                    fieldName: "code",
                    key: "code",
                    minWidth: 64,
                    maxWidth: 64,
                    isResizable: true
                }
                , {
                    name: "Description",
                    fieldName: "description",
                    key: "Description",
                    minWidth: 256,
                    maxWidth: 256,
                    isResizable: true
                }
            ]}
            setKey="set"
            selectionMode={SelectionMode.single}
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            //onItemInvoked={this._onItemInvoked}
            enterModalSelectionOnTouch={true} />
        <Dialog hidden={hideDialog} dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Import Scenario Assets',
            subText: "Existing assets will be removed"
        }} >
            <form action={`${ApiClient.tempHost}/uploads`} method="post" encType="multipart/form-data" onSubmit={(ev) => {
                (async function () {
                    if (inputFile) {
                        setIsLoading(true);

                        let fd = new FormData();
                        fd.append("scenarioId", String((selection.getSelection()[0] as IScenario).id));
                        fd.append("inputFileCode", 'assets');
                        fd.append("inputFile", inputFile);

                        try {
                            let result = await fetch(ev.currentTarget.action, {
                                method: "post",
                                body: fd,
                                credentials: "include"
                            });
                            let text = await result.text();

                            toggleHideDialog();
                            setMessage([text, result.ok ? MessageBarType.success : MessageBarType.error])
                        }
                        catch (ex) {
                            toggleHideDialog();
                            setMessage([ex.message, MessageBarType.error])
                        }
                        setIsLoading(false);
                    }
                })()
                ev.preventDefault();
            }}>
                {isLoading ? <Spinner label="Uploading Document(s)" /> :
                    <>
                        <Stack>
                            <input type="file" name="inputFile" className={fileUploadStyle} onChange={(ev) => setInputFile(ev.target.files?.[0])} />
                            {fileList && <><h2>Existing Assets</h2>
                                {fileList}</>
                            }
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={!inputFile} type="submit" children="Import" />
                            <DefaultButton onClick={toggleHideDialog} children="Cancel" />
                        </DialogFooter>
                    </>}
            </form>
        </Dialog>

    </SetupPage>

}

export function ScenarioSetupPage() {

    const params = useSearchParams();
    const scenarioId = Number(params.get("scenarioid"));

    return <Switch404>
        <Route exact path="/admin/scenarios/edit">
            <ScenarioSetupPanel scenarioId={scenarioId} />
        </Route>
        <Route exact path="/admin/scenarios/setup">
            <ConfigPanel scenarioId={scenarioId} />
        </Route>
        <Route exact path="/admin/scenarios/reports">
            <ScenarioReportPanel scenarioId={scenarioId} />
        </Route>
        <Route exact path="/admin/scenarios/schedule">
            <ScenarioSchedulePanel scenarioId={scenarioId} />
        </Route>
        <Route exact path="/admin/scenarios">
            <ScenarioList />
        </Route>
    </Switch404>
}

const configurables = {
    roles: {
        title: "Roles",
        headers: ["role", "team", "ob", "participants"]
    },
    actors: {
        title: "Actors",
        headers: ["name", "code", "affiliation", "color", "lineColor", "isPrimaryColor"]
    },
    bases: {
        title: "Bases",
        headers: ["name", "lat", "long", "type", "actorCode"]
    },
    units: {
        title: "Units",
        headers: ["name", "lat", "long", "unitTypeCode", "baseName", "transportName", "unitFlags", "visibility"]
    },
    actions: {
        title: "Actions",
        headers: ["unitType", "actionCode", "actorCode", "extra", "ogSpeed", "ogUnit", "ogMaxRange", "rangeMethod", "speed", "maxRange"]
    }

}

function ConfigPanel(props: { scenarioId?: number }) {
    type DataKey = keyof typeof configurables;
    const [scenarioExt, setScenarioExt] = useState<IScenarioExt>();
    const [message, setMessage] = useMessageState();
    const [curKey, setCurKey] = useState<DataKey>("roles")
    const [isImportMode, setIsImportMode] = useState(false);
    const [importData, setImportData] = useState<any[]>()
    const [curHeaders, setCurHeaders] = useState<string[]>(configurables.roles.headers);
    const [runValue, setRunValue] = useState<number>(0);


    function importFunc() {
        return <Stack tokens={{ childrenGap: "2rem" }} horizontalAlign="center">
            <input type="file" className={fileUploadStyle} accept=".csv" placeholder={`Pick a file to import ${curKey}`} onChange={(ev) => {
                let file = ev.target.files?.[0];
                if (file) {
                    let fr = new FileReader();
                    fr.readAsText(file, "utf-8");
                    fr.onload = async function () {
                        try {
                            let data = await csv({ checkType: true }).fromString(fr.result as string);
                            if (!data.length) {
                                setMessage(["No Data Found. Please make sure the file type is CSV", MessageBarType.error]);
                            }
                            else
                                setMessage([`${data.length} ${curKey} ready to import`, MessageBarType.info]);

                            setImportData(data);
                        }
                        catch (ex) {
                            setMessage([ex.message, MessageBarType.error]);
                        }
                    };
                }
            }}></input>

            <Stack tokens={{ childrenGap: "0.5rem" }} horizontal>
                <PrimaryButton disabled={!importData}
                    onClick={() => {
                        (async function () {
                            if (importData) {
                                let existingImportData = importData;
                                setImportData(undefined);
                                try {
                                    await ScenarioClient.import(Number(scenarioExt?.id), curKey, existingImportData);
                                    setMessage(["Import Complete!"])
                                    setRunValue(runValue + 1);
                                }
                                catch (ex) {
                                    setMessage([ex.message, MessageBarType.error]);
                                }

                                setIsImportMode(false);
                            }
                        })()
                    }}
                >Import</PrimaryButton><DefaultButton onClick={() => setIsImportMode(false)}>Cancel</DefaultButton>
            </Stack>
        </Stack>
    }

    function exportFunc(key: DataKey) {

        if (scenarioExt) {
            let csvBuffer = [];
            csvBuffer.push(curHeaders.join(","));
            let items = scenarioExt[key];
            if (items && curKey === "units") {
                items = (items as any).map((i: any) => {
                    let visibility = i.visibility;
                    if (visibility)
                        visibility = Object.keys(JSON.parse(visibility)).join(",");
                    return {
                        ...i,
                        visibility
                    }
                });
            }
            setMessage([<>Your <Link href={csvURL(items as any, ...curHeaders)} download={key + ".csv"}>{curKey} export</Link> is ready.</>, MessageBarType.success])
        }
    }
    let warnIfNeeded = useCallback(
        (scen: IScenarioExt) => {
            let entity = ''
            if (!scen.actors?.length)
                entity = 'Actors'
            else if (!scen.bases?.length)
                entity = 'Bases'
            else if (!scen.units?.length)
                entity = 'Units'
            if (entity)
                setMessage([`'${entity}' import is required for further configuration. A template can be exported from the appropriate tab.`, MessageBarType.warning])
            else
                setMessage(undefined);
        }, [setMessage])

    function pivClicked(key: DataKey) {
        if (scenarioExt)
            warnIfNeeded(scenarioExt);
        setCurKey(key);
        setCurHeaders(configurables[key].headers);
    }

    let [crumbs, setCrumbs] = useCrumbs();
    function mapColumns(key: DataKey): IColumn[] {
        let configurable = configurables[key];
        return configurable.headers.map((c, i) => ({
            key: c,
            minWidth: 128,
            fieldName: c,
            name: c,
            isRowHeader: i === 0,
            isResizable: true
        }))
    }

    let allStates: Record<DataKey, { itemState: [any[], (items: any[]) => void], columnState: [IColumn[], (columns: IColumn[]) => void] }> =
    {
        actors: { itemState: useState(scenarioExt?.actors || []), columnState: useState(mapColumns("actors")) },
        bases: { itemState: useState(scenarioExt?.bases || []), columnState: useState(mapColumns("bases")) },
        units: { itemState: useState(scenarioExt?.units || []), columnState: useState(mapColumns("units")) },
        roles: { itemState: useState(scenarioExt?.roles || []), columnState: useState(mapColumns("roles")) },
        actions: { itemState: useState(scenarioExt?.actions || []), columnState: useState(mapColumns("actions")) }
    }


    for (const k in allStates) {
        const { itemState, columnState } = allStates[k as DataKey];
        const [items, setItems] = itemState;
        const [columns, setColumns] = columnState;
        setSortable(columns, items, setColumns, setItems);
    }
    const history = useHistory();

    useEffect(() => {
        if (scenarioExt === undefined) {
            (async () => {
                try {

                    let scenario = await ScenarioClient.findById(props.scenarioId as number);
                    setScenarioExt(scenario);
                    let tempCrumbs: IBreadcrumbItem[] = [
                        rootCrumb(history),
                        { key: "2", text: scenario.name },
                        { key: "3", text: "Configuration" }]

                    for (const key in allStates) {
                        let stateInfo = allStates[key as DataKey];
                        stateInfo.itemState[1](scenario[key as DataKey] || []);
                    }

                    setCrumbs(tempCrumbs);
                    warnIfNeeded(scenario);
                }
                catch (ex) {
                    setMessage([ex.message, MessageBarType.error]);
                }
            })()
        }
    }, [props.scenarioId, runValue, warnIfNeeded, setCrumbs, setMessage, allStates, history])

    return <SetupPage title={setupPageTitle} crumbs={crumbs} message={message} commands={[{
        key: 'saveItem',
        text: 'Import',
        iconProps: { iconName: 'Upload' },
        onClick: () => {
            setIsImportMode(true);
        }
    },
    {
        key: 'cancelItem',
        text: 'Export',
        iconProps: { iconName: 'Download' },
        onClick: () => {
            exportFunc(curKey);
        }
    },
    ]} >
        {
            !scenarioExt ?
                <ShimmerForm /> :
                <Pivot onLinkClick={(pi) => {
                    pivClicked(pi?.props.headerText?.toLowerCase() as DataKey)
                }}>
                    {
                        Object.keys(configurables).map(k => {
                            const configurable = configurables[k as DataKey];
                            return <PivotItem headerText={configurable.title}>
                                {isImportMode ? importFunc() : <DetailsList
                                    selectionMode={SelectionMode.none}
                                    className={imortExportList}
                                    items={allStates[k as DataKey].itemState[0]}
                                    columns={allStates[k as DataKey].columnState[0]}
                                />}
                            </PivotItem>
                        })
                    }
                </Pivot>

        }
    </SetupPage>

}
function ScenarioSetupPanel(props: { scenarioId?: number }) {

    const [scenario, setScenario] = useState<IScenario>();

    const [fields, setFields] = useState({
        name: "",
        code: "",
        description: ""
    })
    const [crumbs, setCrumbs] = useCrumbs();
    const [disableSave, setDisableSave] = useState(false);
    const [message, setMessage] = useMessage();
    const history = useHistory();


    useEffect(() => {
        (async () => {
            try {
                let scenario = await ScenarioClient.findById(props.scenarioId);
                setScenario(scenario);

                let tempCrumbs: IBreadcrumbItem[] = [rootCrumb(history)]
                tempCrumbs.push({ key: "2", text: scenario.name || "New" })
                if (scenario.id)
                    tempCrumbs.push({ key: "3", text: "Edit" })

                setCrumbs(tempCrumbs)
                setFields({
                    code: scenario.code,
                    description: scenario.description,
                    name: scenario.name
                })
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error)
            }
        })()
    }, [props.scenarioId, setCrumbs, setMessage, history])

    var fieldHandler: ReactEventHandler = function (ev) {
        let elem = (ev.target as HTMLInputElement);
        (fields as any)[elem.name] = elem.value;
        setFields({
            code: fields.code,
            name: fields.name,
            description: fields.description
        })
    }


    return <SetupPage message={message} title={setupPageTitle} crumbs={crumbs}
        commands={
            editFormCommands(() => ScenarioClient.upsert(fields.name, fields.code, fields.description, scenario?.id),
                history, '/admin/scenarios', disableSave, setDisableSave, setMessage)

        }>
        {!scenario ? <ShimmerForm /> :
            <Stack
                tokens={{ childrenGap: "1rem" }}
                horizontal wrap disableShrink>

                <Stack.Item className={halfField}><TextField name="name" value={fields.name} label="Scenario Name" placeholder="Enter a scenario name" required onChange={fieldHandler} /></Stack.Item>
                <Stack.Item className={halfField}><TextField name="code" value={fields.code} label="Scenario Abbreviation" placeholder="Abbreviation for the scenario" required onChange={fieldHandler} /></Stack.Item>
                <Stack.Item className={fullField}><TextField name="description" value={fields.description} label="Description" placeholder="Description of the scenario" multiline onChange={fieldHandler} /></Stack.Item>
            </Stack>

        }
    </SetupPage>


}

function ScenarioReportPanel(props: { scenarioId: number }) {
    const history = useHistory();

    const [hideDialog, setHideDialog] = useState(true);
    const toggleHideDialog = () => setHideDialog(v => !v);

    const [inputFile, setInputFile] = useState<File | null>();
    const [crumbs, setCrumbs] = useCrumbs();
    const [scenario, setScenario] = useState<IScenario>();
    const [documents, setDocuments] = useState<IDocument[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessageState] = useMessageState();
    const [columns, setColumns] = useState<IColumn[]>([
        {
            key: "name",
            name: "Name",
            fieldName: "name",
            minWidth: 96,
            isRowHeader: true
        },
        {
            key: "createdAt",
            name: "CreatedAt",
            fieldName: "createdAt",
            minWidth: 96
        }
    ]);

    setSortable(columns, documents || [], setColumns, setDocuments);

    useEffect(function () {
        if (!documents) {
            (async function () {
                let result = await ScenarioClient.findById(props.scenarioId);
                setDocuments(await ScenarioClient.documents(props.scenarioId));
                setScenario(result);
                setCrumbs([
                    rootCrumb(history),
                    { key: "Name", text: result.name },
                    { key: "Reports", text: "Reports", isCurrentItem: true }
                ])
            })()
        }
    }, [props.scenarioId, setCrumbs, documents, history])

    return (
        <SetupPage title={setupPageTitle}
            message={message}
            crumbs={crumbs}
            commands={documents ? [
                { key: "Import", text: "Import", onClick: toggleHideDialog, iconProps: { iconName: "Upload" } },
                {
                    key: "Export", text: "Export", iconProps: { iconName: "Download" },
                    onClick: () => {
                        setMessageState(csvMessage("documents", documents, "name", "createdAt"))
                    }
                }
            ] : []}>
            <DetailsList items={documents || []}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                className={imortExportList}
            >
            </DetailsList>
            <Dialog hidden={hideDialog} dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Import Scenario Reports',
                subText: 'Note: Multiple files can be uploaded at once by uploading a zip file. (20mb limit)',
            }} >
                <form action={`${ApiClient.tempHost}/uploads`} method="post" encType="multipart/form-data" onSubmit={(ev) => {
                    (async function () {
                        if (inputFile) {
                            setIsLoading(true);
                            let fd = new FormData();
                            fd.append("scenarioId", String(scenario?.id));
                            fd.append("inputFileCode", 'scenario');
                            fd.append("inputFile", inputFile);
                            try {
                                let result = await fetch(ev.currentTarget.action, {
                                    method: "post",
                                    body: fd,
                                    credentials: "include"
                                });
                                let text = await result.text();
                                toggleHideDialog();
                                setMessageState([text, MessageBarType.success])
                                setDocuments(undefined);
                            }
                            catch (ex) {
                                toggleHideDialog();
                                setMessageState([ex.message, MessageBarType.error])
                            }

                        }
                    })()
                    ev.preventDefault();
                }}>
                    {isLoading ? <Spinner label="Uploading Document(s)" /> :
                        <>
                            <Stack>
                                <input type="file" name="inputFile" className={fileUploadStyle} onChange={(ev) => setInputFile(ev.target.files?.[0])} />
                            </Stack>
                            <DialogFooter>
                                <PrimaryButton disabled={!inputFile} type="submit" children="Import" />
                                <DefaultButton onClick={toggleHideDialog} children="Cancel" />
                            </DialogFooter>
                        </>}
                </form>
            </Dialog>
        </SetupPage>)
}

// offset:Number
// role:string,
// team:string,
// dayPart:string,
// participants: number,
// documentName:string

function ScenarioSchedulePanel(props: { scenarioId: number }) {
    const history = useHistory();

    const [isImportMode, setIsImportMode] = useState(false);
    const [importData, setImportData] = useState<any[] | null>();
    const [crumbs, setCrumbs] = useCrumbs();
    const [scenario, setScenario] = useState<IScenario>();
    const [schedule, setSchedule] = useState<IScenarioSchedule[]>();
    const [message, setMessageState] = useMessageState();

    useEffect(function () {
        if (!schedule) {
            (async function () {

                let result = await ScenarioClient.findById(props.scenarioId);
                setSchedule(await ScenarioClient.schedule(props.scenarioId));
                setScenario(result);
                setCrumbs([
                    rootCrumb(history),
                    { key: "Name", text: result.name },
                    { key: "Schedule", text: "Schedule", isCurrentItem: true }
                ])
            })()
        }
    }, [props.scenarioId, setCrumbs, schedule, history])

    return (
        <SetupPage title={setupPageTitle}
            message={message}
            crumbs={crumbs}
            commands={scenario ? [
                { key: "Import", text: "Import", onClick: () => setIsImportMode(true), iconProps: { iconName: "Upload" } },
                {
                    key: "Export", text: "Export", iconProps: { iconName: "Download" },
                    onClick: () => {
                        let url = csvURL(schedule || [], "documentName", "role", "team", "simDate", "source", "ob", "participants", "dayPart", "offset");
                        setMessageState([<>Your <Link href={url} download="scenarioschedule.csv">schedule export</Link> is ready</>, MessageBarType.success])
                    }
                }
            ] : []}>
            {!isImportMode ? <DetailsList items={schedule || []}
                columns={[
                    {
                        key: "docName",
                        name: "Document Name",
                        fieldName: "documentName",
                        minWidth: 96,
                        isRowHeader: true,
                        isResizable: true

                    },
                    {
                        key: "role",
                        name: "Role",
                        fieldName: "role",
                        minWidth: 96,
                        isResizable: true

                    }, {
                        key: "team",
                        name: "Team",
                        fieldName: "team",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "simDate",
                        name: "SimDate",
                        fieldName: "simDate",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "source",
                        name: "Source",
                        fieldName: "source",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "ob",
                        name: "OB",
                        fieldName: "ob",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "partic",
                        name: "Participants",
                        fieldName: "participants",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "team",
                        name: "T+?",
                        fieldName: "offset",
                        minWidth: 96,
                        isResizable: true

                    },
                    {
                        key: "dayPart",
                        name: "Day Part",
                        fieldName: "dayPart",
                        minWidth: 96,
                        isResizable: true
                    }
                ]}
                selectionMode={SelectionMode.multiple}
            /> :
                <Stack horizontalAlign="center" tokens={{ childrenGap: "1rem" }}>
                    <input type="file" name="inputFile" className={fileUploadStyle} onChange={(ev) => {
                        let file = ev.target.files?.[0];
                        if (file) {
                            let fr = new FileReader();
                            fr.readAsText(file, "utf-8");
                            fr.onload = async function () {
                                try {
                                    let data = await csv({ checkType: true }).fromString(fr.result as string);
                                    if (!data.length) {
                                        setMessageState(["No Data Found. Please make sure the file type is CSV", MessageBarType.error]);
                                    }
                                    else
                                        setMessageState([`${data.length} schedule ready to import`, MessageBarType.info]);

                                    setImportData(data);
                                }
                                catch (ex) {
                                    setMessageState([ex.message, MessageBarType.error]);
                                }
                            };
                        }
                    }} />
                    <Stack horizontal tokens={{ childrenGap: "0.5rem" }}>
                        <PrimaryButton disabled={!importData} children="Import" onClick={() => {
                            (async function () {
                                if (importData) {
                                    let existingImportData = importData;
                                    setImportData(null);
                                    try {
                                        await ScenarioClient.import(Number(scenario?.id), "schedule", existingImportData);
                                        setMessageState(["Import Complete!"])
                                        setSchedule(undefined);
                                    }
                                    catch (ex) {
                                        setMessageState([ex.message, MessageBarType.error]);
                                    }

                                    setIsImportMode(false);
                                }
                            })()
                        }} />
                        <DefaultButton onClick={() => { setIsImportMode(false); setImportData(null); setMessageState(undefined) }} children="Cancel" />
                    </Stack>
                </Stack>
            }

        </SetupPage>)
}

