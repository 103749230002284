
import { IStyleExt } from "../IExt";
import { Card } from "./Card";
import { Component, FormEventHandler } from "react";
import { MessageBarType } from "@fluentui/react";
import React from "react";

export class FormCard extends Component<IStyleExt & {message?: string,  messageType?: MessageBarType, onSubmit?:FormEventHandler<HTMLElement>, formTitle: string},{}>{
        render(){
            return (
                <Card as="form" onSubmit={this.props.onSubmit} cardTitle={this.props.formTitle} styleExt={this.props.styleExt}
                    message={{ type:this.props.messageType, text: this.props.message }} >
                    {this.props.children}
                </Card>
            )
        }
}