import React, { useState, useEffect, useRef } from 'react'
import * as styles from '../styles'
import { useHistory } from 'react-router-dom';
import { ApiClient, IRole, ISessionBasic, ISessionUser, SessionClient } from '../api/client';
import { Session } from 'inspector';
import { useSearchParams } from './util';
import { Switch, Route, Link as RRLink, Redirect, useLocation } from "react-router-dom";
import logo from '../images/logo-wide2.png'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack, Text, Image, MessageBar, MessageBarButton, MessageBarType, Link } from '@fluentui/react';
import { appHeader, appContent, appHeader_link, centerPiece, appScreen } from "../styles";

// import * as styles from './styles'
// import Alert from '@mui/material/Alert';
// import IconButton from '@mui/material/IconButton';
// import Collapse from '@mui/material/Collapse';
// import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';



const useScript = (url: string, name: string) => {

  const [lib, setLib] = useState<any>()
  useEffect(() => {

    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => setLib(window[name as any])
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return lib

}
type Payment = {
  firstName: string,
  lastName: string,
  orderId: string,
  payerId: string,
  amountPaid: string,
}

//disable-funding=venmo,credit
//const merchantId = `FR869X3ZM5G7S`;
//const clientId = 'ATV1mbzCoDWyRv4UnJ2jSQh46PJhyEkdnjFX9-N2dzLlZKXtS8rLsZv1i3AuUf8tdBumlLK14bI1PjG_'
//const defaultClientId = 'AY5Osr_qKjwVGxifJAWdum33WKo7atPzliPeGwAIIyUequKoy27Ooebb-RRefOXwB3-4GSiTchc5HF5q'
const waymarkCliendId = 'AcMFavkl9Yzg44Vac3OHQd-NJ79POlwJZvdAHUtpspcwRQUCSLn1wZQ43HVhHua1B6yr6_w5nC1s3Az8';
export const PaymentPage = () => {

  const paypal = useScript(`https://www.paypal.com/sdk/js?client-id=${waymarkCliendId}&components=buttons&currency=USD`, "paypal")
  const [open, setOpen] = useState(false)
  // const [payments, setPayments] = useState<Payment[]>([{ 
  //     firstName: 'First Name', lastName: 'Last Name', orderId: 'Order Id', payerId: 'Payer Id', amountPaid: 'Amount Paid' }]);
  const [currentSession, setCurrentSession] = useState<ISessionBasic>()
  const [sessionPrice, setSessionPrice] = useState<number>()
  const [amountString, setAmountString] = useState<string>()
  const [button, setButton] = useState<any>()
  const history = useHistory()
  let sessionResponse;
  let sessionId = Number(useSearchParams().get("sessionId"));

  useEffect(() => {
    let doIRender = false;

    (async () => {
      sessionResponse = await SessionClient.getBasic();
      const currentSession = sessionResponse.find(session => session.id === sessionId);
      if (currentSession) {
        console.log('setting price')
        //SET PRICE OF SESSION HERE
        //currentSession.cost = 99
        setSessionPrice(currentSession.cost)
        console.log(sessionPrice)
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        setAmountString(currentSession.cost ? formatter.format(currentSession.cost) : "Unknown Error. Please try logging in again.")
      }
      setCurrentSession(currentSession)
    })()

    if (paypal && currentSession?.cost) {
      console.log('setting the button data ' + currentSession?.cost)
      const paypalButton = paypal.Buttons({
        createOrder: function (data: any, actions: any) {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [{
              'invoice-id': {},
              'custom-id': {},
              amount: {
                value: `${currentSession?.cost}`,
                breakdown: {
                  item_total: {  /* Required when including the items array */
                    currency_code: "USD",
                    value: `${currentSession?.cost}`
                  }
                }
              },
              items: [
                {
                  name: `Waymark Session ${currentSession?.name}`, /* Shows within upper-right dropdown during payment approval */
                  description: "This is a test description for the waymark session purchase", /* Item details will also be in the completed paypal.com transaction view */
                  unit_amount: {
                    currency_code: "USD",
                    value: `${currentSession?.cost}`
                  },
                  quantity: 1
                },
              ]
            },
            ],
          });
        },
        onApprove: function (data: any, actions: any) {
          console.log(data)
          return actions.order.capture().then(function (details: any) {
            let transactionId: string = details.purchase_units[0].payments.captures[0].id;
            handleSucessPayment(transactionId);

            // This function shows a transaction success message to your buyer.
            // console.log('Capture result', details, JSON.stringify(details, null, 2));
            // // setPayments((oldPayments) => {
            // //   return [...oldPayments, { firstName: details.payer.name.given_name, lastName: details.payer.name.surname, orderId: details.id, payerId: details.payer.payer_id, amountPaid: details.purchase_units[0].amount.value }]
            // // })
            // addPayments(newPayments)
          });
        },
        onCancel: function (data: any) {
          alert("Payment cancelled")
        }
      })
      if (paypalButton) {
        paypalButton.render('#paypal-button-container')
      }


    }
  }, [paypal, currentSession?.cost])


  const handleSucessPayment = async (transactionId: string) => {
    await SessionClient.addPayment(transactionId, sessionId, ApiClient.signedInUser.id!)
    window.location.replace('/')
    setOpen(true)
    setTimeout(() => {
      setOpen(false)
    }, 10000)
  }


  return (
    <div>
      <><Stack className={appHeader} horizontal>
        <Stack className="ms-sm3 ms-xxl2" verticalFill >
          <Image src={logo} height="3rem" styles={{ image: { marginTop: "0.25rem" } }} />
        </Stack>
        <Stack horizontal grow gap="1rem" horizontalAlign="end" verticalAlign="center">
          <Text variant="xLarge" >{`${ApiClient.signedInUser.firstName} ${ApiClient.signedInUser.lastName}`}</Text>
          {/* <Text>{`${paymentStatus}`}</Text> */}
          <RRLink to="/signout" className={appHeader_link} style={{ marginRight: "2rem" }}>[Sign Out]</RRLink>
        </Stack>
      </Stack></>
      <div className={styles.exteriorContainer}>

        <div className={styles.paymentContainer}>
          <img style={{ width: '50%' }} src={logo}></img>

          <div className={styles.paymentDropdownContainer}>
            <i style={{ textAlign: 'center' }}>*This session is not yet paid for. Please complete payment to access the course content.</i>
            <p><b>Session: </b>{currentSession?.name}</p>
            <p><b>Amount Due: </b>{amountString}</p>
          </div>

          <div style={{ width: '300px' }} id="paypal-button-container"></div>
          <DefaultButton
            onClick={() => {
              history.push('/?paid=1')
            }}
            text="Back to Dashboard" allowDisabledFocus />
        </div>
      </div>
    </div >

  );
}