import { Card } from "./card/Card";
import React, { useEffect } from "react";
import { ApiClient } from "../api/client";

export let SignOutBox = function (props: { onLoggedOut: () => void }) {
    useEffect(() => {
        (async ()=>{ 
            await ApiClient.logout();
            window.location.href = "/login";
        })()
    }, []);
    return <Card cardTitle="Sign Out"></Card> 
}
