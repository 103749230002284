import React from "react";
import { Stack } from "@fluentui/react";
import { appScreen, centerPiece } from "./styles";
import { LoginBox as LoginForm } from "./components/LoginBox";
import { Route, Switch } from "react-router-dom";
import { HelpBox as HelpForm } from "./components/HelpBox";
import { SignUpPage } from "./SignUpPage";
import { ConfirmBox } from "./components/ConfirmBox";
import { ResetPassBox } from "./components/ResetPassBox";

export function AuthPage(props: { onLoggedIn: () => void }) {
    return (
        <Stack className={appScreen} dir="ltr" horizontalAlign="center" >
            <Switch>
                <Route path="/resetpassword">
                    <ResetPassBox/>
                </Route>
                <Route path="/login/confirm">
                    <ConfirmBox styleExt={centerPiece} />
                </Route>
                <Route path="/login/signup">
                    <SignUpPage />
                </Route>
                <Route path="/login/help">
                    <HelpForm styleExt={centerPiece} />
                </Route>
                <Route path="/">
                    <LoginForm styleExt={centerPiece} onLoggedIn={props.onLoggedIn} />
                </Route>
            </Switch>
            <Stack.Item>Waymark Intelligence Solutions, LLC © 2020</Stack.Item>
        </Stack>

    )
}