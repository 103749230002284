import { FunctionComponent } from "react"
import { Stack, Text, IBreadcrumbItem, Breadcrumb, CommandBar, ICommandBarItemProps, MessageBar, MessageBarType, IRenderFunction } from "@fluentui/react"
import React from "react"
import { MessageState } from "../util"

export const SetupPage: FunctionComponent<{
    title: string,
    crumbs?: IBreadcrumbItem[],
    crumbRender?:IRenderFunction<IBreadcrumbItem>,
    commands?: ICommandBarItemProps[],
    message?:MessageState

}> =
    function (props) {
        return (<Stack tokens={{ childrenGap: "0.5rem" }}>
            <Text variant="xxLarge" styles={{}} as="div" block>{props.title}</Text>
            {props.crumbs && <Breadcrumb items={props.crumbs}  onRenderItem={props.crumbRender} />}
            {props.commands && <CommandBar items={props.commands} />}
            {props.message?.[0] && <MessageBar messageBarType={props.message[1] || MessageBarType.info}>{props.message[0]}</MessageBar>}
            {props.children}
        </Stack>)
    }
