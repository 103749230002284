import { Stack, Dropdown, TextField, Text, IDropdownOption, ITextFieldProps, mergeStyles, DefaultButton, PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { card, halfField, fullField } from "./styles";
import { useMessage, useSearchParams } from "./components/util";
import { ApiClient, UserClient } from "./api/client";
import { useHistory } from "react-router-dom";

type QuestionInfo = [string, string, boolean?]
export const fieldQuestions = {
    email: ["Email", "Enter an email address"],
    signature: ["Full Legal Name", "Enter your full name"],
    verifyEmail: ["Verify Email", "Verify your email"],
    password: ["Password", "Enter a password"],
    verifyPass: ["Verify Password", "Verify your password"],
    firstName: ["First Name", "Enter your first name"],
    lastName: ["Last Name", "Enter your last name"],
    gender: ["Gender", "Pick a gender"],
    //    program: ["Program", "Pick a program"] as QuestionInfo,
    //    semester: ["Semester", "Pick semester"] as QuestionInfo,

    fieldOfWork: ["What field do you currently work in?", "Pick field"] as QuestionInfo,
    pursueDegree: ["If you are a student, what degree are you pursuing?", "Pick degree"] as QuestionInfo,

    degree: ["Highest Degree", "Pick your highest degree"] as QuestionInfo,
    study: ["Field(s) of Study", "Pick your field(s) of study", true] as QuestionInfo,
    almas: ["Alma Mater(s)", "Enter your Alma Mater(s)"] as QuestionInfo,
    //    jobs: ["What jobs are you pursuing?", "Pick jobs", true] as QuestionInfo,
    workExperience: ["Work Experience", "Pick your work experience", true] as QuestionInfo,
    projectManagement: ["Have you had any project management experience?", "Management experience?"] as QuestionInfo,
    technicalExpertise: ["Do you have advanced expertise in any of the following?", "Pick technical expertise", true] as QuestionInfo,
    languages: ["Language(s) with intermediate proficiency or greater?", "Pick language(s)?", true] as QuestionInfo,
    studyAbroad: ["Have you spent time overseas?", "Time overseas?"] as QuestionInfo,
    militaryExperience: ["Do you have military experience?", "Military experience?"] as QuestionInfo,
    securityClearance: ["Have you ever had a security clearance?", "Security clearance?"] as QuestionInfo,
    schoolHours: ["Hours/Week in School", "Pick time spent in school"] as QuestionInfo,
    workHours: ["Hours/Week Employed", "Pick time spent working"] as QuestionInfo,
    busy: ["Estimate how busy you are", "How busy?"] as QuestionInfo,
    leader: ["Are you a leader or follower (be honest)", "Leader or follower?"] as QuestionInfo,
    personality: ["What is your Myer's Briggs personality type?", "Pick a personality type"] as QuestionInfo,
    gamePref: ["Which do you enjoy more?", "Pick a game type"] as QuestionInfo,
    teamPref: ["Which team would you prefer?", "Pick a team"] as QuestionInfo,
    sourcePref: ["Which source would you prefer?", "Pick a source"] as QuestionInfo,
    obPref: ["Which order of battle would you prefer?", "Pick an order of battle"] as QuestionInfo
}

function StackField(props: ITextFieldProps) {
    return <Stack.Item className={mergeStyles(halfField, { width: "50% - 1rem" })}><TextField label={fieldQuestions[props.name as keyof typeof fieldQuestions][0]} placeholder={fieldQuestions[props.name as keyof typeof fieldQuestions][1]} {...props} /></Stack.Item>
}

function StackDown(props: { name: keyof typeof fieldOptions, infoHandlers: InfoAndHanlders }) {

    let multiSelect = Boolean(fieldQuestions[props.name as keyof typeof fieldQuestions][2])

    let selectedKeys: number[] | undefined = undefined;
    let selectedKey: number | undefined;
    let curKey = props.infoHandlers.info[props.name]?.[0];
    if (multiSelect && curKey) {
        selectedKeys = [];
        fieldOptions[props.name].forEach((v, i) => {
            if (((v.key as number) & curKey as number) === v.key)
                (selectedKeys as number[]).push(v.key as number);
        })
    }
    else
        selectedKey = curKey;

    return <Stack.Item className={mergeStyles(halfField, { width: "calc(50% - 1rem)" })}>
        <Dropdown
            options={fieldOptions[props.name]} selectedKey={selectedKey} selectedKeys={selectedKeys} onChange={(ev, dd) => {
                props.infoHandlers.setDropdown(props.name, dd, multiSelect);
            }} label={fieldQuestions[props.name as keyof typeof fieldQuestions][0]} placeholder={fieldQuestions[props.name as keyof typeof fieldQuestions][1]} multiSelect={multiSelect} />
    </Stack.Item>
}
function StackTitle(props: { title: string }) {
    return <Stack.Item className={mergeStyles(fullField, { boxSizing: "border-box", border: "1px solid #ccc", padding: "0.5rem", backgroundColor: "#f2fafe" })}><Text variant="large">{props.title}</Text></Stack.Item>
}

type InfoAndHanlders = {
    setDropdown(name: keyof typeof fieldOptions, dd: IDropdownOption | undefined, isMulti?: boolean): void,
    setField(ev: any, newValue: string | undefined): void,
    info: SignUpInfo
}
type SignUpInfo = {
    email?: string,
    verifyEmail?: string,
    password?: string,
    verifyPass?: string,
    firstName?: string,
    lastName?: string,
    almas?: string,
    signature?: string,

} & {
        [P in keyof typeof fieldOptions]?: [number, number]
    };

export function NDAPanel(props: { infoAndHanlder: InfoAndHanlders }) {
    return <>
        <StackTitle title="Non-Disclosure Agreement" />
        <div>
            <h2>PARTICIPANT ACKNOWLEDGEMENT</h2>
            <p>
                The undersigned, in consideration of being afforded the opportunity to participate in an educational program offered by Waymark Intelligence Solutions, LLC, a Virginia limited liability company (the “Company”), hereby acknowledges and agrees that:
            </p>
            <ol>
                <li>All operating, marketing and teaching methods, writings, course materials, videotapes, and electronic records of the Company, whether or not they bear the Company’s copyright, are valuable and unique assets of the Company and will remain the sole property of the Company.</li>
                <li>The undersigned will not divulge such confidential information or property to third parties or use such confidential information or property in any manner except in connection with an educational program offered by the Company.</li>
                <li>Unless otherwise agreed in writing by the Company, all confidential information and property of the Company shall be returned to the Company promptly following conclusion of the education program offered by the Company in which the undersigned participates.</li>
                <li>The breach or threatened or attempted breach of any provision of this acknowledgement will cause irreparable harm to the Company for which any remedies at law would be inadequate. Accordingly, in the event of any breach or threatened or attempted breach of any such provision by the undersigned, the Company shall, in addition to and not to the exclusion of any other rights and remedies at law or in equity, be entitled to (i) full temporary, interlocutory and permanent injunctive relief enjoining and restraining the undersigned and each and every other person, firm, organization, association, partnership, corporation, institution or entity affiliated or associated with the undersigned from the continuation of such violative acts, and (ii) a decree for specific performance of the provisions hereof. The Company shall not be required to show actual damage or irreparable harm, or to furnish any bond or other security, and the undersigned covenants not to plead in defense thereto that there is or would be an adequate remedy at law.</li>
                <li>This acknowledgement may be executed by means of signature by electronic transmission in portable document format (pdf) or any other electronic signature complying with the U.S. federal ESIGN Act of 2000 (e.g., www.docusign.com). This acknowledgement, to the extent signed and delivered by means of electronic transmission, shall be treated in all manner and respects as an original agreement and shall be considered to have the same binding legal effect as if it were the original signed version thereof delivered in person. No party hereto shall raise the use of electronic transmission in portable document format (pdf) or any other electronic signature complying with the ESIGN Act of 2000 to deliver a signature or the fact that any signature or agreement or instrument was transmitted or communicated by electronic transmission in portable document format (pdf) or any other electronic signature complying with the ESIGN Act of 2000 as a defense to the formation of a contract and each such party forever waives any such defense, except to the extent such defense related to lack of authenticity.</li>
            </ol>
            <p>This acknowledgement is enforceable by and shall inure to the benefit of the Company and its successors and assigns. This acknowledgement shall be interpreted, construed, and governed according to the laws of the Commonwealth of Virginia, without reference to its choice of law provisions.</p>
        </div>
        <div className={halfField} />
        <StackField value={props.infoAndHanlder.info.signature} onChange={props.infoAndHanlder.setField} name="signature" label="Full Name" className={halfField} />
    </>
}


export function GeneralPanel(props: { infoAndHanlder: InfoAndHanlders }) {
    return <>
        <StackTitle title="General" />
        <StackField value={props.infoAndHanlder.info.email as string} onChange={props.infoAndHanlder.setField} name="email" className={halfField} />
        <StackField value={props.infoAndHanlder.info.verifyEmail} onChange={props.infoAndHanlder.setField} name="verifyEmail" className={halfField} />
        <StackField value={props.infoAndHanlder.info.password} onChange={props.infoAndHanlder.setField} name="password" type="password" className={halfField} />
        <StackField value={props.infoAndHanlder.info.verifyPass} onChange={props.infoAndHanlder.setField} name="verifyPass" type="password" className={halfField} />
        <StackField value={props.infoAndHanlder.info.firstName} onChange={props.infoAndHanlder.setField} name="firstName" className={halfField} />
        <StackField value={props.infoAndHanlder.info.lastName} onChange={props.infoAndHanlder.setField} name="lastName" className={halfField} />
        <StackDown infoHandlers={props.infoAndHanlder} name="gender" />
    </>
}
function ExperiencePanel(props: { infoAndHanlder: InfoAndHanlders }) {
    return <>
        <StackTitle title="Experience" />
        {/* <StackDown infoHandlers={props.infoAndHanlder} name="program" /> */}
        {/* <StackDown infoHandlers={props.infoAndHanlder} name="semester" /> */}
        <StackDown infoHandlers={props.infoAndHanlder} name="fieldOfWork" />
        <StackDown infoHandlers={props.infoAndHanlder} name="pursueDegree" />
        <StackDown infoHandlers={props.infoAndHanlder} name="degree" />
        <StackDown infoHandlers={props.infoAndHanlder} name="study" />
        <StackField value={props.infoAndHanlder.info.almas} name="almas" label="Alma Mater(s)" onChange={props.infoAndHanlder.setField} placeholder="Enter your Alma Mater(s)" />
        {/* <StackDown infoHandlers={props.infoAndHanlder} name="jobs" /> */}
        <StackDown infoHandlers={props.infoAndHanlder} name="workExperience" />
        <StackDown infoHandlers={props.infoAndHanlder} name="projectManagement" />
        <StackDown infoHandlers={props.infoAndHanlder} name="technicalExpertise" />
        <StackDown infoHandlers={props.infoAndHanlder} name="languages" />
        <StackDown infoHandlers={props.infoAndHanlder} name="studyAbroad" />
        <StackDown infoHandlers={props.infoAndHanlder} name="militaryExperience" />
        <StackDown infoHandlers={props.infoAndHanlder} name="securityClearance" />
    </>
}

function AvailabilityPanel(props: { infoAndHanlder: InfoAndHanlders }) {
    return <>
        <StackTitle title="Availability" />
        <StackDown infoHandlers={props.infoAndHanlder} name="schoolHours" />
        <StackDown infoHandlers={props.infoAndHanlder} name="workHours" />
        <StackDown infoHandlers={props.infoAndHanlder} name="busy" />
    </>
}
function PrefPanel(props: { infoAndHanlder: InfoAndHanlders }) {
    return <>
        <StackTitle title="Personality" />
        <StackDown infoHandlers={props.infoAndHanlder} name="leader" />
        <StackDown infoHandlers={props.infoAndHanlder} name="personality" />
        <StackDown infoHandlers={props.infoAndHanlder} name="gamePref" />
        <div>If you don’t know your personality type, you can take a free test here: <a target="__blank" href="http://www.16personalities.com">www.16personalities.com</a>.  Although certainly not infallible, this test gives us a general sense of your preferences in receiving and processing information, which allows us to create a more beneficial experience for everyone. It is used for role placement only and will not negatively affect your application or experience.</div>
        <StackTitle title="Preferences" />
        <StackDown infoHandlers={props.infoAndHanlder} name="teamPref" />
        <StackDown infoHandlers={props.infoAndHanlder} name="sourcePref" />
        {/* <StackDown infoHandlers={props.infoAndHanlder} name="obPref" /> */}
    </>
}

function WaitOnConfirm() {
    return <div>Thanks for registering with Waymark Intelligence Solutions, LLC. We will send a note to the email address you registered with us to verify your account, which will then enable you to login with your username (your email) and the password you selected.</div>
}

let ensureAllText = "Please make sure all fields are filled in before continuing.";
let panelButtons = ["General", "Terms of Use", "Agree & Continue", "Availability", "Personality & Prefs"]
let verify =
    [
        (signUpInfo: SignUpInfo) => {
            let errorMessages: string[] = [];
            //check general values 
            if ((signUpInfo.password || "").length < 8)
                errorMessages.push("Password must be at least 8 characters");
            if (signUpInfo.password !== signUpInfo.verifyPass)
                errorMessages.push("Passwords do not match");
            if (signUpInfo.email !== signUpInfo.verifyEmail)
                errorMessages.push("Confirmation email does not match");
            if (!signUpInfo.firstName || !signUpInfo.lastName || !signUpInfo.gender)
                errorMessages.push(ensureAllText);
            return errorMessages;
        },
        (signUpInfo: SignUpInfo) => {

            let errorMessages: string[] = [];
            //check general values 
            if ((signUpInfo.signature || "").length < 2)
                errorMessages.push("Please enter a valid full name");
            return errorMessages;

        },
        (signUpInfo: SignUpInfo) => {
            let errorMessages: string[] = [];


            if (signUpInfo.workExperience?.[0] !== 512 && ((signUpInfo.workExperience?.[0] || 0) & 512) === 512) {
                errorMessages.push("Work Experience: 'None' cannot be selected with other options.")
            }
            if (
                // !signUpInfo.program ||
                //     !signUpInfo.semester ||
                !signUpInfo.fieldOfWork ||
                !signUpInfo.pursueDegree ||
                !signUpInfo.degree ||
                !signUpInfo.study ||
                !signUpInfo.almas ||
                // !signUpInfo.jobs ||
                !signUpInfo.workExperience ||
                !signUpInfo.projectManagement ||
                !signUpInfo.technicalExpertise ||
                !signUpInfo.languages ||
                !signUpInfo.studyAbroad ||
                !signUpInfo.militaryExperience ||
                !signUpInfo.securityClearance) {
                errorMessages.push(ensureAllText)
            }

            return errorMessages;
        },
        (signUpInfo: SignUpInfo) => {
            let errorMessages: string[] = [];
            if (!signUpInfo.schoolHours || !signUpInfo.workHours || !signUpInfo.busy)
                errorMessages.push(ensureAllText);
            return errorMessages;
        },
        (signUpInfo: SignUpInfo) => {
            let errorMessages: string[] = [];
            if (!signUpInfo.leader || !signUpInfo.personality ||
                //!signUpInfo.obPref 
                //||
                !signUpInfo.gamePref || !signUpInfo.teamPref || !signUpInfo.sourcePref) {
                errorMessages.push(ensureAllText);
            }
            return errorMessages;
        }]

export function SignUpPage() {
    const hist = useHistory();
    const [index, setIndex] = useState(ApiClient.signedInUser.id ? 1 : 0);

    const [info, setSignupInfo] = useState<SignUpInfo>({});
    const [message, setMessage] = useMessage();
    const accesscode = useSearchParams().get("accesscode") as string;
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {
        (async () => {
            try {
                const u = await UserClient.checkAccess(accesscode);
                if (!u)
                    window.location.href = "/"
            }
            catch (ex) {
                window.location.href = "/"
            }
        })()
    }, [accesscode])

    const panelInfo = {
        info,
        setField(ev: any, newValue: string) {
            (info as any)[ev.target.name] = newValue;
            setSignupInfo({ ...info })
        },
        setDropdown(name: keyof typeof fieldOptions, dd: IDropdownOption | undefined, isMulti?: boolean) {
            if (dd) {
                let score = (dd as any).score;
                let output: [number, number];
                if (!isMulti) {
                    output = [dd.key as number, score];
                }
                else {
                    let existingValues = info[name] || [0, 0];
                    if (dd.selected)
                        output = [existingValues[0] | (dd.key as number), existingValues[1] + score]
                    else
                        output = [existingValues[0] & ~(dd.key as number), existingValues[1] - score]
                }

                info[name] = output;
                setSignupInfo({ ...info });
            }
        }
    }
    const verification = verify[index];

    return <Stack horizontal maxWidth="70%" wrap className={mergeStyles(card, { marginTop: "2rem", overflow: "auto", marginBottom: "2rem" })} tokens={{ childrenGap: "1rem", maxHeight: "calc(100vh - 2rem)" }}>
        {index === 5 ? <Stack.Item className={fullField} ><Text variant="xLargePlus">Registration Complete!</Text></Stack.Item>
            : <Stack.Item className={fullField} ><Text variant="xLargePlus">Sign Up</Text><Text variant="large" style={{ float: "right" }}>{index + 1}/5</Text></Stack.Item>}

        {message && <MessageBar messageBarType={message[1]}>{message[0]}</MessageBar>}
        {[<GeneralPanel infoAndHanlder={panelInfo} />,
        <NDAPanel infoAndHanlder={panelInfo} />,
        <ExperiencePanel infoAndHanlder={panelInfo} />,
        <AvailabilityPanel infoAndHanlder={panelInfo} />,
        <PrefPanel infoAndHanlder={panelInfo} />,
        <WaitOnConfirm />][index]}

        <Stack horizontal className={fullField} horizontalAlign="stretch" tokens={{ childrenGap: "1rem" }}>
            {index > 0 && (ApiClient.signedInUser.id && index === 1 ? panelButtons.length : index) < panelButtons.length && <Stack.Item grow className={mergeStyles({ flexBasis: "20rem" })}  ><DefaultButton
                iconProps={{ iconName: "DoubleChevronLeftMed" }}
                className={mergeStyles({ boxSizing: "border-box", width: "100%" })}
                onClick={() => {
                    setIndex(index - 1);
                }}
            >{panelButtons[index - 1]}</DefaultButton></Stack.Item>}
            {index < panelButtons.length - 1 &&
                <Stack.Item className={mergeStyles({ flexBasis: "20rem" })} align="end" grow><PrimaryButton className={mergeStyles({ boxSizing: "border-box", width: "100%" })} dir="rtl" iconProps={{ iconName: "DoubleChevronLeftMedMirrored" }}
                    onClick={() => {
                        let errors = verification(info);
                        if (!errors.length) {
                            setIndex(index + 1);
                            setMessage(undefined);
                        }
                        else
                            setMessage(<>{errors.flatMap((e) => [e, <br />])}</>, MessageBarType.error);
                    }}
                >{panelButtons[index + 1]}</PrimaryButton></Stack.Item>}
            {
                index === 5 && <Stack.Item className={mergeStyles({ flexBasis: "20rem" })} align="end" grow><PrimaryButton className={mergeStyles({ boxSizing: "border-box", width: "100%" })} dir="rtl"
                    onClick={() => hist.push("/login")}
                >Go To Login</PrimaryButton></Stack.Item>}
            {index === panelButtons.length - 1 &&
                <Stack.Item className={mergeStyles({ flexBasis: "20rem" })} align="end" grow>
                    <PrimaryButton
                        disabled={submitting}
                        onClick={() => {
                            if (!submitting) {
                                setSubmitting(true);
                                (async () => {
                                    try {
                                        let score = 0;
                                        Object.keys(fieldOptions).forEach((k) => {
                                            score += info[k as keyof typeof fieldOptions]?.[1] || 0
                                        });

                                        //This is where you would change this if this is not a new user
                                        await UserClient.createAccount(
                                            info.email as string,
                                            info.password as string,
                                            info.firstName as string,
                                            info.lastName as string,
                                            accesscode,
                                            score,
                                            info.signature as string,
                                            {
                                                all: calcAllSource(info),
                                                humint: calcHUMINT(info),
                                                imint_am: calcIMINT_AM(info),
                                                imint_gn: calcIMINT_GN(info),
                                                sigint: calcSIGINT(info)
                                            },
                                            info)

                                        setIndex(5);

                                    }
                                    catch (ex) {
                                        setSubmitting(false);
                                        setMessage((ex as any).message, MessageBarType.error);
                                    }
                                })()
                            }
                        }}
                        className={mergeStyles({ boxSizing: "border-box", width: "100%" })}>Submit</PrimaryButton>
                </Stack.Item>}
        </Stack>

    </Stack>

}
type MultiSelectValueArray = ([string, number])[];

const personalityArray: MultiSelectValueArray = [["INTJ (Architect)", 0],
["INTP (Logician)", 0],
["ENTJ (Commander)", 0],
["ENTP (Debater)", 0],
["INFJ (Advocate)", 0],
["INFP (Mediator)", 0],
["ENFJ (Protagonist)", 0],
["ENFP (Campaigner)", 0],
["ISTJ (Logistician)", 0],
["ISFJ (Defender)", 0],
["ESTJ (Executive)", 0],
["ESFJ (Consul)", 0],
["ISTP (Virtuoso)", 0],
["ISFP (Adventurer)", 0],
["ESTP (Entrepreneur)", 0],
["ESFP (Entertainer)", 0]]

export const fieldOptions = {
    // jobs: makeOptions(
    //     ["Intelligence", 1],
    //     ["Policy", 2],
    //     ["Diplomacy", 4],
    //     ["Non-Profit", 8],
    //     ["Other", 16]),
    // program: makeOptions(
    //     ["PhD", 1],
    //     ["MA", 1],
    //     ["Certificate", 0],
    //     ["Intern", -1]),
    // semester: makeOptions(
    //     ["First", 0],
    //     ["Second", 1],
    //     ["Third", 2],
    //     ["Fourth", 2],
    //     ["Fifth", 2]),

    fieldOfWork: makeOptions(
        ['Intelligence', 0],
        ['Policy', 0],
        ['Diplomacy', 0],
        ['Military', 0],
        ['Non-Profit', 0],
        ['Student', 0],
        ['Other', 0]
    ),
    pursueDegree: makeOptions(
        ['Doctoral', 0],
        ['Master\'s', 0],
        ["Bachelor's", 0],
        ['Associate\'s', 0],
        ['Certificate', 0],
        ['Not currently a student', 0]

    ),
    degree: makeOptions(
        ["Doctorate", 3],
        ["Master's", 2],
        ["Bachelor's", 0],
        ["Associate's", 0],
        ["HS Diploma", -1]),

    study: makeOptions(
        ["Business/Economics", 1],
        ["Communications/Journalism", 1],
        ["Computer Science", 1],
        ["Criminology/Law Enforcement/Homeland Security", 2],
        ["Humanities", 0],
        ["International Relations/History/Political Science", 2],
        ["National Security/Intelligence", 3],
        ["Other", 0]),
    workExperience: makeOptions(
        ["Clerical", 0.5],
        ["Data Analytics", 4],
        ["Humanitarian/Non-profit", 1],
        ["Information Technology", 1],
        ["Journalism/Professional research/writing", 2],
        ["National Security/Intelligence", 4],
        ["Other", 0],
        ["Policy support/Decision planning", 2],
        ["Visual Arts", 1],
        ["None", 0]
    ),
    projectManagement: makeOptions(
        ["Yes, professionally", 2],
        ["Yes, in school", 1],
        ["No", 0]),
    technicalExpertise: makeOptions(
        ['None of these', 0],
        ["Analyst's Notebook", 0.5],
        ["Arc GIS", 0.5],
        ["Google Earth", 0.5],
        ["Microsoft Excel", 0.5],
        ["Tableau", 0.5],
        ["Writing code", 1]),
    languages: makeOptions(
        ["None", 0],
        ["African languages (e.g. Swahili, Afrikaans, Berber)", 1],
        ["Eastern European languages (e.g. Russian, Serbian, Bulgarian)", 1],
        ["Far East languages (e.g. Mandarin, Japanese, Korean)", 1],
        ["Middle Eastern/Indian languages (e.g. Arabic, Urdu, Hindi, Pashto)", 1],
        ["Western European languages (e.g. German, French, Spanish)", 0.5]),
    studyAbroad: makeOptions(
        ["Yes, work abroad", 0.5],
        ["Yes, study abroad", 0.5],
        ["Yes, international travel", 0],
        ["No", 0]),
    securityClearance: makeOptions(
        ["Yes", 2],
        ["No", 0]),
    militaryExperience: makeOptions(
        ["Yes", 1],
        ["No", 0]),
    schoolHours: makeOptions(
        ["12 or More", -3],
        ["Less than 12", 0]),
    workHours: makeOptions(
        ["More than 40", -3],
        ["40", -2],
        ["20-39", 0],
        ["0-19", 1],
        ["0", 3]),
    busy: makeOptions(
        ["Low", 0],
        ["Medium", 0],
        ["High", 0],
        ["Apocalyptic", 0]
    ),
    attendance: makeOptions(
        ["The Seminar", -1],
        ["The Simulation", -4],
        ["The War Game", -3],
        ["I can attend all of it", 0]),
    gender: makeOptions(
        ["Male", 0],
        ["Female", 0],
        ["No Response", 0]
    ),
    leader: makeOptions(
        ["Leader", 0],
        ["Follower", 0],
        ["I am a leader, but can follow easily too", 0]),
    personality: makeOptions(
        ...personalityArray),
    gamePref: makeOptions(
        ["Strategy Games (e.g. Risk, Diplomacy, Axis and Allies)", 0],
        ["Deductive games (e.g. Sudoku, Crosswords, Logic Puzzles)", 0],
        ["I don't like games", 0]),
    teamPref: makeOptions(
        ["NATO East (Continental NATO)", 0],
        ["NATO West (UK & Canada)", 0],
        ["Russia", 0],
        // ["United States", 0],
        // ["China",0],
        // ["South Korea",0],
        ["No Preference", 0]),
    sourcePref: makeOptions(
        //    ["All Source", 0], ["HUMINT", 0], ["IMINT", 0], ["SIGINT", 0], ["No Preference", 0]
        ["Diplomatic", 0], ["Information", 0], ["Military", 0], ["Economic", 0], ["No Preference", 0]
    ),
    obPref: makeOptions(
        ["Air", 0],  //0
        ["Ground", 0],  //1
        ["Missiles", 0],  //2
        ["Navy", 0], //3
        ["No Preference", 0] //4
    )
}

function makeOptions(...value: MultiSelectValueArray): IDropdownOption[] {
    return value.map((v, i) => {
        return {
            key: Math.pow(2, i),
            text: v[0],
            score: v[1]
        }
    });
}



function calcShared(info: SignUpInfo) {
    const hasDegree = (info.degree?.[0] || 3) <= 2;
    const hasJob = ((info.workExperience?.[0] || 0) !== 512);
    const personVal = info.personality ? Math.log2(info.personality[0]) : -1;
    return {
        hasDegree,
        hasJob,
        hasProjectManagement: (info.projectManagement && info.projectManagement[0] <= 1) as boolean,
        personality: {
            E: (personalityArray[personVal][0].substr(0, 4).indexOf("E") > -1) as boolean,
            I: (personalityArray[personVal][0].substr(0, 4).indexOf("I") > -1) as boolean,
            P: (personalityArray[personVal][0].substr(0, 4).indexOf("P") > -1) as boolean,
            N: (personalityArray[personVal][0].substr(0, 4).indexOf("N") > -1) as boolean,
            S: (personalityArray[personVal][0].substr(0, 4).indexOf("S") > -1) as boolean,
            J: (personalityArray[personVal][0].substr(0, 4).indexOf("J") > -1) as boolean,
        },
        source: {
            //["All Source", 0], ["HUMINT", 0], ["IMINT", 0], ["SIGINT", 0], ["No Preference", 0]),
            allSource: info.sourcePref?.[0] === 1,
            humint: info.sourcePref?.[0] === 2,
            imint: info.sourcePref?.[0] === 4,
            sigint: info.sourcePref?.[0] === 8,
            noPref: info.sourcePref?.[0] === 16
        },
        ob: {
            air: info.obPref?.[0] === 1,
            ground: info.obPref?.[0] === 2,
            missiles: info.obPref?.[0] === 4,
            navy: info.sourcePref?.[0] === 8
        },
        gamePref: {
            logic: info.gamePref?.[0] === 2,
            strat: info.gamePref?.[0] === 1
        }
    }
}

function calcScore(...test: boolean[]) {
    let score = 0;
    test.forEach((t) => {
        score += (t ? 1 : 0)
    });
    return score;
}

function calcAllSource(info: SignUpInfo) {
    const shared = calcShared(info);
    return calcScore(
        (info.leader && info.leader[0] === 0) as boolean,
        shared.gamePref.strat,
        shared.hasDegree,
        shared.hasJob,
        shared.personality.E,
        shared.personality.N,
        shared.personality.J,

        shared.source.allSource,
        shared.source.noPref,

        shared.hasProjectManagement,
        shared.hasJob && shared.hasDegree
    )
}

function calcIMINT_GN(info: SignUpInfo) {
    const shared = calcShared(info);
    return calcScore(
        shared.gamePref.logic,
        shared.hasDegree,
        shared.hasJob,
        shared.personality.E,
        shared.personality.S,
        shared.personality.J,

        (shared.source.imint && (shared.ob.ground || shared.ob.navy)),
        shared.source.noPref,

        shared.hasProjectManagement
    )
}

function calcHUMINT(info: SignUpInfo) {
    const shared = calcShared(info);
    return calcScore(
        shared.gamePref.strat,
        !shared.hasDegree,
        !shared.hasJob,
        shared.personality.I,
        shared.personality.N,
        shared.personality.P,

        shared.source.humint,
        shared.source.noPref,

        !shared.hasProjectManagement
    )
}

function calcIMINT_AM(info: SignUpInfo) {
    const shared = calcShared(info);
    return calcScore(
        shared.gamePref.logic,
        !shared.hasDegree,
        !shared.hasJob,
        shared.personality.I,
        shared.personality.S,
        shared.personality.J,

        (shared.source.imint && (shared.ob.air || shared.ob.missiles)),
        shared.source.noPref,

        !shared.hasProjectManagement
    )
}

function calcSIGINT(info: SignUpInfo) {
    const shared = calcShared(info);
    return calcScore(
        shared.gamePref.logic,
        !shared.hasDegree,
        !shared.hasJob,
        shared.personality.E,
        shared.personality.S,
        shared.personality.P,

        shared.source.sigint,
        shared.source.noPref,

        !shared.hasProjectManagement
    )
}