import React, { useState, useEffect, ReactEventHandler, useMemo } from "react";
import { IClient, PortalClient } from "../../api/client";
import { Link, Selection, Stack, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, IBreadcrumbItem, TextField, MessageBarType, ITextFieldProps, MaskedTextField, IColumn, Dropdown, IDropdownProps, PrimaryButton, DefaultButton, Dialog, DialogFooter, DetailsList } from "@fluentui/react";
import { useHistory, Route, } from "react-router-dom";
import { useMessageState, csvURL, useSearchParams, useCrumbs, Switch404, setSortable, useMessage } from "../util";
import { ShimmerForm } from "../shimmer/ShimmerForm";
import { SetupPage } from "./SetupPage";
import { halfField } from "../../styles";

type T = IClient
const setupPageTitle = "Setup: Clients"
const rootCrumb: (hist: any) => IBreadcrumbItem = (hist) => ({ key: "root", text: "Clients", onClick: () => hist.push("/admin/clients") });
const formFields = (fieldHandler: any, setForm: any) => [{ name: "name", props: { label: "Client Name", placeholder: "Enter a client name", onChange: fieldHandler, required: true } },
{ name: "code", props: { label: "Client Abbreviation", placeholder: "Enter a client abbreviation", onChange: fieldHandler, required: true } },
{ name: "poc", props: { label: "Point of Contact", placeholder: "Enter a contact name", onChange: fieldHandler } },
{ name: "pocTitle", props: { label: "Point of Contact Title", placeholder: "Enter a title", onChange: fieldHandler } },
{ name: "email", props: { label: "Email", placeholder: "Enter an email", onChange: fieldHandler } },
{ name: "phone", props: { label: "Phone", placeholder: "Enter a phone number", onChange: fieldHandler, mask: "999-999-9999" } },
{ name: "city", props: { label: "City", placeholder: "Enter a city", onChange: fieldHandler } },
{ name: "state", props: { label: "State", placeholder: "Enter a state", onChange: fieldHandler } },
{ name: "billingAddress", props: { label: "Billing Address", placeholder: "Enter a billing address", onChange: fieldHandler } },
{ name: "status", ddp: { label: "Status", options: ["Active", "Inactive", "Prospective", "Deferred"].map(key => ({ key, text: key })), placeholder: "Pick a status", onChange: (ev: any, dd: any) => setForm("status", dd?.key as string) } },
{ name: "notes", props: { label: "Notes", placeholder: "Add notes", onChange: fieldHandler, multiline: true } },]

export function ClientSetupPage(props: {}) {
    return (<Switch404>
        <Route path="/admin/clients/edit">
            <ClientSetupPanel />
        </Route>
        <Route path="/admin/clients/view">
            <ClientViewPanel />
        </Route>
        <Route exact path="/admin/clients">
            <ClientList />
        </Route>
    </Switch404>)
}

function ClientViewPanel() {
    type ProfileField = { field: string, value: string }
    const history = useHistory();
    const clientId = Number(useSearchParams().get("clientid"));
    //const [client, setClient] = useState<IClient>();
    const [crumbs, setCrumbs] = useCrumbs();
    const [message, setMessage] = useMessage();
    const [profileData, setProfileData] = useState<ProfileField[]>([])
    const [columns, setColumns] = useState<IColumn[]>([
        { key: "field", fieldName: "field", name: "Field", minWidth: 64, isRowHeader: true },
        { key: "value", fieldName: "value", name: "Value", minWidth: 512 }
    ]);

    useEffect(() => {
        (async () => {
            try {
                let client = await PortalClient.findById(clientId);
                setCrumbs([
                    rootCrumb(history),
                    {
                        key: "client",
                        text: client.name || "",
                        isCurrentItem: true
                    }
                ]);
                //setClient(client);
                let newProfileData: ProfileField[] = [];
                formFields(null, null).forEach(f => newProfileData.push({ field: f.props?.label || f.ddp?.label || "", value: client[f.name as keyof IClient] as string }))
                setProfileData(newProfileData);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [clientId, history, setCrumbs, setMessage]);
    setSortable(columns, profileData, setColumns, setProfileData);
    return <SetupPage title={setupPageTitle} message={message} crumbs={crumbs}>
        <DetailsList items={profileData} columns={columns} />
    </SetupPage>
}


//let deleteButtonClass = mergeStyles({ alignItems: "unset" });

//const theme = getTheme();
// const styles = mergeStyleSets({
//     buttonArea: {
//         verticalAlign: 'top',
//         display: 'inline-block',
//         textAlign: 'center',
//         margin: '0 100px',
//         minWidth: 130,
//         height: 32,
//     },
//     callout: {
//         maxWidth: 300,
//     },
//     header: {
//         padding: '18px 24px 12px',
//     },
//     title: [
//         theme.fonts.xLarge,
//         {
//             margin: 0,
//             fontWeight: FontWeights.semilight,
//         },
//     ],
//     inner: {
//         height: '100%',
//         padding: '0 24px 20px',
//     },
//     actions: {
//         position: 'relative',
//         marginTop: 20,
//         width: '100%',
//         whiteSpace: 'nowrap',
//     },
//     subtext: [
//         theme.fonts.small,
//         {
//             margin: 0,
//             fontWeight: FontWeights.semilight,
//         },
//     ],
//     link: [
//         theme.fonts.medium,
//         {
//             color: theme.palette.neutralPrimary,
//         },
//     ],
// });
function ClientList() {
    const history = useHistory();
    const [selectedItem, setSelectedItem] = useState<IClient>();

    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setSelectedItem(selection.count ? selection.getSelection()[0] as IClient : undefined)
        }
    }), [setSelectedItem])


    const [isDiagHidden, setDHid] = useState(true);
    const toggleIsDiagHidden = () => setDHid((v) => !v);

    const [message, setMessage] = useMessageState();
    const [clients, setClients] = useState<IClient[]>([])
    const [columns, setColumns] = useState<IColumn[]>([{
        name: "Name",
        fieldName: "name",
        key: "Name",
        minWidth: 128,
        maxWidth: 128,
        isRowHeader: true,
        isResizable: true,

    }, {
        name: "Code",
        fieldName: "code",
        key: "code",
        minWidth: 64,
        maxWidth: 64,
        isResizable: true,

    },
    {
        name: "Point Of Contact",
        fieldName: "poc",
        key: "poc",
        minWidth: 128,
        maxWidth: 128,
        isResizable: true,

    },
    {
        name: "Title",
        fieldName: "pocTitle",
        key: "pocTitle",
        minWidth: 96,
        maxWidth: 96,
        isResizable: true,

    },
    {
        name: "Phone",
        fieldName: "phone",
        key: "phone",
        minWidth: 128,
        maxWidth: 128,
        isResizable: true,

    },
    {
        name: "Email",
        fieldName: "email",
        key: "email",
        minWidth: 128,
        maxWidth: 128,
        onRender: (client: T) => {
            return <Link href={"mailto:" + client.email}>{client.email}</Link>
        },

        isResizable: true
    },
    {
        name: "City",
        fieldName: "city",
        key: "city",
        minWidth: 96,
        maxWidth: 96,
        isResizable: true,

    },
    {
        name: "State",
        fieldName: "state",
        key: "state",
        minWidth: 32,
        maxWidth: 32,
        isResizable: true,

    },
    {
        name: "Status",
        fieldName: "status",
        key: "status",
        minWidth: 96,
        maxWidth: 96,
        isResizable: true,

    },
    {
        name: "Session Count",
        fieldName: "sessionCount",
        key: "sessionCount",
        minWidth: 32,
        maxWidth: 32,
        isResizable: true,

    }
    ])
    const [runValue, setRunValue] = useState(0);

    useEffect(() => {
        (async function () {
            try {
                setClients(await PortalClient.clients());
            }
            catch (ex) {
                setMessage([ex.message, MessageBarType.error])
            }
        })()
    }, [setMessage, setClients, runValue]);

    setSortable(columns, clients, setColumns, setClients);

    return <SetupPage title={setupPageTitle}
        message={message}
        commands={[
            {
                key: 'newItem',
                text: 'New',
                iconProps: { iconName: 'Add' },
                onClick: () =>
                    history.push('/admin/clients/edit')
            },
            {
                key: 'editItem',
                text: 'Edit',
                disabled: !selectedItem,
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    history.push("/admin/clients/edit?clientid=" + selectedItem?.id)
                },
            },
            {
                key: 'profile',
                text: 'Profile',
                disabled: !selectedItem,
                iconProps: { iconName: 'List' },
                onClick: () => {
                    history.push("/admin/clients/view?clientid=" + selectedItem?.id)
                },
            },
            {
                key: 'deleteItem',
                text: 'Delete',
                //className:deleteButtonClass,
                disabled: !selectedItem,
                iconProps: { iconName: 'Delete' },
                onClick: () =>
                    toggleIsDiagHidden()
            },
            {
                key: "Export",
                text: "Export",
                iconProps: { iconName: "Download" },
                onClick: () => {
                    if (clients) {
                        setMessage([
                            <>Your download<Link href={csvURL(clients, ...(Object.keys(clients[0])) as (keyof T)[])} download="clients.csv">client export</Link> is ready.</>,
                            MessageBarType.success])
                    }
                }
            }

        ]} >
        <ShimmeredDetailsList
            items={clients}
            columns={columns}
            setKey="set"
            selectionMode={SelectionMode.single}
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            //onItemInvoked={this._onItemInvoked}
            enterModalSelectionOnTouch={true} />
        <Dialog
            hidden={isDiagHidden}
            onDismiss={toggleIsDiagHidden}
            title="Delete Client"
            subText={`Are you sure you want to delete '${selectedItem?.name}'?`}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={() => {
                        (async () => {
                            await PortalClient.deleteClient(selectedItem?.id as number)
                            toggleIsDiagHidden();
                            setRunValue(runValue + 1);
                        })()
                    }}>Delete</PrimaryButton>
                <DefaultButton onClick={() => {
                    toggleIsDiagHidden();
                }} >Keep</DefaultButton>
            </DialogFooter>
        </Dialog>
    </SetupPage>
}


function ClientSetupPanel() {

    const clientId = Number(useSearchParams().get("clientid"));

    const history = useHistory();

    const [fields, setFields] = useState<IClient>();
    const [crumbs, setCrumbs] = useCrumbs();
    const [message, setMessage] = useMessageState();

    useEffect(
        () => {
            (async () => {
                let client: IClient;
                if (clientId)
                    client = await PortalClient.findById(clientId)
                else
                    client = {};

                let crumbs: IBreadcrumbItem[] = [rootCrumb(history)]
                if (client.id)
                    crumbs.push({ text: client.name as string, key: "2" }, { text: "Edit", key: "3", isCurrentItem: true })
                else
                    crumbs.push({ text: "New", key: "2", isCurrentItem: true });

                setCrumbs(crumbs)
                setFields({ ...client })
            })()
        }, [clientId, setCrumbs, history]);

    const [disableSave, setDisableSave] = useState(false);


    function setForm(name: string, value: string) {
        (fields as any)[name] = value;
        setFields({ ...fields })
    }

    var fieldHandler: ReactEventHandler = function (ev) {
        let elem = (ev.target as HTMLInputElement);
        setForm(elem.name, elem.value)
    }

    return <SetupPage title={setupPageTitle} crumbs={crumbs}
        message={message}
        commands={
            fields ? [
                {
                    key: 'saveItem',
                    text: 'Save',
                    iconProps: { iconName: 'Save' },
                    disabled: disableSave,
                    onClick: () => {
                        setDisableSave(true);
                        (async () => {
                            try {
                                let result = await PortalClient.saveClient(fields);
                                setForm("id", String(result.id));
                                setMessage(["Successfully Saved!", MessageBarType.success]);
                                history.push("/admin/clients");
                            }
                            catch (ex) {
                                setMessage([ex.message, MessageBarType.error]);
                            }
                            setDisableSave(false);
                        })()

                    }
                },
                {
                    key: 'cancelItem',
                    text: 'Close',
                    iconProps: { iconName: 'Cancel' },
                    onClick: () => {
                        history.push("/admin/clients");
                    }
                }
            ] : undefined}>
        <Stack
            tokens={{ childrenGap: "1rem" }} horizontal wrap disableShrink>
            {
                !fields ?
                    <ShimmerForm /> : <>
                        {
                            (formFields(fieldHandler, setForm) as { name: keyof IClient, ddp?: IDropdownProps, props?: ITextFieldProps & { mask?: string } }[]).map(field =>
                                <Stack.Item className={halfField}>
                                    {field.props ? (field.props.mask ?
                                        <MaskedTextField value={String(fields[field.name] || "")} name={field.name} {...field.props} /> :
                                        <TextField value={String(fields[field.name] || "")} name={field.name} {...field.props} />) :
                                        field.ddp && <Dropdown selectedKey={String(fields[field.name])} {...field.ddp} />
                                    }
                                </Stack.Item>)
                        }
                    </>
            }
        </Stack>
    </SetupPage>

}

