import { useState, useEffect } from "react";
import { UserClient, IUserProfile, ApiClient } from "../../api/client";
import React from "react";
import { useSearchParams } from "../util";
import { Link, Text, Stack, Dropdown, mergeStyles, Separator, Label, IDropdownOption } from "@fluentui/react";
import { SetupPage } from "./SetupPage";
import { fieldQuestions, fieldOptions } from "../../SignUpPage";

const fieldStack = mergeStyles({

    "&>*>*": {
        width: "33%",
        boxSizing: "border-box",
        marginBottom: "1rem"
    }

});

export function ProfilePage(props: { userId?: number }) {
    const searchUserId = Number(useSearchParams().get("userid"));
    const userId = props.userId || searchUserId;
    const [profiles, setProfiles] = useState<IUserProfile[]>();
    //const [message, setMessage] = useMessage();
    const [sessionId, setSessionId] = useState<number>();
    useEffect(() => {
        (async function () {
            try {
                const newProfiles = (await UserClient.profile(userId));
                setProfiles(newProfiles);
                setSessionId(newProfiles[0].sessionId);
            }
            catch (ex) {
                alert(ex.message);
                //setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [userId, setProfiles])
    let profile: IUserProfile | null = null;
    if (sessionId)
        profile = profiles?.find(p => p.sessionName) || null;

    function getQuestion(key: keyof typeof fieldQuestions) {
        return fieldQuestions[key][0];
    }
    function getValueAndScore(key: keyof typeof fieldQuestions): [string, number] {
        const val = profile?.response[key];
        if (val instanceof Array) {
            const isMulti = Boolean(fieldQuestions[key][2]);
            const options = fieldOptions[key as keyof typeof fieldOptions];
            if (isMulti) {
                let output: string[] = [];
                let score = 0;
                options.forEach((o) => {
                    if ((val[0] & (o.key as number)) === o.key) {
                        output.push(o.text);
                        if (ApiClient.isAdmin)
                            score += (o as any).score;
                    }
                });
                return [output.join(","), score];
            }
            else {
                const opt = options.find(o => o.key === val[0]) as IDropdownOption;
                return [opt.text, ApiClient.isAdmin ? (opt as any).score : 0];
            }
        }
        else
            return [val as string, 0];
    }

    const experience: (keyof typeof fieldQuestions)[] = ["fieldOfWork", "pursueDegree", "degree", "study", "almas", "workExperience", "projectManagement",
        "technicalExpertise", "languages", "studyAbroad", "militaryExperience", "securityClearance"]
    const availability: (keyof typeof fieldQuestions)[] = ["schoolHours", "workHours", "busy"];
    const personality: (keyof typeof fieldQuestions)[] = ["leader", "personality", "gamePref"]
    const preferences: (keyof typeof fieldQuestions)[] = ["teamPref", "sourcePref", "obPref"]
    return <SetupPage
        title={profile ? `${profile.firstName} ${profile.lastName}` : "Loading..."}
    >
        {profiles &&
            <Stack>
                <Stack horizontal verticalAlign="center">
                    <Text variant="large"><Link to={`mailto:${profile?.email}`}>{profile?.email}</Link></Text>
                    <Text variant="large">&nbsp;| {profile?.clientName}</Text>
                    <Text variant="large">&nbsp; | {getValueAndScore("gender")[0]}</Text>
                    {ApiClient.isAdmin && <Text variant="large">&nbsp;| Score: {profile?.Score}</Text>}
                    <Dropdown selectedKey={sessionId} className={mergeStyles({ marginLeft: "auto !important" })} options={profiles.map(p => ({
                        key: p.sessionId,
                        text: p.sessionName
                    }))}
                        onChange={(ev, dd) => setSessionId(dd?.key as number)}
                    /></Stack>
                <Stack maxHeight={"calc(100vh - 12rem)"} style={{ overflow: "auto" }}>
                    <Separator></Separator>
                    <Text variant="large">Experience</Text>
                    <Separator></Separator>
                    <Stack horizontal className={fieldStack} wrap disableShrink>
                        {
                            experience.map((v: any) => {
                                const gvs = getValueAndScore(v);

                                return <Stack>
                                    <Label>{getQuestion(v)}
                                        {gvs[1] > 0 && <Text style={{ float: "right", marginRight: "12%" }} variant="medium">{gvs[1]}</Text>}
                                    </Label>
                                    <Text>{gvs[0]}</Text>
                                </Stack>
                            })
                        }
                    </Stack>
                    <Separator></Separator>
                    <Text variant="large">Availability</Text>
                    <Separator></Separator>
                    <Stack horizontal className={fieldStack} wrap disableShrink>
                        {
                            (availability).map((v: any) => {
                                const gvs = getValueAndScore(v);

                                return <Stack>
                                    <Label>{getQuestion(v)}
                                        {gvs[1] !== 0 && <Text style={{ float: "right", marginRight: "12%" }} variant="medium">{gvs[1]}</Text>}
                                    </Label>
                                    <Text>{gvs[0]}</Text>
                                </Stack>
                            })
                        }
                    </Stack>
                    <Separator></Separator>
                    <Text variant="large">Personality</Text>
                    <Separator></Separator>
                    <Stack horizontal className={fieldStack} wrap disableShrink>
                        {
                            personality.map((v: any) => {
                                const gvs = getValueAndScore(v);

                                return <Stack>
                                    <Label>{getQuestion(v)}
                                        {gvs[1] > 0 && <Text style={{ float: "right", marginRight: "12%" }} variant="medium">{gvs[1]}</Text>}
                                    </Label>
                                    <Text>{gvs[0]}</Text>
                                </Stack>
                            })
                        }
                    </Stack>
                    <Separator></Separator>
                    <Text variant="large">Preferences</Text>
                    <Separator></Separator>
                    <Stack horizontal className={fieldStack} wrap disableShrink>
                        {
                            preferences.map((v: any) => {
                                const gvs = getValueAndScore(v);

                                return <Stack>
                                    <Label>{getQuestion(v)}
                                        {gvs[1] > 0 && <Text style={{ float: "right", marginRight: "12%" }} variant="medium">{gvs[1]}</Text>}
                                    </Label>
                                    <Text>{gvs[0]}</Text>
                                </Stack>
                            })
                        }
                    </Stack>
                </Stack>
            </Stack>
        }
    </SetupPage>
}