import React, { ReactElement, useState, FunctionComponent, useCallback } from "react";
import { MessageBar, MessageBarType, IBreadcrumbItem, Link, Dropdown, IDropdownOption, IColumn, ICommandBarItemProps } from "@fluentui/react";
import { useLocation, Redirect, Switch } from "react-router-dom";


// export function useLoadData(fetchFunc: () => void, deps?: React.DependencyList) {
//     useEffect(() => {
//         fetchFunc();
//     }, deps || [])
// }

// export function useLoadDataOrError(fetchFunc: () => Promise<any>,setError:(msg:string)=>void, deps?: React.DependencyList) {
//     useEffect(() => {
//         fetchFunc().catch(ex=>{
//             setError(ex.message||String(ex)||"Default Unknown Error");
//         })
//     },  deps|| [])
// }


export let LoadOrError = function (props: { error?: string, children: ReactElement }) {
    return props.error ? (<MessageBar messageBarType={MessageBarType.error}>{props.error}</MessageBar>) : props.children
}

export function useSearchParams() {
    return new URLSearchParams(useLocation().search)
}


export type MessageState = [string | ReactElement, MessageBarType?];

export function useMessageState() {
    return useState<MessageState>();
}
export function useMessage(seed?: MessageState) {
    const [message, setMessageState] = useState<MessageState | undefined>(seed);
    const setMessage = useCallback((message: ReactElement, type: MessageBarType = MessageBarType.info) => {
        setMessageState(message ? [message, type] : undefined);
    }, [setMessageState])
    return ([message, setMessage] as [MessageState | undefined, (message: ReactElement | string | undefined, type?: MessageBarType) => void]);
}

export function useCrumbs() {
    return useState<IBreadcrumbItem[]>([])
}
export function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

function _extractKey<T>(key: (keyof T) | [keyof T, string]) {
    return key instanceof Array ? key[0] : key;
}

function _extractAlias<T>(key: (keyof T) | [keyof T, string]) {
    return key instanceof Array ? key[1] : key;
}

export function CSVify<T>(objects: T[], ...keys: ((keyof T) | [keyof T, string])[]) {
    let csvBuffer = [];

    csvBuffer.push(keys.map(k => _extractAlias(k)).join(","));
    objects?.forEach((item: any) => {
        csvBuffer.push(keys.map((h) => {
            //regex replace " with \""
            let tester = item[_extractKey(h)];
            return tester === "" || tester === null || tester === undefined ? '' : `"${tester || ""}"`;
        }));
    });
    return csvBuffer.join("\r\n")
}

export function objectURLFromString(data: string, mimeType: string) {
    return URL.createObjectURL(new Blob([data], { type: mimeType }))
}
export function csvURL<T>(objects: T[], ...keys: ((keyof T) | [keyof T, string])[]) {
    return objectURLFromString(CSVify(objects, ...keys), "text/csv");
}
export function csvMessage<T>(downloadName: string, objects: T[], ...keys: ((keyof T) | [keyof T, string])[]): [string | ReactElement, MessageBarType?] {
    return [<>Your<Link href={csvURL(objects, ...keys)} download={downloadName + ".csv"}>{downloadName} export</Link> is ready</>, MessageBarType.success];
}

// export interface ICaseProps{
//     children: ReactElement[]
// }
// export function Case(){

// }
// export function Switch(props: { children: ReactElement[] }){
//     for(var c of props.children) {
//         if(c.type === Case){

//         }
//     }
//     return null;
// }
export interface CaseProps {
    when: boolean
};
export var Case: FunctionComponent<CaseProps> = function (props) {
    return <>{props.children}</>
};
export var Default: FunctionComponent = function (props) {
    return <>{props.children}</>
};

// function _isCase(test: any): test is ReactElement<CaseProps> {
//     return test.type === Case;
// }
// function _isDefault(test: any): test is ReactElement<CaseProps> {
//     return test.type === Default;
// }
// export var Switch: FunctionComponent=  function(props){
//     let output: ReactElement= <></>;
//     let children = React.Children.toArray(props.children);
//     for (let index = 0; !output && index < children.length; index++) {
//         const child = children[index];
//         if(_isDefault(child) || (_isCase(child) && child.props.when))
//             output = child;
//     }
//     return output;
// }


export var Switch404: FunctionComponent = function (props) {
    return <Switch>
        {props.children}
        <Redirect to="/404"></Redirect>
    </Switch>

}


export function TimePicker(props: { selectedKey?: string, label?: string, name: string, onChange: (name: string, dd: IDropdownOption | undefined) => void, placeholder: string }) {
    return <Dropdown label={props.label}
        selectedKey={props.selectedKey}
        options={
            ["8:00am", "8:30am", "9:00am", "9:30am", "10:00am", "10:30am", "11:00am", "11:30am",
                "12:00pm", "12:30pm", "1:00pm", "1:30pm", "2:00pm", "2:30pm", "3:00pm", "3:30pm", "4:00pm", "4:30pm", "5:00pm", "5:30pm", "6:00pm", "6:30pm"]
                .map(t => ({ key: t, text: t }))}
        onChange={(ev, dd) => props.onChange(props.name, dd)}
        placeholder={props.placeholder}
    />
}

export function setSortable<T>(columns: IColumn[], listItems: T[], setColumns: (cols: IColumn[]) => void, setItems: (items: T[]) => void) {
    let columnClicked = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        let newColumns = columns.slice();
        let isSortedDescending: boolean;
        newColumns.forEach((c) => {
            if (c.key === column.key) {
                isSortedDescending =
                    c.isSortedDescending = !c.isSortedDescending;
                c.isSorted = true;
            }
            else {
                c.isSortedDescending = true;
                c.isSorted = false;
            }
        })
        let newItems = listItems.slice().sort((a, b) => {
            let val = a[column.fieldName as keyof T];
            let val2 = b[column.fieldName as keyof T];
            let output: number = -1;

            if (val === val2) {
                output = 0;
            }
            else if ((val === undefined || val === null) && (val2 !== undefined && val2 !== null)) {
                output = -1;
            }
            else if ((val2 === undefined || val2 === null) && (val !== undefined && val !== null)) {
                output = 1;
            }
            else if (val instanceof Date && val2 instanceof Date) {
                output = val.valueOf() - val2.valueOf();
            }
            else if (typeof val === 'string' && typeof val2 === 'string') {
                output = val.localeCompare(val2);
            }
            else if (typeof val === 'number' && typeof val2 === 'number') {
                output = val - val2;
            }

            return output * (isSortedDescending ? -1 : 1)
        });

        setColumns(newColumns);
        setItems(newItems);
    }
    columns.forEach(c => {
        if (c.fieldName)
            c.onColumnClick = columnClicked
    });
}


export function editFormCommands(saveFunc: () => Promise<any>, hist: any, redirect: string, disableSave: boolean, setDisableSave: (ds: boolean) => void, setMessage: (message: ReactElement | string | undefined, type?: MessageBarType) => void): ICommandBarItemProps[] {
    return [{
        key: 'saveItem',
        text: 'Save',
        iconProps: { iconName: 'Save' },
        disabled: disableSave,
        onClick: () => {
            setDisableSave(true);
            (async () => {
                try {
                    await saveFunc();
                    hist.push(redirect)
                }
                catch (ex) {
                    setMessage(ex.message, MessageBarType.error)
                }
                setDisableSave(false);
            })()

        }
    }, {
        key: 'cancelItem',
        text: 'Cancel',
        iconProps: { iconName: 'Cancel' },
        onClick: () => {
            hist.push(redirect);
        }
    }]
}

export async function runAsync<T = any>(promise: Promise<T>, setMessage?: (message: ReactElement | string | undefined, type?: MessageBarType) => void, success = ("Completed" as ReactElement | string)) {
    try {
        if (setMessage)
            setMessage(undefined);
        await promise;
        if (setMessage)
            setMessage(success, MessageBarType.success)

    }
    catch (ex) {
        if (setMessage)
            setMessage(ex.message, MessageBarType.error);
    }
}
