import React, { useState, useEffect, useMemo, useCallback } from "react";
import { IUser, PortalClient, UserClient, IUserProfile, ApiClient } from "../../api/client";
import { Link, Selection, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, MessageBarType, IColumn, DetailsList, IDropdownOption, Dropdown } from "@fluentui/react";
import { SetupPage } from "./SetupPage";
import { setSortable, useMessage, useSearchParams, useCrumbs } from "../util";
import { ie2, imortExportList } from "../../styles";
import { useHistory } from "react-router-dom";
import { fieldQuestions, fieldOptions } from "../../SignUpPage";

type T = IUser
const setupPageTitle = "Setup: Users"

export function UserSetupPage() {
    const hist = useHistory();
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [users, setUsers] = useState<IUser[]>([])
    const [message, setMessage] = useMessage();
    const [runValue, setRunValue] = useState(0);
    let selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setSelectedUser(selection.getSelection()[0] as IUser);
        }
    }), [setSelectedUser]);
    const [columns, setColumns] = useState<IColumn[]>([
        {
            name: "First Name",
            fieldName: "firstName",
            key: "fn",
            minWidth: 96,
            maxWidth: 96,
            isRowHeader: true,
            isResizable: true
        },
        {
            name: "Last Name",
            fieldName: "lastName",
            key: "LastName",
            minWidth: 96,
            maxWidth: 96,
            isRowHeader: true,
            isResizable: true
        },
        {
            name: "Email",
            fieldName: "email",
            key: "email",
            minWidth: 160,
            maxWidth: 160,
            onRender: (client: T) => {
                return <Link href={"mailto:" + client.email}>{client.email}</Link>
            },
            isResizable: true
        },
        {
            name: "Client",
            fieldName: "clientCode",
            key: "clientCode",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true
        },
        {
            name: "Last Login",
            fieldName: "lastSignIn",
            key: "lastSignIn",
            minWidth: 128,
            maxWidth: 128,
            isResizable: true
        },
        {
            name: "Created",
            fieldName: "createdAt",
            key: "createdAt",
            minWidth: 128,
            maxWidth: 128,
            isResizable: true
        },
        {
            name: "Confirmed",
            fieldName: "confirmed",
            key: "confirmed",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true
        },
        {
            name: "Code",
            fieldName: "confirmCode",
            key: "confirmCode",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true
        }, {
            name: "Session Count",
            fieldName: "sessionCount",
            key: "sessionCount",
            minWidth: 32,
            maxWidth: 32,
            isResizable: true,
        }
    ]);

    setSortable(columns, users, setColumns, setUsers);

    useEffect(() => {
        (async function () {
            try {
                setUsers((await PortalClient.users()).filter(u => !u.email?.match('.*@waymark.test')));
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [runValue, setMessage])


    return <SetupPage title={setupPageTitle} message={message} commands={[
        {
            key: "profile",
            text: "Profile",
            disabled: !selectedUser,
            iconProps: {
                iconName: "Contact"
            },
            onClick: () => {
                hist.push("/users/profilelist?userid=" + selectedUser?.id)
            }
        }, {
            key: 'disable',
            text: selectedUser && selectedUser.email?.match("__.*__") ? 'Enable' : 'Disable',
            disabled: !selectedUser,
            iconProps: { iconName: 'Blocked' },
            onClick: () => {
                (async () => {
                    try {
                        await UserClient.blockUser(selectedUser?.id as number);
                        setMessage(`${selectedUser?.firstName} ${selectedUser?.lastName} has been ${(selectedUser?.email?.match("__.*__") ? 're-enabled' : 'blocked')}`, MessageBarType.info);
                        setRunValue(runValue + 1);
                    }
                    catch (ex) {
                        setMessage(ex.message, MessageBarType.error);
                    }
                })()
            },
        }
    ]}>
        <div
            className={ie2}
            
        >
            <ShimmeredDetailsList
                
                items={users}
                columns={columns}
                setKey={String(users.length)}
                selectionMode={SelectionMode.single}
                layoutMode={DetailsListLayoutMode.justified}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                //onItemInvoked={this._onItemInvoked}
                key={users.length}
                enterModalSelectionOnTouch={true} />
        </div>
    </SetupPage>

}


export function ProfileList() {
    const userId = Number(useSearchParams().get("userid"))
    const [message, setMessage] = useMessage();
    const [columns, setColumns] = useState<IColumn[]>([
        {
            key: "category",
            fieldName: "category",
            name: "Category",
            minWidth: 96,
            isRowHeader: true,
            isResizable: true,
        },
        {
            key: "question",
            fieldName: "question",
            name: "Question",
            minWidth: 512,
            isRowHeader: true,
            isResizable: true,
        },
        {
            key: "response",
            fieldName: "response",
            name: "Response",
            minWidth: 512,
            isResizable: true,
        },
        {
            key: "score",
            fieldName: "score",
            name: "Score",
            minWidth: 64,
            isResizable: true,
        }
    ])
    type ItemType = { category: string, question: string, response: string, score: number };
    const [responses, setItems] = useState<ItemType[]>([]);
    const [profiles, setProfiles] = useState<IUserProfile[]>([]);
    const [profile, setProfile] = useState<IUserProfile>();
    const [crumbs, setCrumbs] = useCrumbs();

    function getQuestion(key: keyof typeof fieldQuestions) {
        return fieldQuestions[key][0];
    }
    const getValueAndScore = useCallback((key: keyof typeof fieldQuestions) => {
        const val = profile?.response[key];
        if (val instanceof Array) {
            const isMulti = Boolean(fieldQuestions[key][2]);
            const options = fieldOptions[key as keyof typeof fieldOptions];
            if (isMulti) {
                let output: string[] = [];
                let score = 0;
                options.forEach((o) => {
                    if ((val[0] & (o.key as number)) === o.key) {
                        output.push(o.text);
                        if (ApiClient.isAdmin)
                            score += (o as any).score;
                    }
                });
                return [output.join(","), score];
            }
            else {
                const opt = options.find(o => o.key === val[0]) as IDropdownOption;
                return [opt.text, ApiClient.isAdmin ? (opt as any).score : 0];
            }
        }
        else
            return [val as string, 0];
    }, [profile]);


    useEffect(() => {
        if (profile) {
            let newResponses: ItemType[] = []
            setProfile(profile);

            setCrumbs([
                { key: "users", text: "Users" },
                { key: "client", text: profile.clientName },
                { key: "name", text: `${profile.firstName} ${profile.lastName} (${[getValueAndScore("gender")?.[0]]})`, isCurrentItem: true },
                { key: "session", text: "" }
            ]);

            //Experience
            let allFields = {
                Experience: ["program", "semester", "degree", "study", "almas", "jobs", "workExperience", "projectManagement",
                    "technicalExpertise", "languages", "studyAbroad", "militaryExperience", "securityClearance"] as (keyof typeof fieldQuestions)[],
                Availability: ["schoolHours", "workHours", "busy"] as (keyof typeof fieldQuestions)[],
                Personality: ["leader", "personality", "gamePref"] as (keyof typeof fieldQuestions)[],
                Preferences: ["teamPref", "sourcePref", "obPref"] as (keyof typeof fieldQuestions)[]
            };
            for (const category in allFields) {
                allFields[category as keyof typeof allFields]
                    .forEach((k) => {
                        let valAndSocre = getValueAndScore(k);
                        newResponses.push({
                            category: category,
                            question: getQuestion(k),
                            response: valAndSocre[0],
                            score: valAndSocre[1]
                        })
                    })
            }

            setItems(newResponses);
        }
    }, [profile, getValueAndScore, setCrumbs]);


    setSortable(columns, responses, setColumns, setItems);

    useEffect(() => {
        (async () => {
            try {
                let profResp = await UserClient.profile(userId as number);
                setProfiles(profResp);
                if (profResp.length)
                    setProfile(profResp[0]);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }
        })()
    }, [userId, setMessage])

    return <SetupPage
        title={setupPageTitle}
        message={message}
        crumbs={crumbs}
        crumbRender={(p, r) => {
            if (p?.key === "session")
                return <Dropdown defaultSelectedKey={profile?.sessionId} styles={{ root: { width: "15rem" } }} style={{ width: "15rem" }} options={profiles?.map(p => ({ key: p.sessionId as number, text: `${p.sessionName} | ${p.Score}` }))}></Dropdown>
            else if (r) return r(p)
            else
                throw new Error("Render Error");
        }}

    > <DetailsList
            className={imortExportList}
            selectionMode={SelectionMode.none}
            items={responses}
            columns={columns}
        />
    </SetupPage>

}


