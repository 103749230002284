import { card } from "../../styles";
import { IStyleExt } from "../IExt";
import { logoNoText } from "../../images";
import { IStackProps, mergeStyles, MessageBarType, Stack, MessageBar, Text, Image } from "@fluentui/react";
import React, { ReactElement } from "react";

export function Card(props: IStackProps & IStyleExt & { cardTitle: string, message?: { type?: MessageBarType, text?: ReactElement|string|undefined } }) {
    return (<Stack tokens={{childrenGap:"0.5rem"}}   as={props.as} className={mergeStyles(card, props.className, props.styleExt)} {...props}>
        <Stack horizontal><Image height="2rem" src={logoNoText}></Image><Text variant="xLarge">{props.cardTitle}</Text></Stack>
        {
            [
                props.message?.text && <MessageBar messageBarType={props.message.type}>{props.message.text}</MessageBar>,
                props.children
            ]
        }
    </Stack>)
}