import { merge, mergeStyles } from "@fluentui/react";
import { red, bgColor } from "./colors";
import { getTheme } from '@fluentui/react';


const theme = getTheme();

export let absolute = mergeStyles({
    position: "absolute"
})

export let appScreen = mergeStyles(absolute, {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: bgColor
});

export let appHeader = mergeStyles(absolute,
    {
        height: "4rem",
        borderBottom: "1px solid #333438",
        borderTop: "1px solid #333438",
        lineHeight: "3.5rem",
        backgroundColor: "white",
        boxSizing: "border-box",
        overflow: "hidden",
        width: "100%"
    });

export let appContent = mergeStyles(absolute,
    {
        top: "4rem",
        height: "calc(100% - 4rem)",
        width: "100%"
    })

export let appHeader_link = mergeStyles(
    {
        fontSize: "1.25rem",
        textDecoration: "none",
        color: red,
    });
export let appHeader_link__underline = mergeStyles(appHeader_link, {
    "&:hover": {
        borderBottom: "4px solid " + red
    }
});

export let card = mergeStyles("ms-depth-4", {
    backgroundColor: "white",
    padding: "1rem",
    boxSizing: "border-box",
    maxHeight: "100%"
})

export const halfField = mergeStyles({
    minWidth: "calc(50% - 1rem)"
})
export const fullField = mergeStyles({
    flexBasis: "100%"
})

export const centerPiece = mergeStyles({ margin: "5% 0", width: "22rem" });
export let fileUploadStyle = mergeStyles({
    border: "1px dashed #ccc",
    padding: "1rem",
    backgroundColor: "#f5f5f5"
})


export const imortExportList = mergeStyles({

    "& .ms-DetailsList-contentWrapper": {
        //overflowY: "auto",
        maxHeight: 'calc(100vh - 24rem)',
        //overflowX: 'hidden'
    }

})


export const ie2 = mergeStyles({


    maxHeight: 'calc(100vh - 24rem)',
    overflowY: 'auto'


})

export const paymentContainer = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'lightgray',
    width: '400px',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: theme.effects.elevation64,
    margin: '100px'
})
export const exteriorContainer = mergeStyles({
    display: 'flex',
    justifyContent: 'center'
})
export const paymentDropdownContainer = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '10px'
})