import React, { useState, useEffect, ReactEventHandler, useMemo, useCallback, useRef } from "react";
import { ISession, SessionClient, RefClient, ISessionUser, IRole, ScenarioClient, ISessionSchedule, ScheduleClient, UserClient, IUser, IUserNDA, ApiClient } from "../../api/client";
import { Link as MsLink, Selection, Stack, Text, ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, TextField, mergeStyles, MessageBar, MessageBarType, Dropdown, IDropdownOption, IRenderFunction, IDetailsRowProps, Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, DatePicker, IColumn, IBreadcrumbItem, Checkbox, Separator, DetailsList, ScrollablePane, IObjectWithKey } from "@fluentui/react";
import { ShimmerForm } from "../shimmer/ShimmerForm";
import { useSearchParams, useMessageState, TimePicker, useMessage, editFormCommands, setSortable, useCrumbs, csvMessage } from "../util";
import { useHistory, Switch, Route } from "react-router-dom";
import { DropdownAsync } from "../form/DropdownAsync";
//import { useBoolean } from '@uifabric/react-hooks';
import { SetupPage } from "./SetupPage";
import { ie2, imortExportList } from "../../styles";
import { ProfilePage } from "./ProfilePage";

type T = ISession

let unassignedText = mergeStyles({ color: "lightgray" })
let setupPageTitle = "Setup: Sessions"
let rootCrumb: (hist: any) => IBreadcrumbItem = (hist) => ({ key: "root", text: "Sessions", onClick: () => hist.push('/admin/sessions') });


let onRenderRow: IRenderFunction<IDetailsRowProps> = function (props, defaultRenderer) {
    if (props && props.item.isDirty)
        props.className = mergeStyles(props.className, {
            backgroundColor: "#f5f5f5"
        })

    return defaultRenderer ? defaultRenderer(props) : <div />;
}
function sessionRootUrl(relativeUrl: string) {
    return '/admin/sessions' + (relativeUrl ? '/' + relativeUrl : '');
}
const listPath = sessionRootUrl('');
const editPath = sessionRootUrl('edit');
const managePath = sessionRootUrl('manage');
const sessionQueryKey = "sessionid";
function sessionQuery(id: number) {
    return `?${sessionQueryKey}=${id}`;
}

function SessionList() {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    const history = useHistory();
    const [session, setSelectedSession] = useState<ISession>();
    const [runValue, setRunValue] = useState(0);
    const [shimmer, setShimmer] = useState(true);

    const [columns, setColumns] = useState<IColumn[]>([
        {
            name: "Name",
            fieldName: "name",
            key: "Name",
            minWidth: 128,
            maxWidth: 128,
            isRowHeader: true,
            isResizable: true,
        }, {
            name: "Client",
            fieldName: "clientCode",
            key: "clientCode",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true
        },
        {
            name: "Scenario",
            fieldName: "scenarioCode",
            key: "scenarioCode",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true
        },
        {
            name: "AccessToken",
            fieldName: "accessCode",
            key: "accessToken",
            minWidth: 64,
            maxWidth: 64,
            isResizable: true,

        },
        {
            name: "",
            fieldName: "pendingCount",
            key: "pendingCount",
            minWidth: 32,
            maxWidth: 32,
            iconName: "WaitlistConfirm",
            isResizable: false
        },
        {
            name: "",
            key: "userCount",
            fieldName: "participantCount",
            minWidth: 32,
            maxWidth: 32,
            iconName: "Group",
            onRender: (item: T) => {
                return `${item.userCount}/${item.participantCount}`;
            },
            isResizable: false
        },
        {
            name: "Turn",
            key: "turn",
            fieldName: "turn",
            minWidth: 32,
            maxWidth: 32,
            onRender: (item: T) => {
                return `${item.turn}/${item.totalTurns}`;
            },
            isResizable: false
        },
        {
            name: "Status",
            key: "status",
            minWidth: 64,
            maxWidth: 64,
            fieldName: "status",
            isResizable: true
        },
        {
            name: "Cost",
            key: "cost",
            fieldName: "cost",
            minWidth: 60,
            maxWidth: 60,
            onRender: (itm: T) => {
                const formattedCost = formatter.format(Number(itm.cost))
                return (
                    // itm.cost ? `${itm.cost}`: ''
                    formattedCost ? `${formattedCost}` : ''
                )
            },
            isResizable: false
        },
        {
            name: "Created At",
            key: "createdAt",
            fieldName: "createdAt",
            minWidth: 32,
            maxWidth: 32,
            onRender: (itm: T) => {
                return new Date(itm.createdAt as any).toDateString()
            },
            isResizable: false
        }
    ]);
    const [sessions, setSessions] = useState<ISession[]>([]);
    //const [message, setMessage] = useMessage();
    setSortable(columns, sessions, setColumns, setSessions);
    useEffect(() => {
        (async function () {
            try {
                setSessions(await SessionClient.get())
                setShimmer(false);
            }
            catch (ex) {
                alert(ex.message);
            }
        })()
    }, [runValue, setSessions, setShimmer])

    let selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setSelectedSession(selection.getSelection()[0] as ISession)
        }
    }), [setSelectedSession]);

    return (<SetupPage title={setupPageTitle} commands={[
        {
            key: 'newItem',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: () =>
                history.push(editPath)
        }, {
            key: 'schedule',
            text: 'Schedule',
            disabled: !session,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
                history.push("/admin/sessions/schedule?sessionid=" + session?.id)
            }
        },
        {
            key: 'nda',
            text: 'NDAs',
            disabled: !session,
            iconProps: { iconName: 'PenWorkspace' },
            onClick: () => {
                history.push("/admin/sessions/nda?sessionid=" + session?.id)
            }
        },
        {
            key: 'editItem',
            text: 'Manage',
            disabled: !session?.id,
            split: true,
            iconProps: { iconName: 'Group' },
            onClick: () =>
                history.push(managePath + sessionQuery(session?.id as number)),
            subMenuProps: {
                items: [
                    {
                        key: "accessCode",
                        text: "Change Token",
                        disabled: !session,
                        iconProps: { iconName: "Refresh" },
                        onClick: () => {
                            (async () => {
                                try {
                                    await SessionClient.refreshAccessToken(session?.id as number);
                                    setRunValue(runValue + 1);
                                }
                                catch (ex) {
                                    alert(JSON.stringify(ex))
                                }
                            })()
                        }
                    },
                    {
                        text: "Delete",
                        key: "delete",
                        onClick: () => {
                            (async () => {
                                await SessionClient.delete(session?.id as number);
                                setSessions([]);
                                setShimmer(true);
                                setRunValue(runValue + 1);
                            })()
                        }
                    }]
            }
        },
        {
            key: 'openViewers',
            text: 'Open',
            disabled: !session,
            iconProps: { iconName: 'Globe2' },
            split: true,
            onClick: () => {
                window.location.href = "/sessionviewer?id=" + session?.id
            },
            subMenuProps: {
                items: [
                    {
                        key: 'Map', text: 'Map', iconProps: { iconName: 'Globe2' }, onClick: () => {
                            window.location.href = "/sessionviewer?id=" + session?.id
                        }
                    },
                    {
                        key: 'PolicyResponses',
                        text: 'Policy Responses',
                        disabled: !session?.turn,
                        iconProps: { iconName: 'M365InvoicingLogo' }, onClick: () => {
                            window.location.href = "/policyquadviewer?sessionid=" + session?.id
                        }
                    },
                    {
                        key: 'CollectionResponses', text: 'Collection Responses', iconProps: { iconName: 'MapPin' },
                        onClick: () => {
                            history.push(`/collectionresponse?sessionid=${session?.id}`)
                        }
                    },
                ],
            }
        }
    ]}
    >
        <ShimmeredDetailsList
            shimmerLines={4}
            items={sessions}
            columns={columns}
            setKey="set"
            enableShimmer={shimmer}
            selectionMode={SelectionMode.single}
            layoutMode={DetailsListLayoutMode.justified}
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            //onItemInvoked={this._onItemInvoked}
            enterModalSelectionOnTouch={true} />


    </SetupPage>)
}

export function SessionSetupPage() {
    let sessionId = Number(useSearchParams().get(sessionQueryKey));
    return <Switch>
        <Route path="/admin/sessions/edit">
            <SessionSetupPanel sessionId={sessionId} />
        </Route>
        <Route path="/admin/sessions/manage">
            <SessionManageView sessionId={sessionId} />
        </Route>
        <Route path="/admin/sessions/schedule">
            <SessionScheduleView sessionId={sessionId} />
        </Route>
        <Route path="/admin/sessions/nda">
            <SessionNDAView sessionId={sessionId} />
        </Route>
        <Route path="/admin/sessions">
            <SessionList />
        </Route>
    </Switch>
}

let halfField = mergeStyles({
    minWidth: "calc(50% - 1rem)"
})
// let fullField = mergeStyles({
//     flexBasis: "100%"
// })


function SessionSetupPanel(props: { sessionId: number }) {

    let history = useHistory();

    const [session, setSession] = useState<ISession>();
    const [message, setMessage] = useMessage();
    const [disableCommands, setDisableCommands] = useState(false);
    const [participantPromise, setParticipantPromise] = useState<Promise<number[]>>(async function () { return [] });
    const [fields, setFields] = useState({
        name: "",
        clientId: 0,
        scenarioId: 0,
        turns: 0,
        hpt: 0,
        participants: 0,
        seedTurn: 0,
        morningTime: "",
        eveningTime: "",
        startDate: new Date(),
        cost: '',
    })
    const [crumbs, setCrumbs] = useState<IBreadcrumbItem[]>([])

    function setForm(name: string, value: string) {
        (fields as any)[name] = value;
        setFields({ ...fields })

    }

    var fieldHandler: ReactEventHandler = function (ev) {
        let elem = (ev.target as HTMLInputElement);
        setForm(elem.name, elem.value)
    }

    var ddHandler = function (name: string, dd: IDropdownOption | undefined) {
        setForm(name, String(dd ? dd.key : ""))
    }

    const costHandler: ReactEventHandler = function (ev) {
        let elem = (ev.target as HTMLInputElement);

        setForm(elem.name, elem.value)
    }
    // var dateHandler = function (name: string, dd: IDropdownOption | undefined) {
    //     setForm(name, String(dd ? dd.key : ""))
    // }

    useEffect(() => {
        let newCrumbs = [rootCrumb(history)];
        if (props.sessionId)
            try {
                (async () => {
                    let ses = await SessionClient.findById(props.sessionId as number);
                    setSession(ses);
                    newCrumbs.push({ key: "session", text: "" }, { key: "edit", text: "Edit", isCurrentItem: true });
                    setCrumbs(newCrumbs);
                })()
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error)
            }
        else {
            newCrumbs.push({ key: "new", text: "New", isCurrentItem: true });
            setSession({} as ISession)
            setCrumbs(newCrumbs);
        }
    }, [props.sessionId, setMessage, history]);

    // useEffect(() => {
    //     if (session) {
    //         setFields({
    //             turns: session.totalTurns as number,
    //             clientId: session.clientId as number,
    //             scenarioId: session.scenarioId as number,
    //             name: session.name as string,
    //             participants: session.participantCount as number,
    //             seedTurn: 0  
    //         })
    //     }
    // }, [session])

    return <SetupPage title={setupPageTitle} crumbs={crumbs} message={message}
        commands={
            editFormCommands(() => SessionClient.save({
                participants: Number(fields.participants),
                name: fields.name,
                cost: fields.cost,
                clientId: Number(fields.clientId),
                hpt: Number(fields.hpt),
                turns: Number(fields.turns),
                scenarioId: Number(fields.scenarioId),
                seedTurn: Number(fields.seedTurn),
                startDate: fields.startDate,
                morningTime: fields.morningTime,
                eveningTime: fields.eveningTime
            }), history, listPath, disableCommands, setDisableCommands, setMessage)}   >
        {!session ? <ShimmerForm /> :
            <Stack
                tokens={{ childrenGap: "1rem" }}
                horizontal wrap disableShrink>

                <Stack.Item className={halfField}><DropdownAsync getItems={async () => (await RefClient.clients()).map(c => ({ key: String(c.id), text: c.name }))} name="clientId" seed={fields.clientId} label="Session Client" placeholder="Pick a client" required onChange={ddHandler} /></Stack.Item>
                <Stack.Item className={halfField}><DropdownAsync getItems={async () => (await RefClient.scenarios()).map(c => ({ key: String(c.id), text: c.name }))} name="scenarioId" seed={fields.scenarioId} label="Session Scenario" placeholder="Pick a scenario" required onChange={
                    (name, dd) => {
                        setParticipantPromise(ScenarioClient.roles(Number(dd?.key)).then(roles => {
                            return Array.from(new Set(roles.map(r => r.participants)))
                        }))
                        ddHandler(name, dd)
                    }} /></Stack.Item>
                <Stack.Item className={halfField}><TextField name="name" value={fields.name} label="Session Name" placeholder="Enter a session name" required onChange={fieldHandler} /></Stack.Item>
                <Stack.Item className={halfField}><Dropdown options={[{ key: 0, text: "Simulation & War Game" }, { key: 1, text: "War Game Only" }]} label="Session Type" placeholder="Pick a session type" required onChange={(ev, dd) => { ddHandler("seedTurn", dd) }} /></Stack.Item>
                <Stack.Item className={halfField}><Dropdown options={[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(v => ({ key: String(v), text: String(v) + " Turns" }))} label="#  Of Turns" placeholder="Pick number of turns" required onChange={(ev, dd) => { ddHandler("turns", dd) }} /></Stack.Item>
                <Stack.Item className={halfField}><TextField value={fields.cost} name='cost' label="Cost Per User" onChange={costHandler} required /></Stack.Item>
                {
                    Number(fields.seedTurn) === 0 ? <>
                        <Stack.Item className={halfField}><DropdownAsync name="participants" getItems={async () => (await participantPromise).map(r => ({ key: String(r), text: `${r} Participants` }))} label="# of Participants" placeholder="Pick the number of participants" required onChange={ddHandler} /></Stack.Item>
                        <Stack.Item className={halfField}><TimePicker name="morningTime" label="Morning Time" placeholder="Pick a morning time" onChange={ddHandler} /></Stack.Item>
                        <Stack.Item className={halfField}><TimePicker name="eveningTime" label="Evening Time" placeholder="Pick an evening time" onChange={ddHandler} /></Stack.Item>
                        <Stack.Item className={halfField}><DatePicker value={fields.startDate} label="Start Date" placeholder="Pick a start date" onSelectDate={(dt) => setForm("startDate", dt as any)} /></Stack.Item>
                    </> :
                        <Stack.Item className={halfField}>
                            <Stack.Item className={halfField}><Dropdown options={[12, 24, 36, 48, 60, 72, 720, 1440, 2160, 4320].map(v => ({ key: v, text: `${((v / 24).toFixed(1)).replace('.0', '')} Day(s)` }))} label="Hours For 1st Turn" placeholder="Pick number of hours" required onChange={(ev, dd) => { ddHandler("hpt", dd) }} /></Stack.Item>
                        </Stack.Item>
                }
            </Stack>
        }
    </SetupPage>




}

function SessionScheduleView(props: { sessionId: number }) {
    const history = useHistory();
    const [schedule, setSchedule] = useState<ISessionSchedule[]>([])
    const [message, setMessage] = useMessage(["Select items to modify. Multiple selection is enabled.", MessageBarType.info]);
    const [isRescheduleHidden, setResHid] = useState(true);
    const toggleResc = () => {
        setResHid(v => !v);
    }


    const [crumbs, setCrumbs] = useCrumbs();
    const [shimmer, setShimmer] = useState(true);
    const [rescError, setRescError] = useState<string>();
    const [rescDate, setRescDate] = useState<Date>();
    const [rescTime, setRescTime] = useState<string>();
    const [runValue, setRunValue] = useState(0);
    const [columns, setColumns] = useState<IColumn[]>([
        { fieldName: "documentName", name: "Document", key: "document", minWidth: 96, isRowHeader: true, isResizable: true },
        { fieldName: "team", name: "Team", key: "team", minWidth: 96, isResizable: true },
        { fieldName: "role", name: "Role", key: "role", minWidth: 96, isResizable: true },
        { fieldName: "scheduledDate", name: "Release Date", key: "release", minWidth: 96, isResizable: true },
        { fieldName: "status", name: "Status", key: "status", minWidth: 96, onRender: (item: ISessionSchedule) => (item.status === -1 ? "Disabled" : (item.status === 1 ? "Released" : "Pending")), isResizable: true }
    ]);
    setSortable(columns, schedule, setColumns, setSchedule);

    let selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setMessage(selection.count ? `${selection.count} item(s) selected` : "Select items to modify. Multiple selection is enabled.", MessageBarType.info)
        }
    }), [setMessage]);
    useEffect(() => {
        (async () => {
            try {
                let session = await SessionClient.findById(props.sessionId);
                setCrumbs([
                    rootCrumb(history),
                    { key: "session", text: session.name || "" },
                    { key: "schedule", text: "Schedule", isCurrentItem: true }
                ])
                let responseSchedule = await SessionClient.getSchedule(props.sessionId);
                setShimmer(false);
                setSchedule(responseSchedule);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }

        })()
    }, [props.sessionId, runValue, history, setCrumbs, setMessage])
    return <SetupPage

        title={setupPageTitle}
        message={message}
        crumbs={crumbs}
        commands={[
            {
                disabled: !selection.count, key: "reschedule", text: "Reschedule", iconProps: { iconName: "Clock" },
                onClick: () => {
                    toggleResc();
                }
            },
            {
                disabled: !selection.count, key: "release", text: "Release", iconProps: { iconName: "Share" },
                onClick: () => {
                    (async function () {
                        try {
                            await ScheduleClient.release((selection.getSelection() as ISessionSchedule[]).map(s => s.id))
                            setRunValue(runValue + 1);
                        }
                        catch (ex) {
                            setMessage(ex.message, MessageBarType.error);
                        }
                    })()
                }

            },
            {
                disabled: !selection.count, key: "disable", text: "Disable", iconProps: { iconName: "Blocked" },
                onClick: () => {
                    (async function () {
                        try {
                            await ScheduleClient.disable((selection.getSelection() as ISessionSchedule[]).map(s => s.id))
                            setRunValue(runValue + 1);
                        }
                        catch (ex) {
                            setMessage(ex.message, MessageBarType.error);
                        }
                    })()
                }

            }
        ]}>

        <ShimmeredDetailsList
            className={imortExportList}
            onShouldVirtualize={() => false}
            enableShimmer={shimmer}
            selection={selection}
            items={schedule || []}
            columns={columns}
        />

        <Dialog hidden={isRescheduleHidden} dialogContentProps={{ title: "Reschedule Items", subText: "Pick a new date and time" }}
            modalProps={{
                isBlocking: false,
                styles: dialogStyles,
            }}>
            {rescError && <MessageBar messageBarType={MessageBarType.error}>{rescError}</MessageBar>}
            <DatePicker
                label="New date"
                placeholder="Pick a new date"
                value={rescDate}
                onSelectDate={(dt) => {
                    setRescDate(dt as Date);
                }} />
            <TimePicker label="New Time" name="time" placeholder="Pick a new time" onChange={(ev, dd) => {
                setRescTime(dd?.key as string);
            }} />
            <DialogFooter>
                <PrimaryButton onClick={() => {
                    setRescDate(undefined);
                    (async () => {
                        if (rescTime && rescDate) {
                            try {
                                await ScheduleClient.reschedule(rescDate, rescTime, (selection.getSelection() as ISessionSchedule[]).map(s => s.id))
                            }
                            catch (ex) {
                                setMessage(ex.message, MessageBarType.error)
                            }
                            toggleResc();
                            setRescDate(undefined);
                            setRescTime(undefined);
                            setRescError(undefined);
                            setRunValue(runValue + 1);
                        }
                        else {
                            setRescError("Date and time are required.")
                        }
                    })()



                }} >Reschedule</PrimaryButton>
                <DefaultButton
                    onClick={() => {
                        toggleResc();
                        setRescDate(undefined);
                        setRescTime(undefined);
                        setRescError(undefined);
                    }}
                >Cancel</DefaultButton>
            </DialogFooter>
        </Dialog>

    </SetupPage>
}

type IColumnExt<T> = IColumn & { fieldName?: keyof T }
function SessionNDAView(props: { sessionId: number }) {
    const history = useHistory();
    const [ndas, setNDAs] = useState<IUserNDA[]>([])
    const [message, setMessage] = useMessage();
    //const [isRescheduleHidden, { toggle: toggleResc }] = useBoolean(true);
    const [crumbs, setCrumbs] = useCrumbs();
    //const [shimmer, setShimmer] = useState(true);
    const [columns, setColumns] = useState<IColumn[]>([
        { fieldName: "firstName", name: "First Name", key: "fn", minWidth: 96, isRowHeader: true, isResizable: true },
        { fieldName: "lastName", name: "Last Name", key: "ln", minWidth: 96, isResizable: true },
        { fieldName: "agreedOn", name: "Agreed On", key: "ag", minWidth: 96, isResizable: true },
        { fieldName: "signature", name: "Signature", key: "sig", minWidth: 96, isResizable: true },
        { fieldName: "ipAddress", name: "IP", key: "ip", minWidth: 96 }
    ]);
    setSortable(columns, ndas, setColumns, setNDAs);


    useEffect(() => {
        (async () => {
            try {
                let session = await SessionClient.findById(props.sessionId);
                setCrumbs([
                    rootCrumb(history),
                    { key: "session", text: session.name || "" },
                    { key: "nda", text: "NDA", isCurrentItem: true }
                ])
                let responseNDAs = await SessionClient.getNDA(props.sessionId);
                responseNDAs.forEach((nda) => {
                    nda.agreedOnDate = new Date(nda.agreedOn);
                })
                setNDAs(responseNDAs);
            }
            catch (ex) {
                setMessage(ex.message, MessageBarType.error);
            }

        })()
    }, [props.sessionId, history, setCrumbs, setMessage])
    return <SetupPage
        title={setupPageTitle}
        message={message}
        crumbs={crumbs}>
        <DetailsList

            selectionMode={SelectionMode.none}
            className={imortExportList}
            //enableShimmer={shimmer}
            //selection={selection}
            items={ndas || []}
            columns={columns}
        />
    </SetupPage>
}

const cbWidth = mergeStyles({ width: '50%', padding: "0.5rem", boxSizing: "border-box" })
const tudTitle = mergeStyles({ width: '100%', padding: "0.5rem", boxSizing: "border-box", textAlign: "center" })

function SessionManageView(props: { sessionId: number }) {

    const history = useHistory();
    const [profileId, setProfileId] = useState<number>();
    const [sessionUsers, setSessionUsers] = useState<ISessionUser[]>([]);
    const [shimmer, setShimmer] = useState(true);
    const [sessionRoles, setSessionRoles] = useState<IRole[]>([]);
    const [roleCounts, setRoleCounts] = useState({ min: 0, max: 0 });
    const [affiliations, setAffiliations] = useState<string[]>([]);
    const [crumbs, setCrumbs] = useCrumbs();
    const [hideDialog, setHideDialog] = useState(true);
    const toggleHideDialog = () => {
        setHideDialog(v => !v);
    }


    const [hideTestUserDiag, setHideTestUserDiag] = useState(true);
    const showTUDiag = () => setHideTestUserDiag(false);
    const hideTUDiag = () => setHideTestUserDiag(true);

    const searchPrms = useSearchParams();
    const [testUsers, setTestUsers] = useState<(IUser & { isEnabled: boolean })[]>([])
    const [runValue, setRunValue] = useState(0);
    //state array that holds the userIds for all users
    //   const columnsRef = useRef<IColumn[]>([])
    const [columns, setColumns] = useState<IColumn[]>([])

    let [message, setMessage] = useMessageState();
    let selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            setSelectedItem(selection.getSelection()[0] as any);
            //setMagic(magic => !magic)
        }
    }), []);

    const [selectedItem, setSelectedItem] = useState<ISessionUser>()
    const [curParticiapnts, setCurParticipants] = useState<number>(0);


    let teams = sessionRoles.filter(r => r.IsTeam);


    setSortable(columns, sessionUsers, setColumns, setSessionUsers);
    // const [userPayments, setUserPayments] = useState<Record<number, boolean>>({});

    useEffect(() => {
        (async () => {
            let [users, roles, affs] = await SessionClient.getUsersAndRoles(props.sessionId, true);
            let session = await SessionClient.findById(props.sessionId);
            let allRoles = await ScenarioClient.roles(session.scenarioId as number);
            let newRoleCount = {
                min: 10000,
                max: 0
            }
            allRoles.forEach((r) => {
                newRoleCount.min = Math.min(r.participants, newRoleCount.min);
                newRoleCount.max = Math.max(r.participants, newRoleCount.max);
            })

            setRoleCounts(newRoleCount);
            setCurParticipants(Number(session.participantCount))
            setCrumbs([
                rootCrumb(history),
                { key: "session", text: session.name || "" },
                { key: "manage", text: "Manage", isCurrentItem: true }
            ]);
            setShimmer(false)
            setSessionUsers(users);
            setSessionRoles(roles);
            setAffiliations(affs)



        })()
    }, [props.sessionId, runValue, history])

    useEffect(() => {
        (async () => {
            try {
                let session = await SessionClient.findById(props.sessionId);
                // let tempObj: Record<number, boolean> = {};
                // for (const u of sessionUsers) {
                //     if (u.id) {
                //         // tempObj[u.id] = await SessionClient.checkPaymentStatus(u.id, props.sessionId, false)
                //         if(u.paymentTransactionId){
                //             tempObj[u.id] = true
                //         }
                //         else{
                //             tempObj[u.id] = false
                //         }
                //     }
                // }
                const newColumns = [
                    {
                        name: "First Name",
                        key: "firstName",
                        fieldName: "firstName",
                        minWidth: 96,
                        maxWidth: 96
                    },
                    {
                        name: "Last Name",
                        key: "lastName",
                        fieldName: "lastName",
                        minWidth: 96,
                        maxWidth: 96
                    },
                    {
                        name: 'Paid Status',
                        key: 'paidStatus',
                        fieldName: 'paidStatus',
                        minWidth: 96,
                        maxWidth: 96,
                        onRender: (item?: ISessionUser, index?: number) => {

                            return item?.paymentTransactionId ? `Paid via ${item.paymentSource}` : `Unpaid`
                        },
                    },
                    {
                        name: 'Transaction ID',
                        key: 'transactionId',
                        fieldName: 'paymentTransactionId',
                        minWidth: 180,
                        maxWidth: 180,
                    },
                    {
                        name: "Team",
                        key: "team",
                        fieldName: "teamText",
                        minWidth: 128,
                        maxWidth: 128,
                        isResizable: true
                    },
                    {
                        name: "Roles",
                        key: "roles",
                        minWidth: 256,
                        maxWidth: 256,
                        isResizable: true
                    },
                    {
                        name: "Map Affiliation",
                        key: "affiliation",
                        minWidth: 128,
                        isResizable: true
                    },
                    {
                        name: "Email",
                        key: "email",
                        fieldName: "email",
                        minWidth: 128,
                        maxWidth: 128
                    },
                    { fieldName: "all", name: "ALL SOURCE", minWidth: 96, key: "all", isResizable: true, isCollapsible: false },
                    { fieldName: "imint_gn", name: "IMINT (G/N)", minWidth: 96, key: "imint_gn", isResizable: true, isCollapsible: false },
                    { fieldName: "humint", name: "HUMINT", minWidth: 96, key: "humint", isResizable: true, isCollapsible: false },
                    { fieldName: "imint_am", name: "IMINT (A/M)", minWidth: 96, key: "imint_am", isResizable: true, isCollapsible: false },
                    { fieldName: "sigint", name: "SIGINT", minWidth: 96, key: "sigint", isResizable: true, isCollapsible: false },
                    {
                        name: "Score",
                        key: "profile",
                        minWidth: 96
                    },
                    {
                        name: "Created At",
                        key: "createdAt",
                        fieldName: "createdAt",
                        minWidth: 96
                    }
                ]
                setColumns(newColumns.filter(c => session.cost || c.key !== "paidStatus"))
            }
            catch (ex) {
                setMessage([ex.message, MessageBarType.error]);
            }
        })()
    }, [props.sessionId, sessionUsers]);

    if (columns.length != 0) {
        console.log("AFF:")
        console.log(JSON.stringify(affiliations));
        (columns.find(c => c.key === "affiliation") as IColumn).onRender = (item: ISessionUser, idx) => {

            return selection.count && selection.getSelection()[0] === item ?
                <Dropdown
                    key={curParticiapnts}
                    defaultSelectedKey={item.affiliation}
                    options={[{ key: "", text: "Unassigned" }, ...(affiliations)
                        .map(a => ({ key: a, text: a }))]}
                    onChange={(ev, option) => {
                        item.teamRole = undefined;
                        item.roleList = [];
                        item.affiliation = option?.key as string || undefined;
                        item.isDirty = true;
                        selection.setAllSelected(false);
                        setMessage([`Save to commit changes to ${sessionUsers?.filter(u => u.isDirty).length} user(s)`]);
                    }}
                /> :
                (item.affiliation || <i className={unassignedText}>Unassigned</i>)
        }
        (columns.find(c => c.key === "team") as IColumn).onRender = (item: ISessionUser, idx) => {
            return selection.count && selection.getSelection()[0] === item ?
                <Dropdown
                    key={curParticiapnts}
                    defaultSelectedKey={item.teamRole?.id}
                    options={(teams)
                        .map(t => ({ key: t.id, text: t.team, role: t }))}
                    onChange={(dd, option) => {
                        item.teamRole = (option as any)?.role as IRole;
                        item.affiliation = item.teamRole.affiliation;
                        item.roleList = [item.teamRole as IRole];
                        item.isDirty = true;
                        setSessionUsers([...sessionUsers]);
                        setMessage([`Save to commit changes to ${sessionUsers?.filter(u => u.isDirty).length} user(s)`]);
                    }}
                /> :
                (item.teamRole?.team || <i className={unassignedText}>Unassigned</i>)
        }
        (columns.find(c => c.key === "roles") as IColumn).onRender = (item: ISessionUser, idx) => {
            return item.teamRole && selection.count && selection.getSelection()[0] === item ?
                <Dropdown
                    key={curParticiapnts}
                    defaultSelectedKeys={item.roleList.map(r => r.id)}
                    options={(item.teamRole ? sessionRoles.filter(r => r.team === item.teamRole?.team && !r.IsTeam) : [])
                        .map(r => ({ key: r.id, text: `${r.role}${r.ob ? `(${r.ob})` : ""}`, role: r }))}
                    onChange={(dd, opt) => {
                        let existingRole = item.roleList.findIndex(r => r.id === opt?.key);
                        if (opt?.selected && existingRole === -1) {
                            item.roleList.push((opt as any).role)
                        }
                        if (!opt?.selected && existingRole > -1) {
                            item.roleList.splice(existingRole);
                        }
                        item.roleList = item.roleList.sort((a, b) => a.role.localeCompare(b.role) || a.ob.localeCompare(b.ob));
                        item.isDirty = true;
                    }
                    }
                    multiSelect
                /> :
                (item.roleList.length ? item.roleList.filter(r => !r.IsTeam).map((r) => `${r.role}(${r.ob})`).join(',') :
                    <i className={unassignedText}>Unassigned</i>)
        }
        (columns.find(c => c.key === "profile") as IColumn).onRender = (item: ISessionUser) => {
            return <MsLink onClick={() => {
                setProfileId(item.id);
                toggleHideDialog();
            }}>Profile</MsLink>
        }
    }

    const crumbRenderer = useCallback((item, r) => {
        if (item?.key === "manage") {
            const roleNums: number[] = [];
            for (let index = roleCounts.min; index <= roleCounts.max; index++) {
                roleNums.push(index);
            }

            return <><Dropdown
                style={{ width: "15rem" }}
                options={roleNums.map((n) => ({ key: n, text: `${n} Participants` }))}
                selectedKey={curParticiapnts}
                onChange={(ev, dd) => {
                    setCurParticipants(Number(dd?.key));
                }}
            /></>
        }
        else if (r)
            return r(item)
        else
            return null
    }, [setCurParticipants, curParticiapnts, roleCounts]);


    let toggleText: string | undefined = ''

    return <SetupPage

        title={setupPageTitle}
        crumbs={crumbs}
        message={message}
        crumbRender={crumbRenderer}
        commands={[
            {
                key: "Save", text: "Save", iconProps: { iconName: "Save" }, onClick: () => {
                    (async function () {
                        try {

                            sessionUsers?.filter(su => su.isDirty).forEach(su => {
                                if (su.roleList && su.roleList.length && !su.roleList.find((r) => r.IsTeam)) {
                                    const teamRole = sessionRoles.find((r) => r.IsTeam && r.team === su.roleList[0].team);
                                    if (teamRole)
                                        su.roleList.push(teamRole)
                                }
                            });


                            await SessionClient.setUsersAndRoles(props.sessionId, sessionUsers?.filter(su => su.isDirty).map(su => ({ id: su.id as number, affiliation: su.affiliation as string, roles: su.roleList.map(r => r.id) })) || [], curParticiapnts)
                            sessionUsers?.filter(su => su.isDirty).forEach((su) => {
                                su.isDirty = false;
                            });
                            setMessage(["Saved!", MessageBarType.success]);
                        } catch (ex) {
                            setMessage([ex.message, MessageBarType.error])
                        }
                    })()
                }
            },
            {
                key: "Cancel", text: "Cancel", iconProps: { iconName: "Cancel" }, onClick: () => {
                    history.push("/admin/sessions");
                }
            },
            {
                key: "testUsers", text: "Test Users", iconProps: { iconName: "TestUserSolid" }, onClick: () => {
                    (async () => {
                        try {
                            let accounts = await UserClient.getTestAccounts(props.sessionId);
                            setTestUsers(accounts);
                            showTUDiag();
                        }
                        catch (ex) {
                            setMessage([ex.message, MessageBarType.error]);
                        }
                    })()
                }
            },
            {
                key: "Export", text: "Export", iconProps: { iconName: "Download" }, disabled: Boolean(sessionUsers?.find(u => u.isDirty)), onClick: () => {

                    const userExport = sessionUsers.map(su => ({
                        "First Name": su.firstName,
                        "Last Name": su.lastName,
                        Team: su.teamRole?.team,
                        Roles: su.roleList.map(r => `${r.role}${r.ob ? `(${r.ob})` : ""}`),
                        "Map Affiliation": su.affiliation,
                        Email: su.email,
                        all_source: (su as any).all,
                        imint_gn: (su as any).imint_gn,
                        humint: (su as any).humint,
                        imint_am: (su as any).imint_am,
                        sigint: (su as any).sigint
                    }));

                    setMessage(csvMessage(`sessionusers`, userExport, "First Name", "Last Name", "Team", "Roles", "Map Affiliation", "Email", "all_source",
                        "imint_gn",
                        "humint",
                        "imint_am",
                        "sigint"));
                }
            },
            {
                //TOGGLE PAID STATUS
                key: "toggleUsers", text: `${toggleText}`, iconProps: { iconName: "money" }, onClick: (ev: any) => {
                    (async () => {
                        try {
                            if (selectedItem) {
                                if (selectedItem.paymentTransactionId) {
                                    const paymentId = selectedItem.paymentId
                                    if (paymentId)
                                        await SessionClient.removePayment(paymentId);

                                    selectedItem.paymentTransactionId = undefined;
                                    selectedItem.paymentSource = "Admin"
                                }
                                else {
                                    const response = await SessionClient.addPayment("", props.sessionId, selectedItem.id!);
                                    const paymentId = response[0].id;
                                    const transactionId = response[0].transactionId;
                                    selectedItem.paymentTransactionId = transactionId;
                                    selectedItem.paymentId = paymentId;
                                    selectedItem.paymentSource = 'Admin'

                                }

                                setSessionUsers([...sessionUsers])

                            }

                        }
                        catch (ex) {
                            setMessage([ex.message, MessageBarType.error]);
                        }
                    })()
                }
            },
        ]}>


        <DetailsList
            setKey={String(curParticiapnts) + "_" + String(sessionUsers?.length) + selectedItem?.id}
            getKey={(item) => item === selectedItem ? -1 : item.id}
            className={imortExportList}
            selection={selection}
            items={sessionUsers}
            selectionMode={SelectionMode.single}
            onShouldVirtualize={() => false}
            onRenderRow={onRenderRow}
            columns={columns}

        />



        <Dialog
            hidden={hideDialog}
            onDismiss={toggleHideDialog}
            dialogContentProps={{ type: DialogType.close }}
            modalProps={{
                isBlocking: false,
                styles: { main: { height: "calc(100% - 2rem)", width: "90% !important", maxWidth: "100% !important" } },
            }}
        >
            {profileId && <ProfilePage userId={profileId}></ProfilePage>}
        </Dialog>
        <Dialog
            hidden={hideTestUserDiag}
            onDismiss={hideTUDiag}
            dialogContentProps={{ type: DialogType.largeHeader, title: "Test Users" }}
            modalProps={{
                isBlocking: false,
                styles: { main: { width: "40rem !important", maxWidth: "100% !important" } },
            }}>
            <Stack horizontal>
                <Stack horizontal wrap>
                    <Text variant="xLarge" className={tudTitle}>Way Accounts</Text>
                    {testUsers.filter((tu) => tu.firstName?.toLowerCase() === "way").sort((a, b) => Number(a.lastName) - Number(b.lastName))
                        .map(tu => <Checkbox checked={tu.isEnabled} onChange={
                            (ev, newValue) => {
                                tu.isEnabled = Boolean(newValue);
                                setTestUsers([...testUsers]);
                            }
                        } className={cbWidth} label={`${tu.firstName?.toUpperCase()} ${tu.lastName}`} />)}
                </Stack>
                <Separator vertical />
                <Stack horizontal wrap>
                    <Text variant="xLarge" className={tudTitle}>Mark Accounts</Text>
                    {testUsers.filter((tu) => tu.firstName?.toLowerCase() === "mark").sort((a, b) => Number(a.lastName) - Number(b.lastName))
                        .map(tu => <Checkbox checked={tu.isEnabled} onChange={
                            (ev, newValue) => {
                                tu.isEnabled = Boolean(newValue);
                                setTestUsers([...testUsers]);
                            }
                        } className={cbWidth} label={`${tu.firstName?.toUpperCase()} ${tu.lastName}`} />)}
                </Stack>
            </Stack>
            <DialogFooter>
                <PrimaryButton onClick={() => {
                    (async () => {
                        try {
                            await UserClient.setTestAccounts(props.sessionId, testUsers.filter(tu => tu.isEnabled).map(tu => Number(tu.id)));
                            setRunValue(runValue + 1);
                        }
                        catch (ex) {
                            setMessage([ex.message, MessageBarType.error]);
                        }
                    })();
                    hideTUDiag()
                }}>Apply</PrimaryButton>
                <DefaultButton onClick={() => hideTUDiag()}>Cancel</DefaultButton>
            </DialogFooter>
        </Dialog>
    </SetupPage>

}

const dialogStyles = { main: { maxWidth: 450 } };

// const dialogContentProps = {
//     type: DialogType.normal,
//     title: 'Unsaved Changes',
//     subText: 'Do you you want to save changes?',
// };

