import { Text, Image, Stack, mergeStyles, Spinner, PrimaryButton, DefaultButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { appHeader, appContent, appHeader_link, centerPiece, appScreen } from "../styles";
import { logo } from "../images";
import { Sidebar } from "../Sidebar";
import { bgColor } from "../colors";
import { Switch, Route, Link as RRLink, Redirect, useHistory, useLocation } from "react-router-dom";
import { ApiClient, SessionClient, IRole, ISessionBasic, IActor } from "../api/client";
import { ScenarioSetupPage } from "./dash/ScenarioSetupPage";
import { SessionSetupPage } from "./dash/SessionSetupPage";
import { ClientSetupPage } from "./dash/ClientSetupPage";
import { UserSetupPage, ProfileList } from "./dash/UserSetupPage";
import { DirectoryViewer, MessageViewer, AdminDirectory } from "./dash/DirectoryViewer";
import { useSearchParams } from "./util";
import { Card } from "./card/Card";
import { ProfilePage } from "./dash/ProfilePage";
import { EmailerPage } from "./dash/EmailerPage";
import { SimulateDate } from "./dash/SimulateDate";
import { DashboardPage } from "./dash/DashboardPage";
import { ResultSetupPage } from "./ResultSetupPage";
import { Session } from "inspector";

export function Dashboard() {
    let hist = useHistory();
    if (ApiClient.signedInUser && !ApiClient.signedInUser.confirmed) {
        hist.replace("/login/confirm");
    }
    const searchPrms = useSearchParams();
    const [roles, setRoles] = useState<IRole[]>()
    const [actors, setActors] = useState<IActor[]>();
    const [activeSession, setActiveSession] = useState<ISessionBasic | null | undefined>();
    const [sessions, setSessions] = useState<ISessionBasic[]>();
    const affiliation = (useSearchParams().get("affiliation"))?.toLowerCase();

    let currentSessionId = Number(searchPrms.get("sessionid"));
    let findPaidOnly = Number(searchPrms.get("paid")) === 1;

    let roleIdPrm = Number(searchPrms.get("roleid"));
    let loc = useLocation();
    if (loc.pathname.substr(0, 6) !== "/admin" && activeSession && currentSessionId && currentSessionId !== activeSession.id)
        hist.replace("/");


    function setupSession(session: ISessionBasic) {

        (async function () {
            setRoles(session ? (await SessionClient.getRoles(session.id as number)) : undefined);
            setActors(session ? (await SessionClient.getActors(session.id as number)) : undefined);
            setActiveSession(session);
        })()
    }

    useEffect(() => {
        (async () => {
            const _sessionsResult = await SessionClient.getBasic();
            const sessionsSorted = _sessionsResult.sort((a, b) => (a.createdAt?.valueOf() || 0 - (b.createdAt?.valueOf() || 0)))
            console.log(JSON.stringify(sessionsSorted));
            setSessions(sessionsSorted);
            //First Load
            if (sessionsSorted && sessionsSorted.length) {
                const defaultSession =
                    currentSessionId ? sessionsSorted.find(s => s.id === currentSessionId) :
                        findPaidOnly ? sessionsSorted.find(s => Boolean(s.paymentId) || !s.cost) :
                            undefined

                setupSession(defaultSession || sessionsSorted[0]);
            }
            else {
                setActiveSession(null)
            }
        })()
        console.log(currentSessionId)
    }, [currentSessionId, findPaidOnly]);


    let role: IRole | undefined;
    if (roles) {
        if (ApiClient.isAdmin && roleIdPrm < 0 && (-roleIdPrm !== ApiClient.signedInUser.id)) {
            role = roles.find(r => r.id < 0)
            if (role)
                role.id = roleIdPrm;
        }
        else if (roleIdPrm !== 0)
            role = roles.find(r => r.id === roleIdPrm);
        else {
            let aff = String(actors?.find(a => a.affiliation.toLowerCase() === affiliation)?.affiliation);
            role = {
                IsTeam: true,
                id: 0,
                ob: "",
                participants: 0,
                role: aff,
                team: aff,
                affiliation: affiliation
            }
        }
    }


    return !activeSession || !sessions ?
        <Stack className={appScreen} dir="ltr" horizontalAlign="center">
            <Card cardTitle="Waymark Web App" styleExt={centerPiece}>
                {sessions === undefined || activeSession === undefined ? <Spinner label={"Loading Portal..."} styles={{ label: { fontSize: "1rem" }, root: { height: "6rem" } }} /> :
                    <>
                        <Text variant="large">You are all signed up!</Text>
                        <Text>We are currently finalizing your session—accepting additional applicants, forming teams, and assigning roles—and will notify you when we are ready for you to login.</Text>
                        <PrimaryButton onClick={() => hist.push("/profile?userid=" + ApiClient.signedInUser.id)}>View Profile</PrimaryButton>
                        <DefaultButton onClick={() => hist.push("/signout")} >Sign Out</DefaultButton>
                    </>
                }

            </Card></Stack> :


        <><Stack className={appHeader} horizontal>
            <Stack className="ms-sm3 ms-xxl2" verticalFill >
                <Image src={logo} height="3rem" styles={{ image: { marginTop: "0.25rem" } }} />
            </Stack>
            <Stack horizontal grow gap="1rem" horizontalAlign="end" verticalAlign="center">
                <Text variant="xLarge" >{`${ApiClient.signedInUser.firstName} ${ApiClient.signedInUser.lastName}`}</Text>
                {/* <Text>{`${paymentStatus}`}</Text> */}
                <RRLink to="/signout" className={appHeader_link} style={{ marginRight: "2rem" }}>[Sign Out]</RRLink>
            </Stack>
        </Stack>
            <Stack className={appContent} horizontal>
                <Stack.Item className={mergeStyles("ms-sm3 ms-xxl2", { borderRight: "1px solid #ccc", height: "100%", overflow: "auto" })}>
                    <Sidebar
                        sessions={sessions}
                        roles={roles}
                        session={activeSession}
                        actors={actors}
                        sessionChanged={setupSession}
                        isAdmin={ApiClient.isAdmin}
                    />
                </Stack.Item>
                <Stack className="ms-sm9 ms-xxl10" style={{ backgroundColor: bgColor, padding: "1rem", width: "100%" }} >
                    <Stack className={mergeStyles("ms-depth-4", {
                        backgroundColor: "white",
                        padding: "1rem",
                        boxSizing: "border-box",
                        maxHeight: "100%",

                    })} >
                        <Switch>
                            <Route exact path="/">
                                <DashboardPage session={activeSession} isAdmin={ApiClient.isAdmin} />
                            </Route>
                            <Route path="/admin/users">
                                <UserSetupPage />
                            </Route>
                            <Route path="/admin/clients">
                                <ClientSetupPage />
                            </Route>
                            <Route path="/admin/schedulerdate">
                                <SimulateDate />
                            </Route>
                            <Route path="/emailer">
                                <EmailerPage />
                            </Route>
                            <Route path="/admin/scenarios/:mode?">
                                <ScenarioSetupPage></ScenarioSetupPage>
                            </Route>
                            <Route path="/admin/sessions/results">
                                {activeSession && <ResultSetupPage session={activeSession}></ResultSetupPage>}
                            </Route>
                            <Route path="/admin/sessions">
                                <SessionSetupPage></SessionSetupPage>
                            </Route>
                            <Route path="/users/profile">
                                <ProfilePage />
                            </Route>
                            <Route path="/users/profilelist">
                                <ProfileList />
                            </Route>
                            {/* <Route path={"/simulation/:team"}>
                             { roles && <DirectoryViewer roles={roles}></DirectoryViewer> }
                        </Route> */}
                            <Route path={"/simulation/messageboard/posts"}>
                                {role && roles && activeSession && <MessageViewer session={activeSession} role={role} />}
                            </Route>
                            <Route path={"/simulation"}>
                                {role && roles && activeSession && actors &&
                                    (ApiClient.isAdmin && -roleIdPrm === ApiClient.signedInUser.id ?
                                        <AdminDirectory session={activeSession} /> :
                                        <DirectoryViewer actors={actors} roles={roles} role={role} session={activeSession} ></DirectoryViewer>)
                                }
                            </Route>
                            <Redirect to="/404"></Redirect>
                        </Switch>
                    </Stack>
                </Stack>
            </Stack>
        </>
}