
import { Image, Text, Stack, Separator, ImageFit, mergeStyles, StackItem, PrimaryButton, Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ISessionBasic, SessionClient, IDashItem } from "../../api/client";


const ligherText = mergeStyles({ color: "#997979" })


function DashBlock(props: { title: string, stat: string }) {
    return <Stack horizontal verticalAlign="center">
        <StackItem grow><Text variant="mediumPlus">{props.title}</Text></StackItem><StackItem styles={{ root: { width: "30%", textAlign: "center" } }}><Text variant="xLargePlus">{props.stat}</Text></StackItem>
    </Stack>
}

export function DashboardPage(props: { session: ISessionBasic, isAdmin: boolean }) {
    const [dashItems, setDashItem] = useState<IDashItem[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const hideModal = () => setIsModalOpen(false);
    const [anthem, setAnthem] = useState<HTMLAudioElement>()

    useEffect(() => {
        (async () => {
            try {
                setDashItem(await SessionClient.getDashboard(Number(props.session.id)));
            }
            catch (ex) {
                alert(ex.message);
            }
        })();
    }, [props.session.id]);

    useEffect(() => {
        if (isModalOpen) {
            if (anthem)
                anthem.pause();
            const newAnthem = new Audio(`https://waymarkintel.blob.core.windows.net/assets-${props.session.scenarioId}/anthem_${props.session.winner?.toLowerCase()}.mp3`);
            newAnthem.play();
            setAnthem(newAnthem)
        }
        else if (anthem) {
            anthem.pause();
            setAnthem(undefined);
        }
    }, [isModalOpen, props.session.scenarioId, props.session.winner, anthem])

    //SESSION DASHBOARD SECTION

    return <Stack horizontal tokens={{ childrenGap: "0.5rem" }}><Stack style={{ width: "40%" }}>
        <Text variant="xLargePlus">{props.session.name}</Text>
        <Text variant="mediumPlus" className={ligherText} >{props.session.clientName} - {props.session.scenarioName}</Text>
        <Separator></Separator>
        <Image src={`https://waymarkintel.blob.core.windows.net/assets-${props.session.scenarioId}/banner.jpg`}
            width="100%"
        />
        {props.isAdmin && props.session.released &&
            <PrimaryButton
                onClick={() => {
                    showModal();
                }}
            >
                Reveal Winner
            </PrimaryButton>
        }
    </Stack>
        <Separator vertical></Separator>
        <Stack grow>
            <Text variant="xLargePlus">Dashboard</Text>
            <Text variant="mediumPlus" className={ligherText}>{(new Date()).toLocaleDateString()} {(new Date()).toLocaleTimeString()}</Text>
            <Separator></Separator>
            <Stack styles={{
                inner: {

                    "& > *": {
                        width: "50% !important",
                        marginBottom: "1rem"
                    }

                }
            }} horizontal wrap>
                {
                    dashItems.map(di => <DashBlock title={di.title} stat={di.stat} />)
                    //(() => {
                    // if (props.isAdmin && props.session.turn === 0)
                    //     return <>
                    //         <DashBlock title="Current Report Date" stat="7/23" />
                    //         <DashBlock title="NATO Collection Request" stat="10" />
                    //         <DashBlock title="Forum Posts Pending Response" stat="7" />
                    //     </>
                    // else if (props.isAdmin && Number(props.session.turn) > 0) {
                    //     return <>
                    //         <DashBlock title="NATO Collection Request" stat="10" />
                    //         <DashBlock title="Session Current Turn" stat={`${props.session.turn}/${props.session.totalTurns}`} />
                    //         <DashBlock title="NATO Pending Moves" stat="9" />
                    //         <DashBlock title="Forum Posts Pending Response" stat="7" />
                    //     </>
                    // }
                    // else if (props.session.turn === 0) {
                    //     return <><DashBlock title="" stat="" />
                    //     </>
                    // }
                    // else {
                    //     return <><DashBlock title="" stat="" />
                    //     </>
                    // }

                    //               }
                    //               )()
                }
            </Stack>
        </Stack>

        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
        //containerClassName={contentStyles.container}
        >
            <Image
                styles={
                    {
                        root: {
                            width: "90vw",
                            height: "80vh",
                            background: "black"
                        },
                        image: {
                            width: "100%"
                        }
                    }

                }
                imageFit={ImageFit.contain}
                placeholder="Loading..."
                src={props.session.winner ? `https://waymarkintel.blob.core.windows.net/assets-${props.session.scenarioId}/flag_${props.session.winner?.toLowerCase()}.jpg` : undefined} />
        </Modal>
    </Stack>
}